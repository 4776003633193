import React from 'react';
import PageHeader from '../components/PageHeader';
import '../css/deansw.css'; // Update the CSS file name

const OrgChart = () => {
    return (
        <div class="container my-4">
            <center><h4 style={{textDecoration:"underline"}}>Organization Chart</h4></center>
            <div class="row">
            <img class="img-fluid" src="https://nitw.ac.in/api/static/files/orgchart_2024-9-4-19-16-33.jpg" />
               
            </div>
        </div>
    )
}
export default OrgChart;