import React, { useState } from 'react';
import _ from 'lodash';
import PageHeader from '../components/PageHeader';
import { EXAM_BRANCHES } from '../constants/StaticExamBranchData';
import '../css/ExamBranchPage.css'; // Import the CSS file

const ExamBranchPage = () => {
    const [selectedExamBranch, setSelectedExamBranch] = useState(null);
  
    const handleMouseOver = (index) => {
      setSelectedExamBranch(index);
    };
  
    const handleMouseOut = () => {
      setSelectedExamBranch(null);
    };
  
    return (
      <div className="exam-branch-page">
        <PageHeader dept="/EB" />
        <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
          <div className="row">
            {/* Photo with details on top */}
            <div className="col-md-12 mb-4 text-center">
              <img
                src="https://nitw.ac.in/api/static/files/Dr._Kiran_Kumar_K_2024-3-5-18-10-37.jpg" // Replace with the actual path to your photo
                alt="Profile Photo"
                className="rounded-image"
              />
                <p>
              <h5><a href='https://erp.nitw.ac.in/ext/profile/me-kiran'>Prof. K. Kiran Kumar</a></h5></p>
              Professor in charge - Examinations <br></br>
              email: ad_exam@nitw.ac.in
              {/* Add more details if needed */}
            </div>
  
            {_.map(EXAM_BRANCHES, ({ name, link, image }, index) => (
              <div
                className="col-sm-6 col-md-3 mb-4"
                
                key={index}
                onMouseOver={() => handleMouseOver(index)}
                onMouseOut={handleMouseOut}
              >
                <a href={link} target="_self" rel="noreferrer">
                  <div
                    className={`card profile-card-4 ${
                      selectedExamBranch === index ? 'hovered' : ''
                    }`}
                  >
                    <div>
                      <img src={image} alt="profile-sample1" className="rounded-image" />
                    </div>
                    {/* <div style={{ marginTop: '2px' }} className="card-content">
                    <h2 style={{ backgroundColor: '#FFBF00' }}>
                        <a href={link} target="_self" rel="noreferrer">
                          {name}
                        </a>
                      </h2>
                    </div> */}
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
  
     
        {/* Additional details at the bottom */}
        
        <div className="additional-details p-3 container mx-auto">
    <div className="row">
    <div className="col-md-12 mb-4 text-center">
        <h4>Contact Information:</h4>
        <p>
          <h5><a href='https://erp.nitw.ac.in/ext/profile/me-kiran'>Dr. K. Kiran Kumar</a></h5>
          <span className="exam-small-font">
            Professor in charge - Examinations <br></br>
            National Institute of Technology, <br></br>
            Warangal- 506 004, TS, India<br></br>
            Tel: 0870-2462022, 2025, 2021 <br></br>
            Email: ad_exam@nitw.ac.in
          </span>
        </p>
      </div>
      {/* Add more details or sections as needed */}
    </div>
  </div>
  
  
  
      </div>
     
    );
  };
  
  export default ExamBranchPage;