import { Grid, Typography } from "@mui/material";
import React from "react";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
// import { IMG_URL } from "../constants/CampusLifeData";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Styles.css";

// const bgurl =
// `${MainServerUrl}/static/files/NITW_2021-10-13-22-53-31.jpg`;
let theme = createTheme({
  typography: {
    fontFamily: "Merriweather",
  },
});
theme = responsiveFontSizes(theme);


const CampusLife = (props) => {
  // console.log(props.items);
  // const x = props.items;
  // console.log(IMG_URL);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <React.Fragment>
      <Grid
        style={{
          backgroundColor: "#F5F7FA",
          // backgroundImage: `url("${bgurl}")`,
          // backgroundSize: "cover",
        }}
      >
        <Grid height="2vh"></Grid>
        <div style={{ width: "90vw", margin: "auto" }}>
          <ThemeProvider theme={theme}>
            <Typography
              variant="h3"
              fontWeight="bold"
              margin="auto"
              textAlign="center"
              color="#b80924"
              paddingTop="3vh"
            >
              Campus Bulletin
            </Typography>
            <hr className="divider bg-red" />
          </ThemeProvider>
        </div>
        <br />
        <br />
        <section className="section">
          <Carousel
            swipeable={true}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={props.autoPlay}
            keyBoardControl={true}
            containerClass="carousel-container"
            removeArrowOnDeviceType={[]}
            deviceType={props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-20px"
            customTransition={"transform 1000ms ease 0s"}
            centerMode={false}
            focusOnSelect={false}
          >
            {props.items.filter((item)=>{return item.isactive=="Yes"}).map((img) => {
              return (
                  <a
                    type="checkbox"
                    className="thumbnail-default"
                    data-lightgallery="item"
                    href={img.image}
                  >
                      <img
                        width={320}
                        height={320}
                        data-title="alt"
                        src={img.image}
                        alt=""
                        className={img.image}
                        id="image-classes"
                      />
                      <p
                        className="icon text-center"
                        style={{ fontSize: "20px" }}
                        fontFamily="Merriweather"
                      >
                        {img.title}
                      </p>
                      </a>
              );
            })}
          </Carousel>
        </section>
      </Grid>
    </React.Fragment>
  );
};

export default CampusLife;
