// import React from 'react';
import React, { useState } from 'react';
import PageHeader from '../components/PageHeader';
import '../css/deansw.css'; // Update the CSS file name
import { PicData } from '../constants/PicData';


const Picpage = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const handleHover = (index) => {
        setHoveredIndex(index);
      };
    
      const handleHoverEnd = () => {
        setHoveredIndex(null);
      };
    return (
        <div class="container">
            <center><h4 style={{ textDecoration: "underline" }}>Professor In-charges/Co-ordinators</h4></center>
            <div className="row">
                {PicData.map((data, index) => (
                    <div className="col-md-6 col-sm-12 columns-wrapper p-2">
                        <div className="column">
                            <table className="custom-table">
                                <tbody>
                                    <tr
                                        className={`member ${data.emp_page_link === hoveredIndex ? 'hovered' : ''}`}
                                        onMouseEnter={() => handleHover(data.emp_page_link)}
                                        onMouseLeave={handleHoverEnd}
                                        key={data.emp_page_link}
                                    >
                                     
                                        <td className="custom-photo-cell">
                                            <a href={data.emp_page_link} target="_self" rel="noreferrer">
                                                <img className=" img-fluid custom-circle-image" style={{ width:"160px", height:"180px"}} src={data.emp_image} alt="profile-sample1" />
                                            </a>
                                        </td>
                                        <td className="custom-details-cell p-2" style={{textAlign:"left"}}>
                                            <h6>
                                                <a href={data.emp_page_link} target="_self" rel="noreferrer">
                                                    {data.incharge_name}
                                                </a>
                                            </h6>
                                            <div className="details-wrapper">
                                                <div className="details-row">
                                                    <span className="details-label">Name: </span>
                                                    <span className="details-value">{data.emp_name}</span>
                                                </div>
                                                <div className="details-row">
                                                    <span className="details-label">Phone No.: </span>
                                                    <span className="details-value">{data.emp_phone_no}</span>
                                                </div>
                                                <div className="details-row">
                                                    <span className="details-label">Email: </span>
                                                    <span className="details-value">{data.emp_email}</span>
                                                </div>
                                                <br></br>
                                                <br></br>
                                            </div>
                                        </td>
                                      
                                    </tr>
                              
                                </tbody>
                            </table>
                        </div>


                    </div>
                      ))}
                {/* <table className="table table-bordered table-responsive">
                    <thead>
                        
                    </thead>
                    <tbody>
                    <tr>
                            <th>
                                S.No
                            </th>
                            <th>
                                Designation
                            </th>
                            <th>
                                Names
                            </th>
                            <th>
                                Phone No. /Email ID
                            </th>
                        </tr>
                       {PicData.map((data, index) => (
                            <tr>
                            <td>
                            {index+1 }
                            </td>
                            <td>
                             {data.incharge_name}
                            </td>
                            <td>
                             <a href={data.emp_page_link} target='_self'> {data.emp_name}</a> 
                            </td>
                            <td>
                            {data.emp_phone_no}<br />
                            {data.emp_email}
                            </td>
                        </tr>
                        ))}
                        


                    </tbody>
                </table> */}

            </div>
        </div>
    )
}
export default Picpage;