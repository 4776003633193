// import React from "react";
// import { Link } from "react-router-dom";
// import { BaseUrl } from "../BasePath";
// import { Grid, Typography } from "@mui/material";
// import { TwitterTimelineEmbed } from 'react-twitter-embed';
// import {
//   createTheme,
//   responsiveFontSizes,
//   ThemeProvider,
// } from "@mui/material/styles";

// import "../components/Styles.css";

// import "../css/SocialsFeed.css";
// let theme = createTheme({
//   typography: {
//     fontFamily: "Merriweather",
//   },
// });
// theme = responsiveFontSizes(theme);

// function SocialFeed() {
//   return (
//     <div className=" socials-feed-container container" style={{ margin: "0.1rem auto" }}>

//       <div style={{ paddingTop: "30px" }} className="row row-50 text-md-start align-items-md-center justify-content-md-between">
//         <Typography
//           variant="h3"
//           fontWeight="bold"
//           fontFamily= "Merriweather"
//           style={{ textAlign: "center", color: "#b80924" }}
//         >
//           Social Media Updates
//         </Typography>
//         <div>
//           <hr className="divider bg-madison" />
//         </div>
//       </div>

//       {/* <h2 className="text-center fw-bold"> Social Media @ NITW</h2> */}
//       <div className="row">
//       <div className="col-md-12 col-lg-6 text-left" style={{ paddingleft: 0 }}>
//         <div id="fb-root"></div>
//         <div className="fb-page" data-href="https://www.facebook.com/NITWarangalOfficial/" data-tabs="timeline" data-width="600" data-height="500" data-small-header="true" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="true"><blockquote cite="https://www.facebook.com/NITWarangalOfficial/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/NITWarangalOfficial/">National Institute of Technology Warangal</a></blockquote></div>
//       </div>

//       <div className="col-md-12 col-lg-6 text-left" style={{ paddingLeft: 0 }}>
//         {/* <h2> Twitter </h2> */}
//         <TwitterTimelineEmbed
//           sourceType="profile"
//           userId={"994538771713478661"}
//           options={{ height: 500 }}
//           style={{}}
//         />
//         </div>

//       <br />
//       <br />
//       </div>
//     </div>
//   );

// }

// export default SocialFeed;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BaseUrl } from "../BasePath";
import { Grid, Typography } from "@mui/material";
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { ChevronLeft, ChevronRight, Padding } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import "../css/noticeboard.css";




import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import { Tab, Tabs } from "@mui/material";

import "../components/Styles.css";

import "../css/SocialsFeed.css";

let theme = createTheme({
  typography: {
    fontFamily: "Merriweather",
  },
});
theme = responsiveFontSizes(theme);

function SocialFeed() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div className="socials-feed-container " style={{ margin: "0.1rem auto" }}>
      <div style={{ paddingTop: "30px" }} className="row row-50 text-md-start align-items-md-center justify-content-md-between">

        <div>
          {/* <hr className="divider bg-madison" /> */}
        </div>
      </div>

      <div className="row" style={{ borderBottom: "2px solid #f16127" }}>
        <div className="col-sm-12 col-md-12 col-lg-5 text-left" style={{ paddingLeft: 0 }}>
          <Typography
            variant="h4"
            fontWeight="bold"
            fontFamily="Merriweather"
            style={{ textAlign: "center", color: "#b80924" }}
          >
            Student Accolades
          </Typography><br />

          <div className="bar">
            <div className="row seminar-series ">
            <div class="col-md-12 col-xs-8 content mb-1  ml-3" ><h4 class="news-title" style={{borderStyle: "groove"}}> Ms. Gargi Singh, Research Scholar in the Department of  Chemistry under the guidance of Prof. V Rajeshumar, successfully presented her research findings in the international conference "23rd Lecture Conference-ORCHEM 2024," which took place from September 9–September 11, 2024, at the University of Regensburg, Germany.</h4></div>
            
            <div class="col-md-12 col-xs-8 content mb-1  ml-3" ><h4 class="news-title" style={{borderStyle: "groove"}}> Ms. Nakka Anitha,  Ph. D. Research Scholar in the Physics Dept  under the guidance of  Prof T. Venkatappa Rao got Financial Assistance for participating in  "International Conference on Renewable Energy and Energy Conversion Devices (ICREED-24), USA (21 October, 2024 to 22 October, 2024)" under  Anusandhan National Research Foundation (ANRF) of SERB.</h4> </div>
            <div class="col-md-12 col-xs-8 content mb-1  ml-3" ><h4 class="news-title" style={{borderStyle: "groove"}}> Mr. Jitesh Pani, Ph. D. Inspire Fellow, Department of Physics Received the Best Poster Award  and Cash Prize in the International Forum in Plasma and Thin Film Technologies for Sustainable  Development Goals, Taiwan and Awarded Taiwan Experience Education Program to Pursue Research Work on Energy Storage</h4> </div>
            <div class="col-md-12 col-xs-8 content mb-1  ml-3" ><h4 class="news-title" style={{borderStyle: "groove"}}>Mr. Soumya Ranjan Pradhan, Ph.D. Research Scholar under the supervision of Dr. V. Jayalakshmi, Department of Physics, has been awarded a DBT International Travel Grant to give an oral presentation at the 29th International Liquid Crystal Conference (ILCC2024), July 21-26, 2024, Rio de Janeiro, Brazil.</h4> </div>
            <div class="col-md-12 col-xs-8 content mb-1  ml-3" ><h4 class="news-title" style={{borderStyle: "groove"}}>Mr. Jitesh Pani, Ph. D. Inspire Fellow, Department of Physics Received the Best Poster Award  and Cash Prize in the International Forum in Plasma and Thin Film Technologies for Sustainable  Development Goals, Taiwan and Awarded Taiwan Experience Education Program to Pursue Research Work on Energy Storage </h4></div>
            <div class="col-md-12 col-xs-8 content mb-1  ml-3" ><h4 class="news-title" style={{borderStyle: "groove"}}>Mr. Nikhil Vangety, Ph.D. Research Scholar under Prof. Sourabh Roy, Department of Physics received a DST-SERB International Travel Support grant for participating in the SPIE Photonics Europe conference held from 7th April to 11th April 2024 in Strasbourg, France</h4> </div>
            <div class="col-md-12 col-xs-8 content mb-1  ml-3" ><h4 class="news-title" style={{borderStyle: "groove"}}>Miss. Nila B., Ph. D. Research Scholar in the Department of Mathematics Received DST-SERB International Travel Support and CSIR Foreign Travel Grant to Participate 33rd European Conference on Operational Research in Copenhagen, Denmark</h4> </div>
            <div class="col-md-12 col-xs-8 content mb-1  ml-3" ><h4 class="news-title" style={{borderStyle: "groove"}}>Best Poster Award to Ms. Anjaly Babu (Department of Physics) in the conference on Innovations in Materials Science and workshop on Characterization Techniques at Indian Institute of Technology Roorkee on 24-27th April 2024.</h4> </div>
            <div class="col-md-12 col-xs-8 content mb-1  ml-3" ><h4 class="news-title" style={{borderStyle: "groove"}}>Best Paper Award to Mr. Shekher Sheelam in ICAME 2024 conference, SRM University, Chennai on 20-22nd Mar 2024.</h4> </div>
            <div class="col-md-12 col-xs-8 content mb-1  ml-3" ><h4 class="news-title" style={{borderStyle: "groove"}}>Best Oral Presentation Award to Ms. A. Mahalakshmi in the ICMSDT 2024, Central University of Jammu, J & K</h4> </div>
            <div class="col-md-12 col-xs-8 content mb-1  ml-3" ><h4 class="news-title" style={{borderStyle: "groove"}}>Mr. B. Dinesh, Ph. D. Student under Dr. P. Sampath Kumar has been awarded the Best Research Presentation in TLS 2024 at IIT Delhi.</h4> </div>
            <div class="col-md-12 col-xs-8 content mb-1  ml-3" ><h4 class="news-title" style={{borderStyle: "groove"}}>Best Paper Award for PhD Student Nitish Gupta and D. Bhargavi, "An Analytical Study of the Impact of an Inclined Magnetic Field on Couette Flow with Jeffrey Fluid under Local Thermal Non-Equilibrium (LTNE) and Viscous Dissipation", paper presented at the "International Conference on Advancements in Materials, Manufacturing, and Automation (AMMA-2023)", held during April 7-8, 2023, organized by Amrita Vishwa Vidyapeetham, Tamil Nadu, India.</h4> </div>
            <div class="col-md-12 col-xs-8 content mb-1  ml-3" ><h4 class="news-title" style={{borderStyle: "groove"}}></h4> </div>
           
            </div>
          </div>


          {/* Content for the marquee */}
          {/* <TwitterTimelineEmbed
            sourceType="profile"
            userId={"994538771713478661"}
            options={{ height: 1200 }}
            style={{height: "600px"}}
          /> */}
          {/* <div className="row"> */}
          {/* <div id="fb-root"></div>
       <div className="col-md-12 col-lg-6 text-left" style={{ paddingleft: 0 }}>
         <div id="fb-root"></div>
         <div className="fb-page" data-href="https://www.facebook.com/NITWarangalOfficial/" data-tabs="timeline" data-width="1000" data-height="600" data-small-header="true" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="true"><blockquote cite="https://www.facebook.com/NITWarangalOfficial/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/NITWarangalOfficial/">National Institute of Technology Warangal</a></blockquote></div>
       </div> */}
          {/* </div> */}

          {/* face book start */}
          {/* <center>
            <div className="fb-page" data-href="https://www.facebook.com/NITWarangalOfficial" data-tabs="timeline" data-width="500" data-height="600" data-small-header="false" data-adapt-container-width="false" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/NITWarangalOfficial" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/NITWarangalOfficial">National Institute of Technology Warangal</a></blockquote></div>
          </center> */}
          {/* facebook end */}
          {/* <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FNITWarangalOfficial&tabs=timeline&width=500&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="500" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> */}

        </div>

        <div className="col-sm-12 col-md-12  col-lg-7 text-left" style={{ paddingLeft: 0 }}>
          <div id="fb-root"></div>
          <Typography
            variant="h4"
            fontWeight="bold"
            fontFamily="Merriweather"
            style={{ textAlign: "center", color: "#b80924" }}
          >
            Admission Zone
          </Typography>
          <marquee behavior="scroll" direction="left" scrollamount="3" style={{ width: "92%", height: "150px" }}>
            <div className="logo-container">
              <a href="https://josaa.nic.in/ " className="logo-link">
                <img src="https://nitw.ac.in/api/static/files/josaa_2023-5-16-15-21-43.png" alt="Logo 5" className="logo" />
              </a> &nbsp; &nbsp; &nbsp;

              <a href="https://csab.nic.in/" className="logo-link">
                <img src="https://nitw.ac.in/api/static/files/csab_2023-5-16-16-8-43.png" alt="Logo 1" className="logo" />
              </a> &nbsp; &nbsp; &nbsp;
              <a href="https://dasanit.org/dasa2023/" className="logo-link">
                <img src="https://nitw.ac.in/api/static/files/dasa_2023-5-16-16-3-48.png" alt="Logo 2" className="logo" />
              </a> &nbsp; &nbsp; &nbsp;
              <a href="https://ccmt.admissions.nic.in/" className="logo-link">
                <img src="https://nitw.ac.in/api/static/files/ccmt_2023-5-16-16-4-42.png" alt="Logo 3" className="logo" />
              </a> &nbsp; &nbsp; &nbsp;
              <a href="https://ccmn.admissions.nic.in/" className="logo-link">
                <img src="https://nitw.ac.in/api/static/files/ccmn_2023-5-16-16-5-25.png" alt="Logo 4" className="logo" />
              </a> &nbsp; &nbsp; &nbsp;

              <a href="https://www.iccr.gov.in/" className="logo-link">
                <img src="https://nitw.ac.in/api/static/files/iccr_v5_2023-5-21-12-51-11.png" alt="Logo 6" className="logo" />
              </a> &nbsp; &nbsp; &nbsp;
              <a href="http://www.nimcet.in/" className="logo-link">
                <img src="https://nitw.ac.in/api/static/files/nimcet_v2_2023-5-21-12-53-10.png" alt="Logo 7" className="logo" />
              </a> &nbsp; &nbsp; &nbsp;
            </div>
          </marquee>


          <div style={{ display: "flex" }}>
            <IconButton
              onClick={() => setActiveTab((prevTab) => Math.max(prevTab - 1, 0))}
              disabled={activeTab === 0}
              aria-label="previous-tab"
            >
              <ChevronLeft />
            </IconButton>
            {/* Your existing Tabs component */}
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              // centered
              style={{
                backgroundColor: "lightblue",
                height: "60px",
                whiteSpace: "nowrap",
              }}
            >
              <Tab
                value={0}
                label={
                  <div>
                    <a href="https://www.nitw.ac.in/path/?dept=/ad2021">
                      {/* <img src="https://nitw.ac.in/api/static/files/new_2023-5-16-17-34-35.jpg" alt="B.Tech GIF" /> */}
                      <span style={{ textTransform: "capitalize", fontWeight: "bold", fontSize: "20px" }}>B.Tech</span>
                    </a>
                  </div>
                }
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)", // Change the background color on hover
                    color: "blue", // Change the text color on hover
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)", // Add box shadow on hover for the glass effect
                  },
                }}
              />
              <Tab
                value={1}
                label={
                  <div>
                    <a href="https://www.nitw.ac.in/path/?dept=/ad2021">
                      {/* <img src="https://nitw.ac.in/api/static/files/new_2023-5-16-17-34-35.jpg" alt="B.Tech GIF" /> */}
                      <span style={{ textTransform: "capitalize", fontWeight: "bold", fontSize: "20px" }}>Integrated M.Sc.</span></a>
                  </div>
                }
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)", // Change the background color on hover
                    color: "blue", // Change the text color on hover
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)", // Add box shadow on hover for the glass effect
                  },
                }}
              />
              <Tab
                value={2}
                label={
                  <div>
                    <a href="https://www.nitw.ac.in/path/?dept=/ad2021">
                      {/* <img src="https://nitw.ac.in/api/static/files/new_2023-5-16-17-34-35.jpg" alt="B.Tech GIF" /> */}
                      <span style={{ textTransform: "capitalize", fontWeight: "bold", fontSize: "20px" }}>M.Tech</span></a>
                  </div>
                }
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)", // Change the background color on hover
                    color: "blue", // Change the text color on hover
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)", // Add box shadow on hover for the glass effect
                  },
                }}
              />



              <Tab
                value={3}
                label={
                  <div>
                    <a href="https://www.nitw.ac.in/path/?dept=/ad2021">
                      {/* <img src="https://nitw.ac.in/api/static/files/new_2023-5-16-17-34-35.jpg" alt="B.Tech GIF" /> */}
                      <span style={{ textTransform: "uppercase", fontWeight: "bold", fontSize: "20px" }}>MCA</span>
                    </a>
                  </div>
                }
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)", // Change the background color on hover
                    color: "blue", // Change the text color on hover
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)", // Add box shadow on hover for the glass effect
                  },
                }}
              />
              <Tab
                value={4}
                label={
                  <div>
                    <a href="https://www.nitw.ac.in/path/?dept=/ad2021">
                      {/* <img src="https://nitw.ac.in/api/static/files/new_2023-5-16-17-34-35.jpg" alt="B.Tech GIF" /> */}
                      <span style={{ textTransform: "uppercase", fontWeight: "bold", fontSize: "20px" }}>MBA</span></a>
                  </div>
                }
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)", // Change the background color on hover
                    color: "blue", // Change the text color on hover
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)", // Add box shadow on hover for the glass effect
                  },
                }}
              />
              <Tab
                value={5}
                label={
                  <div>
                    <a href="https://www.nitw.ac.in/path/?dept=/ad2021">
                      {/* <img src="https://nitw.ac.in/api/static/files/new_2023-5-16-17-34-35.jpg" alt="B.Tech GIF" /> */}
                      <span style={{ textTransform: "capitalize", fontWeight: "bold", fontSize: "20px" }}>M.Sc.</span></a>
                  </div>
                }
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)", // Change the background color on hover
                    color: "blue", // Change the text color on hover
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)", // Add box shadow on hover for the glass effect
                  },
                }}
              />


              <Tab
                value={6}
                label={
                  <div>
                    <a href="https://www.nitw.ac.in/path/?dept=/ad2021">
                      {/* <img src="https://nitw.ac.in/api/static/files/new_2023-5-16-17-34-35.jpg" alt="B.Tech GIF" /> */}
                      <span style={{ textTransform: "capitalize", fontWeight: "bold", fontSize: "20px" }}>M.Sc.(Tech)</span></a>
                  </div>
                }
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)", // Change the background color on hover
                    color: "blue", // Change the text color on hover
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)", // Add box shadow on hover for the glass effect
                  },
                }}
              />

              <Tab
                value={7}
                label={
                  <div>
                    <a href="https://www.nitw.ac.in/path/?dept=/ad2021">
                      {/* <img src="https://nitw.ac.in/api/static/files/new_2023-5-16-17-34-35.jpg" alt="B.Tech GIF" /> */}
                      <span style={{ textTransform: "capitalize", fontWeight: "bold", fontSize: "20px" }}>PG Diploma</span></a>
                  </div>
                }
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)", // Change the background color on hover
                    color: "blue", // Change the text color on hover
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)", // Add box shadow on hover for the glass effect
                  },
                }}
              />
              <Tab
                value={8}
                label={
                  <div>
                    <a href="https://www.nitw.ac.in/path/?dept=/ad2021">
                      {/* <img src="https://nitw.ac.in/api/static/files/new_2023-5-16-17-34-35.jpg" alt="B.Tech GIF" /> */}
                      <span style={{ textTransform: "capitalize", fontWeight: "bold", fontSize: "20px" }}>Ph.D</span>
                    </a>
                  </div>
                }
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)", // Change the background color on hover
                    color: "blue", // Change the text color on hover
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)", // Add box shadow on hover for the glass effect
                  },
                }}
              />


              <Tab
                value={9}
                label={
                  <div>
                    <a href="https://www.nitw.ac.in/path/?dept=/ad2021">
                      {/* <img src="https://nitw.ac.in/api/static/files/new_2023-5-16-17-34-35.jpg" alt="B.Tech GIF" /> */}
                      <span style={{ textTransform: "", fontWeight: "bold", fontSize: "20px" }}>PDF</span></a>
                  </div>
                }
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)", // Change the background color on hover
                    color: "blue", // Change the text color on hover
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)", // Add box shadow on hover for the glass effect
                  },
                }}
              />
            </Tabs>
            <IconButton
              onClick={() => setActiveTab((prevTab) => Math.min(prevTab + 1, 9))}
              disabled={activeTab === 9}
              aria-label="next-tab"
            >
              <ChevronRight />
            </IconButton>
          </div>


          {/* {activeTab === 0 && (
            <p>B.Tech Admissions Data</p>
          )}
          {activeTab === 1 && (
            <p>M.Tech Admissions Data</p>
          )}
          {activeTab === 2 && (
            <p>Ph.D. Admissions Data</p>
          )}
               {activeTab === 3 && (
            <p>M.C.A Admissions Data</p>
          )}
          {activeTab === 4 && (
            <p>M.B.A Admissions Data</p>
          )}
          {activeTab === 5 && (
            <p>M.Sc. Admissions Data</p>
          )} */}
          <br></br>
          <div style={{ borderTop: "2px solid grey", padding: "2% 0%" }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              fontFamily="Merriweather"
              style={{ textAlign: "center", color: "#b80924" }}
            >
              Newsletters
            </Typography>
            <center>
              <a href="https://nitw.ac.in/newsletter" target="_blank">
                {/* <img src="https://nitw.ac.in/api/static/files/Newsletter_December_2023_2024-2-9-11-57-8.jpeg" alt="convocation 2022" style={{ textAlign: "center", width: "480px", height: "300px" }} /> */}
                {/* <img src="https://nitw.ac.in/api/static/files/March_Newsletter_2024_2024-5-9-17-17-0.jpeg" alt="convocation 2022" style={{ textAlign: "center", width: "480px", height: "300px" }} /> */}
                <img src="https://nitw.ac.in/api/static/files/july2024_news_letter_2024-7-23-19-19-9.png" alt="convocation 2022" style={{ textAlign: "center", width: "480px", height: "300px" }} />
              </a>
            </center>
            {/* <a href="https://nitw.ac.in/convocation2023">
          <Typography
            variant="h4"
            fontWeight="bold"
            fontFamily="Merriweather"
            style={{ textAlign: "center", color: "#b80924" }}
          >
          21<sup>st</sup> Convocation held on 16<sup>th</sup> Sep 2023 
          </Typography>
          <center><img src="https://nitw.ac.in/api/static/files/21st_Convocation_2023_v3_2023-7-24-17-16-27.jpg" alt="convocation 2022"  style={{width:"480px", height:"300px"}}></img></center>
        </a> */}
          </div>

        </div>
      </div>
      {/* <center>
     <div className="">
        <h6 className="mt-2 mb-2 notice-board">
          <a href="https://nitw.ac.in/Careers/" target="_blank">Opportunities (Careers / Jobs)</a>
        </h6>
      </div>
      </center> */}
      <section className="notice-board-section">
        <div className="containercontainer-fluid" >
          <ul className="nav nav-pills nav-justified">
            <li className="nav-item btn btn-sm btn-outline-danger">
              <a className="nav-link " aria-current="page" href="http://ir.library.nitw.ac.in:8080/jspui/">Dspace@NITW</a>
            </li>
            <li className="nav-item btn btn-sm btn-outline-danger">
              <a className="nav-link " href="https://nitw.ac.in/Careers/">Opportunities (Careers / Jobs)</a>
            </li>
            <li className="nav-item btn btn-sm btn-outline-danger ">
              <a className="nav-link " href="https://erp.nitw.ac.in/erp/login">SMILE ERP</a>
            </li>

            <li className="nav-item btn btn-sm btn-outline-danger ">
              <a className="nav-link " target="_blank" href="https://eoffice.nitw.ac.in/">eOffice</a>
            </li>

            <li className="nav-item btn btn-sm btn-outline-danger">
              <a className="nav-link " href="https://www.nitw.ac.in/uba/">Unnat Bharat Abhiyaan</a>
            </li>

            <li className="nav-item btn btn-sm btn-outline-danger">
              <a className="nav-link " href="https://www.easytourz.com/BT-EmabedTour/all/b5f17aa2b040a89f">Virtual Tour</a>
            </li>
          </ul>
        </div>
      </section>

    </div>

  );
}

export default SocialFeed;
