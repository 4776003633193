import { useState } from "react";
import '../css/cseDeptStyle.css';
const HSSDeptPage = () => {


  const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
  const [peopleTab, setPeopleTab] = useState("Faculty");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    console.log(tabId)
  }

  const handlePeopleTabClick = (tabId) => {
    setPeopleTab(tabId);
    console.log(tabId)
  }
  return (
    <><div className="dept-wrapper">
      <div className="container">
        <section className="py-4 header">
          <header>
            {/* */}
            {/*<img src="CSEDeptAssets/images/CS-banner_NITW-new.png" className="img-fluid"/>*/}
            <img
              src="https://nitw.ac.in/api/static/files/HSS_Banner_2023-9-30-17-39-39.jpg" className="img-fluid" />
          </header>
          <div className="row">
            <div className="col-md-3">
              {/* Tabs nav */}
              <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                aria-orientation="vertical">
                {/*<a className="nav-link    active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><span>Home</span></a>*/}
                <a className={`nav-link ${activeTab === 'v-pills-visionandmission' ? 'active' : ''}`} id="v-pills-visionandmission-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-visionandmission')}
                  role="tab" aria-controls="v-pills-visionandmission"
                  aria-selected="false">
                  <span>About</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-academics' ? 'active' : ''}`}
                  id="v-pills-academics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-academics')} role="tab"
                  aria-controls="v-pills-academics" aria-selected="false">
                  <span>Academic Programmes</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-Faculty' ? 'active' : ''}`} id="v-pills-Faculty-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Faculty')}
                  role="tab" aria-controls="v-pills-Faculty" aria-selected="false">
                  <span>People </span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-NotableAlumni' ? 'active' : ''}`} id="v-pills-NotableAlumni-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-NotableAlumni')}
                  role="tab" aria-controls="v-pills-NotableAlumni"
                  aria-selected="false">
                  <span>Notable Alumni </span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-Students' ? 'active' : ''}`}
                  id="v-pills-Students-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Students')} role="tab"
                  aria-controls="v-pills-Students" aria-selected="false">
                  <span>Students Society </span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-AcademicResearchGroup' ? 'active' : ''}`} id="v-pills-AcademicResearchGroup-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-AcademicResearchGroup')}
                  role="tab"
                  aria-controls="v-pills-AcademicResearchGroup" aria-selected="false"> <span>Academic Research
                    Group</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-Awards' ? 'active' : ''}`} id="v-pills-Awards-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Awards')}
                  role="tab" aria-controls="v-pills-Awards"
                  aria-selected="false"> <span>Awards and Honours</span> </a>
                {/*<a className="nav-link ${activeTab === ? 'active' : ''}   " id="v-pills-ResearchScholars-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-ResearchScholars')} href="#v-pills-ResearchScholars" role="tab" aria-controls="v-pills-ResearchScholars" aria-selected="false"><span>Research Scholars</span></a>*/}
                <a className={`nav-link ${activeTab === 'v-pills-labs' ? 'active' : ''}`} id="v-pills-labs-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-labs')} role="tab"
                  aria-controls="v-pills-labs" aria-selected="false">
                  <span>Laboratory & Facilities</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-PhotoGallery' ? 'active' : ''}`} id="v-pills-PhotoGallery-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-PhotoGallery')}
                  role="tab" aria-controls="v-pills-PhotoGallery"
                  aria-selected="false"> <span>Photo Gallery</span> </a>

              </div>
              <div className="head_of_dept"> <a href="https://erp.nitw.ac.in/ext/profile/hs-vishwanathan"
                target="_blank"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/hs-vishwanathan" alt="" className="img-fluid" width="190" height="220" />
                <h6>Prof. M Raja Vishwanathan</h6>
              </a>
                <p className="justified-text"> Head of the Department <br /> <a href="som_hod@nitw.ac.in"
                  style={{ color: "#808080" }}>humanities_hod@nitw.ac.in</a> <br />9490164798</p>
              </div>
            </div>
            <div className="col-md-9">
              {/* Tabs content */}
              <div className="tab-content" id="v-pills-tabContent">
                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade    show active p-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"></div>*/}

                <div className={`tab-pane fade ${activeTab === 'v-pills-visionandmission' ? 'show active' : 'alumniTabHide'} `} id="v-pills-visionandmission"
                  role="tabpanel" aria-labelledby="v-pills-visionandmission-tab">
                  <div className="intro-section">
                    {/* <h4 className=" mb-4">Welcome to Computer Science and Engineering Department</h4>*/}
                    <p className="justified-text">The Department of Humanities and Social Sciences at NIT Warangal was started in2009. The Department offers courses across the board ranging from basic to advanced courses in English Communication and Two Foreign languages catering to diverse needs of students across UG /PG and PhD levels.
                    </p>
                    <p className="justified-text">Besides, the Department also offers research programs in the broad areas of ELT, British literature and American literature through regular, part time and self- finance modes.
                    </p>
                    <p className="justified-text"> The Department has highly qualified and committed faculty members who are well recognized and are members of many Boards across colleges affiliated to state level universities.
                    </p>
                    <p className="justified-text">During the academic year2020-21, as per NEP guidelines 2020,  the Department of Humanities and Social Sciences has revised the syllabi of existing courses and has designed 16 new courses at UG/PG/PhD levels. As part of curriculum revision the department has also designed a Minor program to be offered at UG Level titled ‘Professional Communication Skills for STEM
                    </p>

                    <p className="justified-text">The Department of H&SS constantly thrives to impart quality education and life skills that enable students to become successful on the personal, academic, and professional front; to foster critical thinking through a diverse range of courses aimed at making students industry ready; to encourage and facilitate research in advanced areas of Humanities.
                    </p>



                  </div>
                  <div className="row vision-mission-section">
                    <div className="col-md-6 vision-section">
                      <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/vision.png" className="img-fluid mr-3"
                        alt="" /> Vision</h4>
                      <p className="  mb-4">To promote excellence in education by offering a variety of courses at the undergraduate and post graduate levels to equip the students of National Institute of Technology Warangal with the requisite linguistic and people skills required to excel in any field.

                        <br>
                        </br>Humanities and Social Sciences Department offers courses across the board ranging from basic to advanced courses in English, catering for diverse needs of students </p> <img src="-" alt=""
                          className="img-fluid" />

                    </div>
                    <div className="col-md-6 mission-section">
                      <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/mission.png" className="img-fluid mr-3"
                        alt="" /> Mission</h4>
                      <p className="  mb-2">To impart quality education and life skills that enable students to become successful on the personal, academic, and professional front; to foster critical thinking through a diverse range of courses aimed at making students industry ready; to encourage and facilitate  research in advanced areas of Humanities.</p>


                    </div>
                  </div>
                  <div className="text-center">
                    <h4 className="mt-2 mb-2 notice-board1">
                      <a href="https://www.nitw.ac.in/page/?url=/NoticeBoardHSS/HumanitiesandSocialSciences"> Notices/Announcements</a>
                    </h4>
                  </div>
                </div>



                {/* <div className={`tab-pane ${activeTab === 'v-pills-academics' ? 'show active' : 'alumniTabHide'} fade   " id="v-pills-academics`} role="tabpanel"
                                  aria-labelledby="v-pills-academics-tab">
                                
                                  <div className="row">

                                      <div className="col-md-12 col-sm-12">
                                          <div className="card-4">
                                              <div className="card-detail">
                                                  <div className="head-bx">
                                                    
                                                      <p>Department has been involved in teaching courses to B. Tech, Integrated M. Sc., MBA and Ph. D students at the institute level. Courses taught by Humanities and Social Science are as follows: </p>
                                                    
                                                      </div>
                                                      </div>
                                                      </div>
                              </div>
                              </div>
                              </div> */}











                <div className={`tab-pane ${activeTab === 'v-pills-academics' ? 'show active' : 'alumniTabHide'} fade   " id="v-pills-academics`} role="tabpanel"
                  aria-labelledby="v-pills-academics-tab">

                  <div className="row">








                    <div className="col-md-12 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <p>Department has been involved in teaching courses to B. Tech, Integrated M. Sc., MBA and Ph. D students at the institute level. Courses taught by Humanities and Social Science are as follows: </p>

                            <h3>B.Tech courses</h3>


                            <div className="table-responsive">

                              <table className="table table-bordered table-gap" >
                                <thead>
                                  <tr>
                                    <th>S.N0</th>
                                    <th>Names of courses approved in BOS</th>
                                    <th>Level</th>
                                    <th>Course Code</th>
                                    <th>No of credits</th>
                                    <th>Course Description</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>English for Oral Communication and Report Writing</td>
                                    <td>I B.Tech I Sem EEE branch (CCC)</td>
                                    <td>HS131</td>
                                    <td>03</td>
                                    <td><p><a href="https://oldweb.nitw.ac.in/media/uploads/2021/11/18/hs131.pdf">View/Download</a></p></td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>Communication Skills and Report Writing</td>
                                    <td>I B. Tech II Sem Mech Engg branch (CCC)</td>
                                    <td>HS181</td>
                                    <td>03</td>
                                    <td><p><a href="https://oldweb.nitw.ac.in/media/uploads/2021/11/18/hs181.pdf">View/Download</a></p></td>

                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>English for Technical Communication</td>
                                    <td>I B.Tech I Semester (CCC) ECE, Bio-Tech, CSE, Civil, Chemical, MME</td>
                                    <td>HS132</td>
                                    <td>03</td>
                                    <td><p><a href="https://oldweb.nitw.ac.in/media/uploads/2021/11/18/hs132.pdf">View/Download</a></p></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                          </div>

                        </div>
                      </div>
                    </div>




                    <div className="col-md-12 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>Minor Programs courses</h3>
                            <p className="justified-text">Minors- 18 credits :</p>
                            <p className="justified-text">Professional Communication Skills for STEM.*</p>

                            <div className="table-responsive">

                              <table className="table table-bordered table-gap" >
                                <thead>
                                  <tr>
                                    <th>S.N0</th>
                                    <th>Names of courses approved in BOS</th>
                                    <th>Level UG</th>
                                    <th>Course Code</th>
                                    <th>No of credits</th>
                                    <th>Course Description</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>Creative and Critical Thinking Skills</td>
                                    <td>II-B.Tech I Sem</td>
                                    <td>HSM01</td>
                                    <td>03</td>
                                    <td><p><a href="https://oldweb.nitw.ac.in/media/uploads/2021/11/18/hsm01.pdf">View/Download</a></p></td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>Media and Language</td>
                                    <td>II-B.Tech II Sem</td>
                                    <td>HSM02</td>
                                    <td>03</td>
                                    <td><p><a href="https://oldweb.nitw.ac.in/media/uploads/2021/11/18/hsm02.pdf">View/Download</a></p></td>

                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>Globalisation and World Englishes </td>
                                    <td>III-B.Tech I Sem</td>
                                    <td>HSM03 </td>
                                    <td>03</td>
                                    <td><p><a href="https://oldweb.nitw.ac.in/media/uploads/2021/11/18/hsm03.pdf">View/Download</a></p></td>
                                  </tr>


                                  <tr>
                                    <td>4</td>
                                    <td>Practical and Communicative Writing </td>
                                    <td>III-B.Tech II Sem</td>
                                    <td>HSM04 </td>
                                    <td>03</td>
                                    <td><p><a href="https://oldweb.nitw.ac.in/media/uploads/2021/11/18/hsm04.pdf">View/Download</a></p></td>
                                  </tr>

                                  <tr>
                                    <td>5</td>
                                    <td>Psychology of Life and Career Transition </td>
                                    <td>IV B.Tech I Sem</td>
                                    <td>HSM05 </td>
                                    <td>03</td>
                                    <td><p><a href="https://oldweb.nitw.ac.in/media/uploads/2021/11/18/hsm05.pdf">View/Download</a></p></td>
                                  </tr>

                                  <tr>
                                    <td>6</td>
                                    <td>Project <br></br>
                                      Article Writing on
                                      Discipline specific topics: (Data Collection /samples
                                      in Writing a research
                                      paper )
                                      Or
                                      Writing a Research
                                      Proposal</td>
                                    <td>IV-B.Tech II Sem</td>
                                    <td>HSM06</td>
                                    <td>0</td>
                                    <td><p><a href="-">-</a></p></td>
                                  </tr>

                                </tbody>
                              </table>
                            </div>



                          </div>

                        </div>
                      </div>
                    </div>




                    <div className="col-md-12 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>Open Electives </h3>


                            <div className="table-responsive">

                              <table className="table table-bordered table-gap" >
                                <thead>
                                  <tr>
                                    <th>S.N0</th>
                                    <th>Names of courses approved in BOS</th>
                                    <th>Level</th>
                                    <th>Course Code</th>
                                    <th>No of credits</th>
                                    <th>Course Description</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>Soft Skills</td>
                                    <td>Open Elective III-B.Tech II Sem</td>
                                    <td>HS395</td>
                                    <td>03</td>
                                    <td><p><a href="https://oldweb.nitw.ac.in/media/uploads/2021/11/18/hs395_WnMAnPU.pdf">View/Download</a></p></td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>Foreign Language Course in French</td>
                                    <td>Open Elective III-B.Tech II Sem</td>
                                    <td>HS396</td>
                                    <td>03</td>
                                    <td><p><a href="https://oldweb.nitw.ac.in/media/uploads/2021/11/18/hs396.pdf">View/Download</a></p></td>

                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>Foreign Language Course in German</td>
                                    <td>IV-B.Tech I Sem</td>
                                    <td>HS445</td>
                                    <td>03</td>
                                    <td><p><a href="https://oldweb.nitw.ac.in/media/uploads/2021/11/18/hs445.pdf">View/Download</a></p></td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>Total </td>
                                    <td></td>
                                    <td>12</td>
                                    <td><p><a href="-"></a></p></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>





                          </div>

                        </div>
                      </div>
                    </div>








                    <div className="col-md-12 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>Integrated M.Sc. courses</h3>


                            <div className="table-responsive">

                              <table className="table table-bordered table-gap" >
                                <thead>
                                  <tr>
                                    <th>S.N0</th>
                                    <th>Names of courses approved in BOS</th>
                                    <th>Level</th>
                                    <th>Course Code</th>
                                    <th>No of credits</th>
                                    <th>Course Description</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>Communicative English-I</td>
                                    <td>I Msc. Integrated I Sem (CCC)</td>
                                    <td>HSI131</td>
                                    <td>03</td>
                                    <td><p><a href="https://oldweb.nitw.ac.in/media/uploads/2021/11/18/hs1131.pdf">View/Download</a></p></td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>Communicative English-II</td>
                                    <td>I Msc. Integrated II Sem (CCC)</td>
                                    <td>HSI181</td>
                                    <td>03</td>
                                    <td><p><a href="https://oldweb.nitw.ac.in/media/uploads/2021/11/18/hsi181.pdf">View/Download</a></p></td>

                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>Sanskrit CCS Second Language</td>
                                    <td>I Msc. Integrated I Sem (CCC)</td>
                                    <td>HSI132</td>
                                    <td>03</td>
                                    <td><p><a href="https://oldweb.nitw.ac.in/media/uploads/2021/11/18/course-code.pdf">View/Download</a></p></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>


                          </div>

                        </div>
                      </div>
                    </div>




                    <div className="col-md-12 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>Courses for Ph.D students </h3>


                            <div className="table-responsive">

                              <table className="table table-bordered table-gap" >
                                <thead>
                                  <tr>
                                    <th>S.N0</th>
                                    <th>Names of courses approved in BOS</th>
                                    <th>Level</th>
                                    <th>Course Code</th>
                                    <th>No of credits</th>
                                    <th>Course Description</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>English for Scientific Communication</td>
                                    <td>PhD</td>
                                    <td>HS7001</td>
                                    <td>03</td>
                                    <td><p><a href="https://oldweb.nitw.ac.in/media/uploads/2021/11/18/hs7001.pdf">View/Download</a></p></td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>Research Writing and Professional Ethics</td>
                                    <td>PhD</td>
                                    <td>HS7002</td>
                                    <td>03</td>
                                    <td><p><a href="https://oldweb.nitw.ac.in/media/uploads/2021/11/18/hs7002.pdf">View/Download</a></p></td>

                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>Pedagogical Practices & Classroom Praxis</td>
                                    <td>PhD</td>
                                    <td>HS7003</td>
                                    <td>03</td>
                                    <td><p><a href="https://oldweb.nitw.ac.in/media/uploads/2021/11/18/hs7003.pdf">View/Download</a></p></td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>TOTAL</td>
                                    <td></td>
                                    <td>09</td>
                                    <td><p><a href="-"></a></p></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                          </div>

                        </div>
                      </div>
                    </div>



                    <div className="col-md-12 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>MBA course</h3>


                            <div className="table-responsive">

                              <table className="table table-bordered table-gap" >
                                <thead>
                                  <tr>
                                    <th>S.N0</th>
                                    <th>Names of courses approved in BOS</th>
                                    <th>Level</th>
                                    <th>Course Code</th>
                                    <th>No of credits</th>
                                    <th>Course Description</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>Business Communication</td>
                                    <td>I PG I Semester (MBA)</td>
                                    <td>HS5101</td>
                                    <td>02</td>
                                    <td><p><a href="https://oldweb.nitw.ac.in/media/uploads/2021/11/18/mba.pdf">View/Download</a></p></td>
                                  </tr>

                                </tbody>
                              </table>
                            </div>


                          </div>

                        </div>
                      </div>
                    </div>




                  </div>
                </div>


                <div className={`tab-pane ${activeTab === 'v-pills-labs' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-labs" role="tabpanel"
                  aria-labelledby="v-pills-labs-tab">
                  <div className="row laboratory-facilities">
                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">

                      <h3 className="">LANGUAGE LABORATORY: </h3>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="card item-card card-block">


                            <p className="justified-text">
                              The Department runs a language laboratory located in the Computer Centre. The main objective is to strengthen the language and communicative skills of students. It now has a LAN of 40 computers with Ideal Systems platform and several softwares to teach proper English pronunciation and stress and intonation. Intensive training is offered in short oral presentations and Group Discussions. </p>
                            <h5 className="card-title  mb-2">New lab established/ Equipment and Software procured</h5>
                            <p className="justified-text"><b>Dr. K. Madhavi,</b> TLC Meeting Room budget worth Rs 3,32,406=00 , Meeting Tables and Chairs during 27-11-2020.
                            </p>
                            <p className="justified-text"><b>Dr. K. Madhavi,</b> Computer Lab, TLC budget worth Rs 1,80,540=00 Computer tables 31- 12-2020,.
                            </p>





                          </div>
                        </div>

                        <div className="col-md-6"> <img src="https://nitw.ac.in/api/static/files/Computer_Centre_2023-9-22-16-46-10.png"
                          alt="Photo of sunset" className="img-fluid" /> </div>
                      </div>
                    </div>








                  </div>

                </div>

                <div className={`tab-pane ${activeTab === 'v-pills-Phd' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Phd" role="tabpanel"
                  aria-labelledby="v-pills-Phd-tab">
                  <h4 className=" mb-4">Phd</h4> <a href="" target="_blank"> <span className="highlight">P</span>h.D.
                    Students List ( Full / Part Time) <i className="fa fa-link " aria-hidden="true"></i> </a>
                </div>
                <div className={`tab-pane ${activeTab === 'v-pills-Faculty' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Faculty" role="tabpanel"
                  aria-labelledby="v-pills-Faculty-tab">
                  <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'Faculty' ? 'active' : ''} `} id="nav-faculty-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-faculty"
                      aria-selected="true" onClick={() => { handlePeopleTabClick("Faculty") }}>Faculty</a>
                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'AdjunctFaculty' ? 'active' : ''} `}
                      id="nav-AdjunctFaculty-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-AdjunctFaculty" aria-selected="false" onClick={() => { handlePeopleTabClick("AdjunctFaculty") }}> Adjunct Faculty/ Visiting Professor </a>

                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'NonTeachingStaff' ? 'active' : ''} `}
                      id="nav-NonTeachingStaff-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-NonTeachingStaff" aria-selected="false" onClick={() => { handlePeopleTabClick("NonTeachingStaff") }}>Non-Teaching
                      Staff</a>


                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'ResearchScholars' ? 'active' : ''} `} id="nav-ResearchScholars-tab"
                      data-toggle="tab" role="tab"
                      aria-controls="nav-ResearchScholars" aria-selected="false" onClick={() => { handlePeopleTabClick("ResearchScholars") }}>Research Scholars</a>


                  </div>
                  <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"
                    style={{ marginTop: "5%", marginLeft: "1%" }}>
                    <div className={`tab-pane ${peopleTab === 'Faculty' ? 'show active' : ''} fade`} id="nav-faculty" role="tabpanel"
                      aria-labelledby="nav-faculty-tab">
                      {/*<h4 className=" mb-4">Faculty</h4>*/}
                      {/* <a href="" target="_blank"><span className="highlight">F</span>aculty Profiles <i className="fa fa-external-link" aria-hidden="true"></i></a>*/}
                      <section id="profile-info">

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/hs-spoorthi.b"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16920/16920.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/hs-spoorthi.b"
                                    target="_blank">Prof. B. Spoorthi</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8008461503<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:spoorthi.b@nitw.ac.in ">spoorthi.b@nitw.ac.in   </a> </p>
                                  <p className="justified-text"> Educational Technology, AI for Language Education, Virtual Labs for Language Education, TBLT, AR/VR for Education, CLIL, Social Emotional Learning in ELT.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/hs-madhavik24"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16617/16617.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/hs-madhavik24"
                                    target="_blank">Prof. Madhavi Reddy Kesari</a>
                                  </h5> <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i>8332969522 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:madhavik24@nitw.ac.in">madhavik24@nitw.ac.in</a>
                                  </p>
                                  <p className="justified-text">Innovative and effective methods to improve reading and writing skills; Designing task-based ESP courses based on learner needs;Designing self instructional materials for distance learners;Reflective teaching and Continuous Professional Development;Academic writing skills;Multiple intelligences -Visual Literacies;Learner strategies, methods and behaviourist approaches to language learning;</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/hs-vishwanathan"
                              target="_blank" className="img-fluid"> <img
                                src="https://wsdc.nitw.ac.in/facultynew/media/16616/16616.jpg" alt="" className="img-fluid" width="150" height="180" /> </a>
                            </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/hs-vishwanathan"
                                    target="_blank">Prof. M. Raja Vishwanathan</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969523<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:vishwanathan@nitw.ac.in  ">vishwanathan@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text"> Bilingualism and bilingual education;academic writing;genre analysis; academic reading;cultural politics in language teaching;critical pedagogy; American literature; British literature; Indian Writing in English;</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12 profile-info-individual">
                            <div className="row">
                              <div className="col-md-3"> <a
                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17038"
                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17038/17038.jpg" alt=""
                                  className="img-fluid" width="150" height="180" /> </a> </div>
                              <div className="col-md-9">
                                <div className="card ">
                                  <div className="data">
                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17038"
                                      target="_blank">Prof. R Vennela</a> </h5>
                                    <span>Assistant Professor</span>
                                    <p className="contact-info"> <i className="fa fa-phone"
                                      aria-hidden="true"></i> 9491065961 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:rvennela@nitw.ac.in   ">rvennela@nitw.ac.in  </a>
                                    </p>
                                    <p className="justified-text"> History of English language learning and teaching in India; Bilingual education; Language planning and policy; Translation; Lexicography</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>







                        </div>

                      </section>




                    </div>
                    <div className={`tab-pane ${peopleTab === 'NonTeachingStaff' ? 'show active' : ''} fade`} id="nav-NonTeachingStaff" role="tabpanel"
                      aria-labelledby="nav-NonTeachingStaff-tab">
                      <div className="row academic_group">


                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/P._Hymavathi_2023-9-29-16-42-2.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">P. Hymavathi</h6> <span>Asst SG II </span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 6281198995 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:-">phymavathi@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/P._Sammaiah_2023-9-29-16-38-59.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">P. Sammaiah</h6> <span>Caretaker</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9963124490 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:sammaiahnitw12@gmail.com">sammaiahnitw12@gmail.com</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/T._Laxmi_2023-9-29-16-40-17.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">T. Laxmi</h6> <span>Technician</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969434 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:laxminitw318@gmail.com">laxminitw318@gmail.com</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>

                    </div>
                    <div className={`tab-pane ${peopleTab === 'ResearchScholars' ? 'show active' : ''} fade`} id="nav-ResearchScholars" role="tabpanel"
                      aria-labelledby="nav-ResearchScholars-tab">
                      {/*<h4 className="">Ph.D. Awardee</h4>
                   <p className="justified-text">
                    <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf" target="_blank">
                      <span className="highlight">L</span>ist of Ph.D. Awardees <i className="fa fa-link " aria-hidden="true"></i>
                    </a>
                  </p>*/}






                      <div className="row phd-std-row">







                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Vidyasree_B_2023-9-22-16-55-39.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Indian Writing in English </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Vidyasree B.</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p className="contact-info">  - <br /> <span>

                              <a href="mailto:vidyasree@student.nitw.ac.in">vidyasree@student.nitw.ac.in</a></span> </p>


                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Aiswaria_P._R._2023-9-22-16-57-50.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>English Literature and Melancholia</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Aiswaria P. R.</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p className="contact-info">  -
                              <br /> <span><a href="mailto: aiswariapr@student.nitw.ac.in" > aiswariapr@student.nitw.ac.in</a> </span> </p>

                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Lalitha_Devi_B._2023-9-22-16-59-25.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Pre-Service Teacher Education</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Lalitha Devi B.</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p className="contact-info">  -
                              <br /> <span> <a href="mailto:lalitha.b@student.nitw.ac.in" >lalitha.b@student.nitw.ac.in</a> </span></p>

                          </div>
                        </div>




                        <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Krishna_Priya_2023-9-29-17-40-31.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>A critical study on cultural and psychological aspects in the select novels
                                      of Anees Salim</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Krishna Priya</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p className="contact-info">  -
                              <br />  <span><a href="mailto:kpriyadas@student.nitw.ac.in" >kpriyadas@student.nitw.ac.in</a> </span></p>

                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Kavya_Rayala_2023-9-22-17-2-56.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Multiple Intelligences approach to enhance communication skills of
                                      students in higher education</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Kavya Rayala</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p className="contact-info">  -
                              <br /> <span> <a href="mailto:kr720122@student.nitw.ac.in" >kr720122@student.nitw.ac.in</a></span> </p>

                          </div>
                        </div>








                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Jalli_Ravi_Prakash_2023-9-22-17-5-10.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>English Language Teaching and Content and Language Integrated
                                      Learning (CLIL) Approach</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Jalli Ravi Prakash</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p className="contact-info">  -
                              <br /> <span> <a href="mailto:jr720121@student.nitw.ac.in" >jr720121@student.nitw.ac.in</a></span> </p>

                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Vijaya_Ramala_2023-9-22-17-6-44.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>A Strategy Based Intervention to enhance the English-Speaking
                                      Proficiency of Government Polytechnic Students of Telangana State</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Vijaya Ramala</h6>
                            <span>Part-Time Research Scholar</span>
                            <hr />

                            <p className="contact-info">  -
                              <br /> <span><a href="mailto:vr721116@student.nitw.ac.in" >vr721116@student.nitw.ac.in</a></span>  </p>

                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Takhee_Pasha_MD_2023-9-22-17-8-12.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>A comparative study of parental involvement in English language learning
                                      of secondary school students in urban and rural schools of erstwhile
                                      Warangal district during covid-19 pandemic. </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Takhee Pasha MD.</h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">-
                              <br /> <span> <a href="mailto:tp721117@student.nitw.ac.in" >tp721117@student.nitw.ac.in</a> </span></p>

                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Rehana_Iffath_2023-9-22-17-9-44.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Project-based learning in Tribal welfare colleges.</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Rehana Iffath  </h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> -
                              <br /> <span><a href="mailto:ri712185@student.nitw.ac.in" >ri712185@student.nitw.ac.in</a></span>  </p>

                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Jhansi_Rani_B_2023-9-22-17-11-31.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Technology based Extramural activities to enhance listening and speaking
                                      skills of engineering students with low language proficiency</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Jhansi Rani B </h6>
                            <span>Part-Time Research Scholar</span>
                            <hr />

                            <p className="contact-info"> -
                              <br /> <span><a href="mailto:jr21hsrep02@student.nitw.ac.in" >jr21hsrep02@student.nitw.ac.in </a> </span> </p>

                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Aiswarya_Sureshan_2023-9-22-17-12-55.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Interdisciplinary study on the convergence of Art and Literature </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Aiswarya Sureshan </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p className="contact-info">  -
                              <br />  <span><a href="mailto:as21hsrer01@student.nitw.ac.in" >as21hsrer01@student.nitw.ac.in </a> </span></p>

                          </div>
                        </div>







                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Isha_Srishti_Panna_2023-9-22-17-14-36.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>English Literature – War Literature </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Isha Srishti Panna </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info"> -
                              <br /> <span> <a href="mailto:is21hsrer02@student.nitw.ac.in" >is21hsrer02@student.nitw.ac.in </a> </span></p>

                          </div>
                        </div>






                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Prathyusha_Korimi_2023-9-22-17-16-17.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>English Language Teaching: Employability Skills </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Prathyusha Korimi  </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  -
                              <br />  <span><a href="mailto:kp21hsrer03@student.nitw.ac.in" >kp21hsrer03@student.nitw.ac.in  </a></span> </p>

                          </div>
                        </div>






                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Ahima_K._S._2023-9-22-17-17-49.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Asian Literature </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Ahima K. S</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  -
                              <br /> <span> <a href="mailto:ks22hsr1r01@student.nitw.ac.in" >ks22hsr1r01@student.nitw.ac.in </a></span> </p>

                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Gopika_Jayachandran_2023-9-22-17-19-3.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>English Language Teaching</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Gopika Jayachandran</h6>
                            <span>Full-Time Research Scholar      </span>
                            <hr />

                            <p className="contact-info"> <span>  <a href="mailto:gj22hsr1r02@student.nitw.ac.in" >gj22hsr1r02@student.nitw.ac.in</a></span> </p>

                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Divya_Lekshmi_M._S._2023-9-22-17-20-49.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Animal Studies
                                    </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Divya Lekshmi M. S.</h6>
                            <span>Full-Time Research Scholar </span>
                            <p className="contact-info">  <span><a href="mailto:dl22hsr1r03@student.nitw.ac.in" >dl22hsr1r03@student.nitw.ac.in</a></span> </p>

                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Lakhavath_Beulah_2023-9-22-17-32-21.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>English Language Education - Social and Emotional Learning</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Lakhavath Beulah</h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info"> <span> <a href="mailto:lb22hsr1r04@student.nitw.ac.in" >lb22hsr1r04@student.nitw.ac.in</a></span>  </p>


                          </div>
                        </div>






                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/B._M._Mukesh_Kumar_2023-9-22-17-33-40.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>English Language Teaching - Enhancing Creative Writing Skills using CoConstruction in ESL Classrooms.</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">B. M. Mukesh Kumar</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p className="contact-info">  -
                              <br /> <span> <a href="mailto:bm22hsr1s01@student.nitw.ac.in" >bm22hsr1s01@student.nitw.ac.in</a></span> </p>

                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Anupama_Venugopal_2023-9-22-17-38-42.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>English Language Teaching - Design Thinking and ELT</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Anupama Venugopal</h6>
                            <span>Part-Time Research Scholar  </span>
                            <hr />

                            <p className="contact-info">  -
                              <br /> <span> <a href="mailto:av22hsr1p01@student.nitw.ac.in" >av22hsr1p01@student.nitw.ac.in</a> </span></p>

                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Sampat_Kumar_Kokkula_2023-9-22-17-40-14.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>English Language Teaching - Impact of Bilingual and digitized Text Books
                                      on students to acquire English Language Skills at Secondary School level
                                      in Telangana State</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Sampat Kumar Kokkula</h6>
                            <span>Part-Time Research Scholar  </span>
                            <hr />

                            <p className="contact-info">  -
                              <br /> <span>  <a href="mailto: ks22hsr1p02@student.nitw.ac.in" > ks22hsr1p02@student.nitw.ac.in </a> </span></p>

                          </div>
                        </div>






                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Garima_Sukhwal_2023-9-22-17-41-26.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Language and Linguistics
                                    </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Garima Sukhwal</h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  -
                              <br /> <span>  <a href="mailto:gs22hsr2r01@student.nitw.ac.in" >gs22hsr2r01@student.nitw.ac.in </a></span>  </p>

                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Harishma_A_Gireesh_2023-9-29-16-36-24.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : Gerontology<b>-</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Harishma A. Gireesh</h6>
                            <span>Full-Time Research Scholar    </span>
                            <hr />

                            <p className="contact-info">  -
                              <br /> <span>  <a href="mailto:ha22hsr1r01@student.nitw.ac.in" >ha22hsr1r01@student.nitw.ac.in  </a></span> </p>

                          </div>
                        </div>








                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Roopna_Ravindran_2023-9-29-16-34-23.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : Integrating SDGs into Language Learning <b>- </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Roopna Ravindran</h6>
                            <span>Part-Time Research Scholar    </span>
                            <hr />

                            <p className="contact-info">  -
                              <br /> <span> <a href="mailto:rr23hsr1p01@student.nitw.ac.in" >rr23hsr1p01@student.nitw.ac.in  </a> </span></p>

                          </div>
                        </div>
                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Somreeta_Dinda_2024-2-15-12-0-28.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : Medical Humanities <b>- </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Somreeta Dinda</h6>
                            <span>Full-Time Research Scholar    </span>
                            <hr />

                            <p className="contact-info">  -
                              <br /> <span> <a href="mailto:sd23hsr2r01@student.nitw.ac.in" >sd23hsr2r01@student.nitw.ac.in  </a> </span></p>

                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Madike_Divya_2024-2-15-12-4-42.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : Translanguaging in ELT <b>- </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Madike Divya </h6>
                            <span>Full-Time Research Scholar    </span>
                            <hr />

                            <p className="contact-info">  -
                              <br /> <span> <a href="mailto:md23hsr2r02@student.nitw.ac.in" >md23hsr2r02@student.nitw.ac.in  </a> </span></p>

                          </div>
                        </div>














                      </div>



                    </div>
                  </div>
                </div>
                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade   " id="v-pills-NonTeachingStaff" role="tabpanel" aria-labelledby="v-pills-NonTeachingStaff-tab"><h4 className=" mb-4">Non-Teaching Staff</h4><table className="table table-sm "><thead><tr><th>Sl. No</th><th>Name of the Staff Member</th><th>Designation</th><th>Division</th></tr></thead><tbody><tr><td>1</td><td>Smt. K.S.S.S. Padmasri</td><td>Senior Technical Assistant</td></tr><tr><td>2</td><td>Smt. Shalini Avala</td><td>Technical Assistant</td></tr><tr><td>3</td><td>Sri . R Shivamallikarjun</td><td>Technical Assistant</td><td>CSE</td></tr><tr><td>4</td><td> Sri R. Jeevan kumar</td><td>Senior Technician</td></tr><tr><td>5</td><td>Sri. D. Ramesh</td><td>Senior Technician</td></tr><tr><td>6</td><td>Sri. B. Ramesh</td><td>Junior Assistant</td></tr><tr><td>7</td><td>K. Venkatamma</td><td> Technician (upgd)</td></tr><tr><td>8</td><td> K. Jayaraj</td><td>Caretaker SG-II</td></tr><tr><td>9</td><td>B. Sunil kumar</td><td>Electrician (Daily wage)</td></tr><tr><td>10</td><td>T. Varsha Sree</td><td>Skilled</td></tr><tr><td>11</td><td> M. Padma Rao</td><td>Skilled</td></tr><tr><td>12</td><td>P. Suma </td><td>Skilled</td></tr><tr><td>13</td><td>M. Mahendar</td><td>Un-Skilled</td></tr><tr><td>14</td><td>K. Srinivasulu</td><td>Un-Skilled</td><td>CSE</td></tr><tr><td>15</td><td>T. Sampath </td><td> Un-Skilled</td></tr></tbody></table></div>*/}
                <div className={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                  aria-labelledby="v-pills-Students-tab">
                  <div className="row std-society">

                    {/* <p className="justified-text"> 1. SPIE-Student chapter </p>
                                  <p className="justified-text"> 2. Physics association   </p>

                                  <p className="justified-text"> 3. OSA-Student chapter  </p> */}



                    <div className="col-md-3 col-6">
                      <div className="card "> <a
                        href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16920"
                        target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16920/16920.jpg" alt="" className="img-fluid" width="150" height="180" />
                      </a>
                        <div className="data"> <a
                          href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16920"
                          target="_blank">
                          <h6>Prof. B. Spoorthi</h6> <span> Faculty Advisor </span>
                          <hr />
                        </a>
                          <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i> 8008461503 <br /> <i
                            className="fa fa-envelope" aria-hidden="true"></i> <a
                              href="mailto:spoorthi.b@nitw.ac.in "
                              style={{ color: "#808080" }}>spoorthi.b@nitw.ac.in </a> </p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-3 col-6">
                                          <div className="card "> <a
                                              href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                              target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                          </a>
                                              <div className="data"> <a
                                                  href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                  target="_blank">
                                                  <h6>Prof. U. S. N. Raju</h6> <span> Treasurer </span>
                                                  <hr />
                                              </a>
                                                   <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                                          href="mailto:usnraju@nitw.ac.in"
                                                          style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p>
                                              </div>
                                          </div>
                                      </div> */}
                  </div>
                </div>
                <div className={`tab-panel ${activeTab === 'v-pills-NotableAlumni' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-NotableAlumni" role="tabpanel"
                  aria-labelledby="v-pills-NotableAlumni-tab">


                  <div className="row notable-alumni">
                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="https://nitw.ac.in/api/static/files/Dr._Jaipal_2023-9-29-10-55-12.jpg" alt="" className="img-fluid" width="150" height="180" />
                        <div className="data">
                          <h6>Dr. Jaipal </h6>

                          <p className="justified-text"><b>Batch: </b>2017<br />
                            <b>Present Position: </b>Assistant Professor at VNIT Nagpur
                          </p>

                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="https://nitw.ac.in/api/static/files/Dr._Doreswamy_2023-9-29-10-56-17.jpg" alt="" className="img-fluid" width="150" height="180" />
                        <div className="data">
                          <h6>Dr. Doreswamy</h6>

                          <p className="justified-text"><b>Batch :</b> 2017<br />
                            <b>Present Position :</b> Assistant Professor at NIT Jamshedpur
                          </p>

                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="https://nitw.ac.in/api/static/files/Dr._N._Vijay_Kumar_2023-9-29-10-57-12.jpg" alt="" className="img-fluid" width="150" height="180" />
                        <div className="data">
                          <h6>Dr. N. Vijay Kumar</h6>


                          <p className="justified-text"><b>Batch :</b> 2018<br />
                            <b>Present Position :</b> Mentor and HOD at Rajiv Gandhi University of Knowledge Technologies (RGUKT)
                          </p>




                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="https://nitw.ac.in/api/static/files/Dr._Jismy_K_Joseph_2023-9-29-10-58-7.jpg" alt="" className="img-fluid" width="150" height="180" />
                        <div className="data">
                          <h6>Dr. Jismy K Joseph</h6>
                          <p className="justified-text"><b>Batch :</b> 2022<br />
                            <b>Present Position :</b> Working as a college lecturer in an aided college in Kerala
                          </p>


                        </div>
                      </div>
                    </div>



                    {/* <div className="col-md-3 col-6">
                                          <div className="card ">
                                              <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                              <div className="data">
                                                  <h6>Sridhar Muppala</h6>



                                                   <p className="justified-text">
                                                      <b>Present Position :</b> CEO, Silicon Valley

                                                  </p>

                                             
                                              </div>
                                          </div>
                                      </div>



                                      <div className="col-md-3 col-6">
                                          <div className="card ">
                                              <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                              <div className="data">
                                                  <h6>Movva Ramu</h6>
                                                   <p className="justified-text">
                                                      <b>Present Position :</b> Microsoft Research Centre, USA
                                                  </p>

                                              </div>
                                          </div>
                                      </div>



                                      <div className="col-md-3 col-6">
                                          <div className="card ">
                                              <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                              <div className="data">
                                                  <h6>K V Sridhar</h6>
                                                   <p className="justified-text">
                                                      <b>Present Position :</b> CEO Analytics CA, USA
                                                  </p>

                                              </div>
                                          </div>
                                      </div>




                                      <div className="col-md-3 col-6">
                                          <div className="card ">
                                              <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                              <div className="data">
                                                  <h6>Jitendra Lakotia</h6>
                                                   <p className="justified-text">
                                                      <b>Present Position :</b> Former VP of Cognos. USA
                                                  </p>

                                              </div>
                                          </div>
                                      </div>



                                      <div className="col-md-3 col-6">
                                          <div className="card ">
                                              <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                              <div className="data">
                                                  <h6>Amarnadh Reddy</h6>
                                                   <p className="justified-text">
                                                      <b>Present Position :</b> CTO, CGG, Hyderabad
                                                  </p>
                                              </div>
                                          </div>
                                      </div> */}




                  </div>
                </div>

                <div className={`tab-pane ${activeTab === 'v-pills-AcademicResearchGroup' ? 'show active' : ''} fade `} id="v-pills-AcademicResearchGroup" role="tabpanel"
                  aria-labelledby="v-pills-AcademicResearchGroup-tab">
                  <div className="row academic_group">
                    <div className="col-md-12">
                      <header>
                        <h4>Academic writing, Inclusive Education and Multiple Intelligence and Lexicograhpy & Language Planning and Policy
                        </h4>
                      </header>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16617/16617.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Madhavi Reddy Kesari </h6>
                            <span>Coordinator</span>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969522 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:madhavik24@nitw.ac.in  ">madhavik24@nitw.ac.in  </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16920/16920.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. B. Spoorthi</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8008461503 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:spoorthi.b@nitw.ac.in  ">spoorthi.b@nitw.ac.in  </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16616/16616.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. M. Raja Vishwanathan</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969523 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:vishwanathan@nitw.ac.in ">vishwanathan@nitw.ac.in   </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17038/17038.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. R. Vennela</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9491065961 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:rvennela@nitw.ac.in">rvennela@nitw.ac.in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="row academic_group">
                    <div className="col-md-12">
                      <header>
                        <h4>AI, AR/VR and Educational Technology </h4>
                      </header>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16920/16920.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. B. Spoorthi</h6>   <span>Coordinator</span>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8008461503 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:spoorthi.b@nitw.ac.in  ">spoorthi.b@nitw.ac.in  </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16617/16617.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Madhavi Reddy Kesari </h6>

                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969522 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:madhavik24@nitw.ac.in  ">madhavik24@nitw.ac.in  </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                  <div className="row academic_group">
                    <div className="col-md-12">
                      <header>
                        <h4>Bilingual Education & English Literature</h4>
                      </header>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16616/16616.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. M. Raja Vishwanathan</h6><span>Coordinator</span>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969523 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:vishwanathan@nitw.ac.in ">vishwanathan@nitw.ac.in   </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16617/16617.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Madhavi Reddy Kesari </h6>

                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969522 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:madhavik24@nitw.ac.in  ">madhavik24@nitw.ac.in  </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17038/17038.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. R. Vennela</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9491065961 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:rvennela@nitw.ac.in">rvennela@nitw.ac.in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row academic_group">
                                      <div className="col-md-12">
                                          <header>
                                              <h4>Lexicograhpy & Language Planning and Policy </h4>
                                          </header>
                                      </div>
                                      <div className="col-md-6">
                                          <div className="card">
                                              <div className="d-flex align-items-center">
                                                  <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17038/17038.jpg" alt="" className="img-fluid"  width="150" height="180" />
                                                  </div>
                                                  <div className="ml-3 w-100">
                                                      <h6 className="mb-0 mt-0">Prof. R. Vennela</h6> <span>Coordinator</span>
                                                      <div className=" mt-2">
                                                          <p className="contact-info"> <i className="fa fa-phone"
                                                              aria-hidden="true"></i> 9491065961 <br /> <i
                                                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                      href="mailto:rvennela@nitw.ac.in">rvennela@nitw.ac.in</a>
                                                          </p>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-md-6">
                                          <div className="card">
                                              <div className="d-flex align-items-center">
                                                  <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16617/16617.jpg" alt="" className="img-fluid"  width="150" height="180" />
                                                  </div>
                                                
                                                  <div className="ml-3 w-100">
                                                      <h6 className="mb-0 mt-0">Prof. Madhavi Reddy Kesari </h6>
                                                     
                                                      <div className=" mt-2">
                                                          <p className="contact-info"> <i className="fa fa-phone"
                                                              aria-hidden="true"></i> 8332969522 <br /> <i
                                                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                      href="mailto:madhavik24@nitw.ac.in  ">madhavik24@nitw.ac.in  </a> </p>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-md-6">
                                          <div className="card">
                                              <div className="d-flex align-items-center">
                                                  <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16920/16920.jpg" alt="" className="img-fluid"  width="150" height="180" />
                                                  </div>
                                                  <div className="ml-3 w-100">
                                                      <h6 className="mb-0 mt-0">Prof. B. Spoorthi</h6>
                                                      <div className=" mt-2">
                                                          <p className="contact-info"> <i className="fa fa-phone"
                                                              aria-hidden="true"></i> 8008461503 <br /> <i
                                                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                      href="mailto:spoorthi.b@nitw.ac.in  ">spoorthi.b@nitw.ac.in  </a>
                                                          </p>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-md-6">
                                          <div className="card">
                                              <div className="d-flex align-items-center">
                                                  <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16616/16616.jpg" alt="" className="img-fluid"  width="150" height="180" />
                                                  </div>
                                                  <div className="ml-3 w-100">
                                                      <h6 className="mb-0 mt-0">Prof. M. Raja Vishwanathan</h6>
                                                      <div className=" mt-2">
                                                          <p className="contact-info"> <i className="fa fa-phone"
                                                              aria-hidden="true"></i> 8332969523 <br /> <i
                                                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                      href="mailto:vishwanathan@nitw.ac.in ">vishwanathan@nitw.ac.in   </a>
                                                          </p>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                  

                                      </div> */}




                </div>
                <div className={`tab-pane ${peopleTab === 'AdjunctFaculty' ? 'show active' : ''} fade`} id="nav-AdjunctFaculty" role="tabpanel"
                  aria-labelledby="nav-AdjunctFaculty-tab">

                  <div className="col-md-12 profile-info-individual">
                    <div className="row">

                      {/* <div className="col-md-9">
                        <div className="card">
                          <div className="d-flex">
                            <div className=""> <img src="https://nitw.ac.in/api/static/files/Dr._Ajeesh_A_K_2024-3-15-11-51-23.jpg" alt=""
                              className="" width="150" height="180" /> </div>
                            <div className="ml-3 w-100">
                              <br>
                              </br>
                              <br></br>
                              <h6 className="mb-0 mt-0"> Dr. Ajeesh A K </h6>

                              <div className=" mt-2">
                                <p className="contact-info">  <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:ajeeshak@nitw.ac.in">ajeeshak@nitw.ac.in</a> </p>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}





                      <div className="col-md-9">
                        <div className="card">
                          <div className="d-flex">
                            <div className=""> <img src="https://nitw.ac.in/api/static/files/Dr._Kaniphnath_Malhari_Kudale_2024-3-15-11-52-31.jpg" alt=""
                              className="" width="150" height="180" /> </div>
                            <div className="ml-3 w-100">
                              <br>
                              </br>
                              <br></br>
                              <h6 className="mb-0 mt-0"> Dr. Kaniphnath Malhari Kudale </h6>

                              <div className=" mt-2">
                                <p className="contact-info">  <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:kudalekm@nitw.ac.in">kudalekm@nitw.ac.in</a> </p>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>



                      <div className="col-md-9">
                        <div className="card">
                          <div className="d-flex">
                            <div className=""> <img src="https://nitw.ac.in/api/static/files/dash_2024-9-9-19-10-41.jpg" alt=""
                              className="" width="150" height="180" /> </div>
                            <div className="ml-3 w-100">
                              <br>
                              </br>
                              <br></br>
                              <h6 className="mb-0 mt-0"> Dr. Bhamathi Das </h6>

                              <div className=" mt-2">
                                <p className="contact-info">  <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:bhamatid@nitw.ac.in">bhamatid@nitw.ac.in</a> </p>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>





                      <div className="col-md-9">
                        <div className="card">
                          <div className="d-flex">
                            <div className=""> <img src="https://nitw.ac.in/api/static/files/Sreelakshmy_2024-9-9-19-10-14.jpg" alt=""
                              className="" width="150" height="180" /> </div>
                            <div className="ml-3 w-100">
                              <br>
                              </br>
                              <br></br>
                              <h6 className="mb-0 mt-0"> Dr. Sreelakshmy </h6>
                              {/* <span>Visiting Faculty for Sanskrit</span> */}

                              <div className=" mt-2">
                                <p className="contact-info">  <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:sreelakshmym@nitw.ac.in ">sreelakshmym@nitw.ac.in </a> </p>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>







                    </div>
                  </div>
                </div>




                <div className={`tab-pane ${activeTab === 'v-pills-Awards' ? 'show active' : ''} fade  `} id="v-pills-Awards" role="tabpanel"
                  aria-labelledby="v-pills-Awards-tab">
                </div>










                <div className={"tab-pane ${activeTab === 'v-pills-ResearchScholars' ? 'active' : ''} fade  "} id="v-pills-ResearchScholars" role="tabpanel"
                  aria-labelledby="v-pills-ResearchScholars-tab">
                  <h4 className="">Ph.D. Awardee</h4> <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf"
                    target="_blank"> <span className="highlight">L</span>ist of Ph.D. Awardees <i
                      className="fa fa-link " aria-hidden="true"></i> </a>
                  <h4 className="">Ph.D. On Roll</h4> <a
                    href="CSEDeptAssets/docs/Ph.D._Students_List_2022-7-29-11-55-47.pdf" target="_blank"> <span
                      className="highlight">P</span>h.D. Students List ( Full / Part Time) <i
                        className="fa fa-link " aria-hidden="true"></i> </a>
                </div>


                <div className={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                  aria-labelledby="v-pills-PhotoGallery-tab">

                  <div className="details">

                    <h4>Research Scholars Cultural Day - Research Initiative for Developing Holistic Innovations (Ridhi) 2022</h4>

                  </div>


                  <img src="https://nitw.ac.in/api/static/files/Research_Scholars_Cultural_Day_2023-9-29-13-32-41.jpg" alt="" className="img-fluid mb-5" />

                  <div className="details">

                    <h4>Humanities Academic & Literary Enclave (Hale) 2022</h4>

                  </div>


                  <img src="https://nitw.ac.in/api/static/files/Humanities_Academic_&_Literary_Enclave_2023-9-29-13-41-49.jpg" alt="" className="img-fluid mb-5" />

                  <div className="details">

                    <h4>Inaugural of One month Certificate course in English Communication skills for Postgraduate Students 2022</h4>

                  </div>


                  <img src="https://nitw.ac.in/api/static/files/English_Communication_skills_2023-9-29-13-55-14.jpg" alt="" className="img-fluid mb-5" />

                  <div className="details">

                    <h4>Awareness Talk on Prospects for Higher Education and Research & Scholars meet up with Pingle government college for Women. 2022</h4>

                  </div>


                  <img src="https://nitw.ac.in/api/static/files/Awareness_Talk_on_Prospects_for_Higher_Education_2023-9-29-16-47-57.jpg" alt="" className="img-fluid mb-5" />

                  <img src="https://nitw.ac.in/api/static/files/Awareness_Talk_on_Prospects_for_Higher_Education_v2_2023-9-29-17-1-36.jpg" alt="" className="img-fluid mb-5" />




                  <div className="details">

                    <h4>Women Inclusive Network (WIN) Pre-Conference Outreach Activitiy 2023</h4>

                  </div>


                  <img src="https://nitw.ac.in/api/static/files/WIN_2023_2023-9-29-17-18-33.jpg" alt="" className="img-fluid mb-5" />




                  <div className="details">

                    <h4>Women Inclusive Network (WIN) International Conference 2023</h4>

                  </div>


                  <img src="https://nitw.ac.in/api/static/files/Women_Inclusive_Network_(WIN)_International_Conference_2023_2023-9-29-18-0-22.jpg" alt="" className="img-fluid mb-5" />


                  {/* <img src="https://nitw.ac.in/api/static/files/Photo_Gallery2_2023-9-18-17-25-58.jpg" alt="" className="img-fluid mb-5"/> 
                              <img src="https://nitw.ac.in/api/static/files/Photo_Gallery3_2023-9-18-17-26-20.jpg" alt="" className="img-fluid mb-5"/>
                              <img src="https://nitw.ac.in/api/static/files/Photo_Gallery4_2023-9-18-17-26-50.jpg" alt="" className="img-fluid mb-5"/>
                              <img src="https://nitw.ac.in/api/static/files/Photo_Gallery5_2023-9-18-17-27-10.jpg" alt="" className="img-fluid mb-5"/>


                              <img src="https://nitw.ac.in/api/static/files/Photo_Gallery6_2023-9-18-17-27-48.jpg" alt="" className="img-fluid mb-5"/>

                              <img src="https://nitw.ac.in/api/static/files/Photo_Gallery7_2023-9-18-17-28-18.jpg" alt="" className="img-fluid mb-5"/>

                              <img src="https://nitw.ac.in/api/static/files/Photo_Gallery8_2023-9-18-17-28-43.jpg" alt="" className="img-fluid mb-5"/>  */}





                </div>






              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
      {/* <div className="footer">
              <div className="container">
                  <div className="row">
                      <div className="col-12">
                          <div className="copyright">
                               <p className="justified-text">Copyright © Centre for Digital Infrastructure and Services </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div> */}
    </>
  );
}

export default HSSDeptPage;
