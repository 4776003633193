import { useState } from "react";
import '../css/cseDeptStyle.css';

const SWDeptPage = () => {

    const [activeTab, setActiveTab] = useState("v-pills-Awards");
    const [peopleTab, setPeopleTab] = useState("Faculty");

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        console.log(tabId)
    }

    const handlePeopleTabClick = (tabId) => {
        setPeopleTab(tabId);
        console.log(tabId)
    }
    return (
        <><div class="dept-wrapper">
            <div class="container">
                <section class="py-4 header">
                    <header>
                        {/* */}
                        {/*<img src="CSEDeptAssets/images/CS-banner_NITW-new.png" class="img-fluid"/>*/}
                        <img
                            src="https://nitw.ac.in/api/static/files/SW_2024-5-10-12-24-6.jpg" class="img-fluid" />
                    </header>
                    <div class="row">
                        <div class="col-md-3">
                            {/* Tabs nav */}
                            <div class="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                                aria-orientation="vertical">
                                {/*<a class="nav-link    active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><span>Home</span></a>*/}
                                <a class={`nav-link ${activeTab === 'v-pills-Awards' ? 'active' : ''}`} id="v-pills-Awards-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Awards')}
                                    role="tab" aria-controls="v-pills-Awards"
                                    aria-selected={activeTab === 'v-pills-Awards' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-Awards' ? '#c20315' : '#00FFFF' }}> <span>About</span> </a>



                                <a class={`nav-link ${activeTab === 'v-pills-Faculty' ? 'active' : ''}`} id="v-pills-Faculty-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Faculty')}
                                    role="tab" aria-controls="v-pills-Faculty" aria-selected={activeTab === 'v-pills-Faculty' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-Faculty' ? '#c20315' : '#00FFFF' }}>
                                    <span>People </span>
                                </a>
                                {/* <a class={`nav-link ${activeTab === 'v-pills-E-Books' ? 'active' : ''}`} id="v-pills-labs-E-Books" data-toggle="pill" onClick={() => handleTabClick('v-pills-E-Books')} role="tab"
                                    aria-controls="v-pills-E-Books"
                                    aria-selected={activeTab === 'v-pills-E-Books' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-E-Books' ? '#c20315' : '#00FFFF' }}>

                                    <span>Technical Society</span>
                                </a> */}
                                <a class={`nav-link ${activeTab === 'v-pills-AcademicResearchGroup' ? 'active' : ''}`} id="v-pills-AcademicResearchGroup-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-AcademicResearchGroup')}
                                    role="tab"
                                    aria-controls="v-pills-AcademicResearchGroup" aria-selected={activeTab === 'v-pills-AcademicResearchGroup' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-AcademicResearchGroup' ? '#c20315' : '#00FFFF' }}> <span>SAC</span>
                                </a>

                                <a class={`nav-link ${activeTab === 'v-pills-visionandmission' ? 'active' : ''}`} id="v-pills-visionandmission-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-visionandmission')}
                                    role="tab" aria-controls="v-pills-visionandmission"
                                    aria-selected={activeTab === 'v-pills-visionandmission' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-visionandmission' ? '#c20315' : '#00FFFF' }}>
                                    <span>Students Clubs</span>
                                </a>


                                

                                <a class={`nav-link ${activeTab === 'v-pills-Students' ? 'active' : ''}`}
                                    id="v-pills-Students-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Students')} role="tab"
                                    aria-controls="v-pills-Students" aria-selected={activeTab === 'v-pills-Students' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-Students' ? '#c20315' : '#00FFFF' }}>
                                    <span>Student Council </span>
                                </a>

                                <a class={`nav-link ${activeTab === 'v-pills-NotableAlumni' ? 'active' : ''}`} id="v-pills-NotableAlumni-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-NotableAlumni')}
                                    role="tab" aria-controls="v-pills-NotableAlumni"
                                    aria-selected={activeTab === 'v-pills-NotableAlumni' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-NotableAlumni' ? '#c20315' : '#00FFFF' }}>
                                    <span>Activities</span>
                                </a>
                                {/* <a class={`nav-link ${activeTab === 'v-pills-academics' ? 'active' : ''}`}
                                    id="v-pills-academics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-academics')} role="tab"
                                    aria-controls="v-pills-academics" aria-selected={activeTab === 'vv-pills-academics' ? 'true' : 'false'}
                                    style={{ backgroundColor: activeTab === 'v-pills-academics' ? '#c20315' : '#00FFFF' }}>
                                    <span>Notices/Announcements</span>
                                </a> */}


                                {/*<a class="nav-link ${activeTab === ? 'active' : ''}   " id="v-pills-ResearchScholars-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-ResearchScholars')} href="#v-pills-ResearchScholars" role="tab" aria-controls="v-pills-ResearchScholars" aria-selected="false"><span>Research Scholars</span></a>*/}
                                {/* <a class={`nav-link ${activeTab === 'v-pills-labs' ? 'active' : ''}`} id="v-pills-labs-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-labs')} role="tab"
                                    aria-controls="v-pills-labs" aria-selected="false">
                                    <span>Remote Access Service</span>
                                </a>
                                <a class={`nav-link ${activeTab === 'v-pills-PhotoGallery' ? 'active' : ''}`} id="v-pills-PhotoGallery-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-PhotoGallery')}
                                    role="tab" aria-controls="v-pills-PhotoGallery"
                                    aria-selected="false"> <span>General Rules</span> </a> */}


                                {/* <a class={`nav-link ${activeTab === 'v-pills-AYL' ? 'active' : ''}`} id="v-pills-labs-AYL" data-toggle="pill" onClick={() => handleTabClick('v-pills-AYL')} role="tab"
                                    aria-controls="v-pills-AYL" aria-selected="false">
                                    <span>Ask your Librarian</span>
                                </a> */}

                                {/* <a class={`nav-link ${activeTab === 'v-pills-FIS' ? 'active' : ''}`} id="v-pills-labs-FIS" data-toggle="pill" onClick={() => handleTabClick('v-pills-FIS')} role="tab"
                                    aria-controls="v-pills-FIS" aria-selected="false">
                                    <span>Faculty Information System</span>
                                </a> */}







                            </div>
                            <div class="head_of_dept"> <a href="https://erp.nitw.ac.in/ext/profile/ma-dsc"
                                target="_blank"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/ma-dsc" alt="" class="img-fluid" width="190" height="220" />
                                <h6>Prof. D Srinivasa Charya</h6>
                            </a>
                                <p class="justified-text">Dean (Student Welfare) <br /> <a href="mailto:radharukmini@nitw.ac.in"
                                    style={{ color: "#808080" }}>dean_sa@nitw.ac.in</a> <br /> 9490165362 </p>
                            </div>
                        </div>



                        <div class="col-md-9">
                            {/* Tabs content */}
                            <div class="tab-content" id="v-pills-tabContent">
                                {/*<div class="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade    show active p-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"></div>*/}

                                <div class={`tab-pane fade ${activeTab === 'v-pills-visionandmission' ? 'show active' : 'alumniTabHide'}  p-2`} id="v-pills-visionandmission"
                                    role="tabpanel" aria-labelledby="v-pills-visionandmission-tab">
                                    <div class="intro-section">
                                        {/* <h4 class=" mb-4">Welcome to Computer Science and Engineering Department</h4>*/}




                                        <h3 align='center'>Yoga</h3><br></br>

                                        <div className="container">
                                            <div className="image" style={{ float: 'left', marginRight: '20px' }}>
                                                <img src="https://nitw.ac.in/api/static/files/Yoga_Book_-_2023_2023-6-28-17-39-37.jpg" alt="Sample Image" />
                                            </div>

                                            <div className="description" style={{ textAlign: 'justify' }}>
                                                <p>

                                                    <a href="https://nitw.ac.in/api/static/files/yoga_book_2023-6-28-17-56-5.pdf">Yoga Book<i class="fa fa-angle-right"></i></a>

                                                </p>
                                            </div>
                                            <div style={{ clear: 'both' }}></div>
                                        </div>






                                        <h3 align='center'>DANCE AND DRAMATICS</h3><br></br>


                                        <div className="container">
                                            <div className="image" style={{ float: 'left', marginRight: '20px' }}>
                                                <img src="https://www.nitw.ac.in/media/dnd.jpg" alt="Sample Image" />
                                            </div>
                                            <div className="description" style={{ textAlign: 'justify' }}>
                                                <p>
                                                    The DND club is an organization which consists of a dedicated group of members always enthusiastic about putting up a well-choreographed show for the ever-interest crowd of our college and would make attempt everything they can to make that happen. It has a diverse mix of students who are passionate about dance from b'boying to contemporary to mass, we excel in all dance forms. When it's time to turn up the heat and follow the rhythm, the only person you need to be is yourself.
                                                </p>
                                            </div>
                                            <div style={{ clear: 'both' }}></div>
                                        </div>



                                        <h3 align='center'> LITERARY AND DEBATING CLUB</h3><br></br>

                                        <div className="container">
                                            <div className="image" style={{ float: 'left', marginRight: '20px' }}>
                                                <img src="https://oldweb.nitw.ac.in/media/lnd.jpg" alt="Sample Image" />
                                            </div>
                                            <div className="description" style={{ textAlign: 'justify' }}>
                                                <p>
                                                    The Literary and Debating Club is the largest student club in college with a 65-member strong body. It comprises three student groups that organize events in three languages- English, Hindi and Telugu- but work in tandem to benefit students in the realms of writing and speaking by conducting events that are both instructional and enjoyable.</p>
                                            </div>
                                            <div style={{ clear: 'both' }}></div>
                                        </div>



                                        <h3 align='center'>MUSIC CLUB</h3><br></br>

                                        <div className="container">
                                            <div className="image" style={{ float: 'left', marginRight: '20px' }}>
                                                <img src="https://nitw.ac.in/api/static/files/music_club_2024-5-10-15-49-3.jpg" alt="Sample Image" />
                                            </div>
                                            <div className="description" style={{ textAlign: 'justify' }}>
                                                <p>
                                                    The Music club is a vibrant and elite society of musically gifted students who come together and create fascinating melodies. The purpose of the club is to showcase the musical talent of maximum number of students and it has upheld this tradition over the years.

                                                    The club encourages young musicians to interact with each other and gives them an appropriate jam pad. It enables us students to utilize our free time productively and do something what we as music enthusiasts are passionate about.

                                                    The club recognizes exceptional talent and promotes it in the right direction. The students in the executive body arrange practice sessions for intra as well as inter collegiate events. Workshops and semester long classes are held for those who are interested in learning music. Thus, if a student wants to excel in the field of music, the club does its bit to help achieve that goal.

                                                </p>
                                            </div>
                                            <div style={{ clear: 'both' }}></div>
                                        </div>


                                        <h3 align='center'>PHOTOGRAPHY CLUB</h3><br></br>

                                        <div className="container">
                                            <div className="image" style={{ float: 'left', marginRight: '20px' }}>
                                                <img src="https://oldweb.nitw.ac.in/media/SAVE_20181101_105403_cdCqhLY.png" alt="Sample Image" />
                                            </div>
                                            <div className="description" style={{ textAlign: 'justify' }}>
                                                <p>
                                                    "Photography is the story people fail to put into words." <br></br>

                                                    Photography can defeat time and is the universal language that speaks to the heart. At Photography Club, we believe in the power of photos to spark positive change in a world where the pursuit of self-interest is prioritized by many, its role and power to disseminate truth when all other avenues fail is unparalleled. The Photography club sorts all the happenings on campus into images and publishes them for the NITW community. PGC is the official body that covers all the aspects of NIT Warangal from the beautiful campus to the fests and conferences in the form of stunning photos and videos. Our dedicated and passionate student body always strives to provide different perspectives to the events held on campus and call people's attention to the beauty that surrounds them. In the end, PGC is all about capturing memories and conveying stories.
                                                    <br></br>
                                                    “In photography there is a reality so subtle that it becomes more real than reality.”<br></br>

                                                    <b>Contact: </b><br></br>

                                                    <b>Facebook- </b> facebook.com/photographyclubnitw<br></br>

                                                    <b>Email- </b> photographyclub@student.nitw.ac.in<br></br>

                                                    <b>Instagram- </b> @photography_club_nitw<br></br>


                                                </p>
                                            </div>
                                            <div style={{ clear: 'both' }}></div>
                                        </div>




                                        <h3 align='center'>QUIZ CLUB</h3><br></br>

                                        <div className="container">
                                            <div className="image" style={{ float: 'left', marginRight: '20px' }}>
                                                <img src="https://nitw.ac.in/api/static/files/QUIZ_CLUB_2024-5-11-15-32-13.jpg" alt="Sample Image" />
                                            </div>
                                            <div className="description" style={{ textAlign: 'justify' }}>
                                                <p>
                                                    The Quiz Club takes pride in being one of the most proactive and popular clubs among the student clubs of NIT Warangal.
                                                    <br></br>
                                                    Comprising twenty members, the club is dedicated to preparing and hosting quality quizzes for the student population on the campus.
                                                    <br></br>
                                                    Equipped with events ranging from quizzes such as The Joga Bonitos ( an year-long quiz tournament ) to the Annual General Quiz, the club offers a competing platform for the knowledge-hungry students. The gamut of activities include the Quiz Week ( an intra-mural event ) to quizzes covering specific themes, such as business, sports, literature, fine arts, entertainment, history and politics. The Quiz Club also hosts the Business Quiz held as part of the technical festival Technozion every year and quizzes held as part of the cultural festival Spring Spree. With a motivated team of members and an encouraging response from the students, the Quiz Club promises to cultivate a passion for quizzing among the participants.
                                                </p>
                                            </div>
                                            <div style={{ clear: 'both' }}></div>
                                        </div>



                                        <h3 align='center'>Value Education Club</h3><br></br>

                                        <div className="container">
                                            <div className="image" style={{ float: 'left', marginRight: '20px' }}>
                                                <img src="https://nitw.ac.in/api/static/files/Value_Education_Club_2024-5-11-15-28-12.png" alt="Sample Image" />
                                            </div>
                                            <div className="description" style={{ textAlign: 'justify' }}>
                                                <p>

                                                    <a href="https://nitw.ac.in/api/static/files/Value_Education_Club_2024-3-20-17-25-0.pdf">Value Education Club<i class="fa fa-angle-right"></i></a>

                                                </p>
                                            </div>
                                            <div style={{ clear: 'both' }}></div>
                                        </div>




                                    </div>

                                </div>










                                <div class={`tab-pane ${activeTab === 'v-pills-academics' ? 'show active' : 'alumniTabHide'} fade    p-2" id="v-pills-academics`} role="tabpanel"
                                    aria-labelledby="v-pills-academics-tab">
                                    {/*<h4 class="mb-4">Academic Programmes</h4>*/}
                                    {/*<div class="row">
  <div class="col-md-12">
    <h3 class="mb-3">PhD</h3>
  </div>
</div>*/}
                                    <div class="row">


                                        <div class="col-md-10 col-sm-12">
                                            <div class="card-4">
                                                <div class="card-detail">
                                                    <div class="head-bx">
                                                        <h3 class="" align='center'> Notices/Announcements </h3>
                                                        <p style={{ textAlign: 'justify' }}>



                                                            {/* <a href="https://nitw.ac.in/api/static/files/Advisory_on_recent_covid_-19_variant_2023-12-20-14-46-52.pdf">
                                                                - Institute Health Centre: Advisory on recent covid -19 variant  </a> <br></br> */}



                                                        </p>


                                                    </div>


                                                </div>
                                            </div>
                                        </div>









                                    </div>

                                </div>




                                <div class={`tab-pane ${activeTab === 'v-pills-labs' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-labs" role="tabpanel"
                                    aria-labelledby="v-pills-labs-tab">
                                    <div class="row laboratory-facilities">
                                        <div class="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                <h3 class="" align='center'>Remote Access Service</h3>
                                            </header>

                                            <p class="justified-text">Central Library, NITW introduced a new service called "Remote Access Services - MyLOFT (My Library On Fingertips)" for the benefit of Research Scholars and Faculty. MyLOFT is a mobile App. Through MyLOFT research scholars and faculty can access e-resources subscribed by the NITW anywhere and anytime. Hence, all the P.G. students, Ph.D. students and faculty are requested to utilize the facility to enhance the quality Engineering Education and Research.
                                                <br></br>
                                                <br></br>
                                                <b> Remote Access Services Request Form: </b>
                                                <a href="https://forms.gle/3UxbXJ3q9MEqY3PCA">
                                                    https://forms.gle/3UxbXJ3q9MEqY3PCA </a></p>


                                        </div>

                                    </div>
                                </div>






                                <div class={`tab-pane ${activeTab === 'v-pills-Phd' ? 'show active' : 'alumniTabHide'} fade   p-2`} id="v-pills-Phd" role="tabpanel"
                                    aria-labelledby="v-pills-Phd-tab">
                                    <h4 class=" mb-4">Phd</h4> <a href="" target="_blank"> <span class="highlight">P</span>h.D.
                                        Students List ( Full / Part Time) <i class="fa fa-link " aria-hidden="true"></i> </a>
                                </div>
                                <div class={`tab-pane ${activeTab === 'v-pills-Faculty' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Faculty" role="tabpanel"
                                    aria-labelledby="v-pills-Faculty-tab">
                                    <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">


                                        <a class={`nav-item subtabs tab-bold nav-link ${peopleTab === 'Faculty' ? 'active' : ''} `} id="nav-faculty-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-faculty"
                                            style={{ backgroundColor: peopleTab === 'Faculty' ? '#c20315' : '#00FFFF' }} onClick={() => { handlePeopleTabClick("Faculty") }}>Dean</a>


                                        {/* <a class={`nav-item subtabs tab-bold nav-link ${peopleTab === 'AdjunctFaculty' ? 'active' : ''} `}
                                            id="nav-AdjunctFaculty-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-AdjunctFaculty" aria-selected="false" onClick={() => { handlePeopleTabClick("AdjunctFaculty") }}> Adjunct Faculty</a> */}


                                        <a class={`nav-item subtabs tab-bold nav-link ${peopleTab === 'NonTeachingStaff' ? 'active' : ''} `}
                                            id="nav-NonTeachingStaff-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-NonTeachingStaff"
                                            style={{ backgroundColor: peopleTab === 'NonTeachingStaff' ? '#c20315' : '#00FFFF' }} onClick={() => { handlePeopleTabClick("NonTeachingStaff") }}>Student Activity Centre</a>


                                        <a class={`nav-item subtabs tab-bold nav-link ${peopleTab === 'ResearchScholars' ? 'active' : ''} `} id="nav-ResearchScholars-tab"
                                            data-toggle="tab" role="tab"
                                            aria-controls="nav-ResearchScholars"
                                            style={{ backgroundColor: peopleTab === 'ResearchScholars' ? '#c20315' : '#00FFFF' }} onClick={() => { handlePeopleTabClick("ResearchScholars") }}>
                                            Office Staff</a>


                                    </div>



                                    <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"
                                        style={{ marginTop: "5%", marginLeft: "1%" }}>
                                        <div class={`tab-pane ${peopleTab === 'Faculty' ? 'show active' : ''} fade`} id="nav-faculty" role="tabpanel"
                                            aria-labelledby="nav-faculty-tab">
                                            {/*<h4 class=" mb-4">Faculty</h4>*/}
                                            {/* <a href="" target="_blank"><span class="highlight">F</span>aculty Profiles <i class="fa fa-external-link" aria-hidden="true"></i></a>*/}
                                            <section id="profile-info">



                                                <div class="col-md-12 profile-info-individual">
                                                    <div class="row">
                                                        <div class="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/ma-dsc"
                                                            target="_blank"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/ma-dsc" alt=""
                                                                class="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div class="col-md-9">
                                                            <div class="card ">
                                                                <div class="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-dsc"
                                                                        target="_blank">Prof. D Srinivasa Charya</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p class="contact-info">
                                                                        <i class="fa fa-phone"
                                                                            aria-hidden="true"></i> -<br />
                                                                        <i class="fa fa-mobile fa-lg"
                                                                            aria-hidden="true"></i> 9490165362 <br /> <i
                                                                                class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:dean_sa@nitw.ac.in">dean_sa@nitw.ac.in</a> </p>
                                                                    {/* <p class="justified-text">Modeling, Simulation and Optimization; Process Simulation using Aspen; Reaction Engineering; Thermodynamics; Energy management and CO2 Capture.</p> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                            </section>



                                        </div>




















                                        <div class={`tab-pane ${peopleTab === 'NonTeachingStaff' ? 'show active' : ''} fade`} id="nav-NonTeachingStaff" role="tabpanel"
                                            aria-labelledby="nav-NonTeachingStaff-tab">
                                            <div class="row academic_group">
                                                <div class="col-md-6">
                                                    <div class="card">
                                                        <div class="d-flex align-items-center">
                                                            <div class="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16366/16366.jpg"  width={"150"} height={"130"} alt=""
                                                                class="img-fluid" /> </div>
                                                            <div class="ml-3 w-100">
                                                                <a href="https://erp.nitw.ac.in/ext/profile/ph-syamprasad"
                                                                    target="_blank"> <h6 class="mb-0 mt-0">Prof. P Syam Prasad</h6></a>  <span>President</span>
                                                                <div class=" mt-2">
                                                                    <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                                                        aria-hidden="true"></i> 8332969472 <br /> <i
                                                                            class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:phari@nitw.ac.in">syamprasad@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col-md-6">
                                                    <div class="card">
                                                        <div class="d-flex align-items-center">
                                                            <div class="image"> <img src="https://nitw.ac.in/api/static/files/Prof._P._Hari_Prasad_Reddy_2024-5-10-13-0-56.jpg" alt=""
                                                                class="img-fluid" /> </div>
                                                            <div class="ml-3 w-100">
                                                                <a href=" https://erp.nitw.ac.in/ext/profile/ce-hari"
                                                                    target="_blank"> <h6 class="mb-0 mt-0">Prof. P. Hari Prasad Reddy</h6></a> <span>Vice President (Technical)</span>
                                                                <div class=" mt-2">
                                                                    <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                                                        aria-hidden="true"></i> 8332969254 <br /> <i
                                                                            class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:hari@nitw.ac.in">hari@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div class="col-md-6">
                                                    <div class="card">
                                                        <div class="d-flex align-items-center">
                                                            <div class="image"> <img src="https://nitw.ac.in/api/static/files/Prof._B_Satish_Ben_2024-5-10-13-6-40.jpg" alt=""
                                                                class="img-fluid" /> </div>
                                                            <div class="ml-3 w-100">
                                                                <a href="https://erp.nitw.ac.in/ext/profile/me-satishben"
                                                                    target="_blank">  <h6 class="mb-0 mt-0">Prof. Sathish Ben</h6></a> <span>Vice President (Cultural)</span>
                                                                <div class=" mt-2">
                                                                    <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                                                        aria-hidden="true"></i> 9848065254<br /> <i
                                                                            class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:satishben@nitw.ac.in">satishben@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div class="col-md-6">
                                                    <div class="card">
                                                        <div class="d-flex align-items-center">
                                                            <div class="image"> <img src="https://nitw.ac.in/api/static/files/Prof._P._Abdul_Azeem_2024-5-10-13-8-30.jpg" alt=""
                                                                class="img-fluid" /> </div>
                                                            <div class="ml-3 w-100">
                                                                <a href="https://erp.nitw.ac.in/ext/profile/ph-drazeem2002"
                                                                    target="_blank">    <h6 class="mb-0 mt-0">Prof. P. Abdul Azeem</h6></a> <span>Vice President (Athletics)</span>
                                                                <div class=" mt-2">
                                                                    <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                                                        aria-hidden="true"></i> 8332969473<br /> <i
                                                                            class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:drazeem2002@nitw.ac.in">drazeem2002@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div class="col-md-6">
                                                    <div class="card">
                                                        <div class="d-flex align-items-center">
                                                            <div class="image"> <img src="https://nitw.ac.in/api/static/files/Prof._P._Muralidhar_2024-5-10-13-9-46.jpg" alt=""
                                                                class="img-fluid" /> </div>
                                                            <div class="ml-3 w-100">
                                                                <a href="https://erp.nitw.ac.in/ext/profile/ec-pmurali"
                                                                    target="_blank">  <h6 class="mb-0 mt-0">Prof. P. Muralidhar</h6> </a><span>Vice President (Film and Music)</span>
                                                                <div class=" mt-2">
                                                                    <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                                                        aria-hidden="true"></i> 8332969473<br /> <i
                                                                            class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:pmurali@nitw.ac.in">pmurali@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                            </div>
                                        </div>








                                        <div class={`tab-pane ${peopleTab === 'ResearchScholars' ? 'show active' : ''} fade`} id="nav-ResearchScholars" role="tabpanel"
                                            aria-labelledby="nav-ResearchScholars-tab">

                                            <div class="row phd-std-row">




                                                <div class="col-md-6">
                                                    <div class="card">
                                                        <div class="d-flex align-items-center">
                                                            <div class="image"> <img src="https://nitw.ac.in/api/static/files/R._Srinivas_2024-5-10-12-54-11.jpg" alt=""
                                                                class="img-fluid" /> </div>
                                                            <div class="ml-3 w-100">
                                                                <h6 class="mb-0 mt-0">Shri. R. Srinivas</h6> <span>Asst. S.G-II</span>
                                                                <div class=" mt-2">
                                                                    <p class="contact-info">
                                                                        <i class="fa fa-phone"
                                                                            aria-hidden="true"></i> 0870-2462019<br />

                                                                        <i class="fa fa-mobile fa-lg"
                                                                            aria-hidden="true"></i> 8332969575 <br /> <i
                                                                                class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:rsvas@nitw.ac.in">rsvas@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>







                                {/*<div class="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade   " id="v-pills-NonTeachingStaff" role="tabpanel" aria-labelledby="v-pills-NonTeachingStaff-tab"><h4 class=" mb-4">Non-Teaching Staff</h4><table class="table table-sm "><thead><tr><th>Sl. No</th><th>Name of the Staff Member</th><th>Designation</th><th>Division</th></tr></thead><tbody><tr><td>1</td><td>Smt. K.S.S.S. Padmasri</td><td>Senior Technical Assistant</td></tr><tr><td>2</td><td>Smt. Shalini Avala</td><td>Technical Assistant</td></tr><tr><td>3</td><td>Sri . R Shivamallikarjun</td><td>Technical Assistant</td><td>CSE</td></tr><tr><td>4</td><td> Sri R. Jeevan kumar</td><td>Senior Technician</td></tr><tr><td>5</td><td>Sri. D. Ramesh</td><td>Senior Technician</td></tr><tr><td>6</td><td>Sri. B. Ramesh</td><td>Junior Assistant</td></tr><tr><td>7</td><td>K. Venkatamma</td><td> Technician (upgd)</td></tr><tr><td>8</td><td> K. Jayaraj</td><td>Caretaker SG-II</td></tr><tr><td>9</td><td>B. Sunil kumar</td><td>Electrician (Daily wage)</td></tr><tr><td>10</td><td>T. Varsha Sree</td><td>Skilled</td></tr><tr><td>11</td><td> M. Padma Rao</td><td>Skilled</td></tr><tr><td>12</td><td>P. Suma </td><td>Skilled</td></tr><tr><td>13</td><td>M. Mahendar</td><td>Un-Skilled</td></tr><tr><td>14</td><td>K. Srinivasulu</td><td>Un-Skilled</td><td>CSE</td></tr><tr><td>15</td><td>T. Sampath </td><td> Un-Skilled</td></tr></tbody></table></div>*/}
                                <div class={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                                    aria-labelledby="v-pills-Students-tab">
                                    <div class="row std-society">
                                        <div class={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                                            aria-labelledby="v-pills-Students-tab">
                                            <div class="row std-society">
                                                <div class="col-md-3 col-6">

                                                </div>
                                                <div class="col-md-3 col-6">

                                                </div>
                                                {/* <h3 align='center'>Student Corner</h3> */}

                                                <p class="justified-text">

                                                    The Student Council will be managed by the students under the guidance of Executive Council members. All registered students of the Institute - undergraduate, post-graduate and research scholars are the members of the student council and they constitute the and "GENERAL BODY".
                                                    <br></br>
                                                    <br></br>

                                                    <b>OBJECTIVES OF THE STUDENT COUNCIL</b><br></br>

                                                    - To foster and coordinate all students’ extra-curricular activities through sports, cultural, artistic and technical aspects in the Institute.
                                                    <br></br>
                                                    - To identify the important student issues and promote discussion on them.
                                                    <br></br>
                                                    - To develop well informed participative student community life and create societal awareness.
                                                    <br></br>

                                                    <br></br>
                                                    <b>COMPOSITION</b>
                                                    <br></br>
                                                    The Dean, Student Welfare will be the patron of the Student Council. The Student Council will have an Executive Committee and Class Representatives.  The Executive Committee shall consist of the Secretaries, Joint Secretaries, and Student Representatives shown in Table 1.
                                                    <br></br>
                                                    <br></br>
                                                    <b>Table – 1: Eligibility Criteria and Nomination</b>

                                                    <br></br>


                                                    <table className="table table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <th>POST</th>
                                                                <th>ELIGIBILITY CRITERIA</th>
                                                                <th>TO BE CHOSEN BY</th>

                                                            </tr>


                                                            {/* Add your table rows here */}
                                                            <tr>
                                                                <td><b>Secretary</b>One for each of the Technical, Cultural, Athletics, Film and Music Societies</td>
                                                                <td>B. Tech Final year students</td>
                                                                <td>All UG students</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>Joint Secretary</b>One for each of the Technical, Cultural, Athletics, Film and Music Societies</td>
                                                                <td>B.Tech III year students</td>
                                                                <td>All UG students</td>
                                                            </tr>

                                                            <tr>
                                                                <td><b>PG Representative (Engineering)</b>One for each of the Technical, Cultural, Athletics, Film and Music Societies</td>
                                                                <td>M.Tech Final year Students</td>
                                                                <td>All MTech Students</td>
                                                            </tr>

                                                            <tr>
                                                                <td><b>PG Representative (MSc/MCA/MBA)</b>One for each of the Technical, Cultural, Athletics, Film and Music Societies</td>
                                                                <td>M.Sc., MBA and MCA Final year students</td>
                                                                <td>All PG (M.Sc /MBA /MCA) students</td>
                                                            </tr>

                                                            <tr>
                                                                <td><b>UG Representative (1st Year)</b>Two for each of the Technical, Cultural, Athletics, Film and Music Societies</td>
                                                                <td>B. Tech 1<sup>st</sup> Year</td>
                                                                <td>B. Tech 1<sup>st</sup> Year</td>
                                                            </tr>

                                                            <tr>
                                                                <td><b>UG Representative (2nd Year)</b>Two for each of the Technical, Cultural, Athletics, Film and Music Societies</td>
                                                                <td>B. Tech 2<sup>nd</sup> Year</td>
                                                                <td>B. Tech 2<sup>nd</sup> Year</td>
                                                            </tr>


                                                            <tr>
                                                                <td><b>UG Representative (3rd Year)</b>Two for each of the Technical, Cultural, Athletics, Film and Music Societies</td>
                                                                <td>B. Tech 3<sup>rd</sup> Year</td>
                                                                <td>B. Tech 3<sup>rd</sup> Year</td>
                                                            </tr>

                                                            <tr>
                                                                <td><b>UG Representative (4th Year)</b>Two for each of the Technical, Cultural, Athletics, Film and Music Societies</td>
                                                                <td>B. Tech 4<sup>th</sup> Year</td>
                                                                <td>B. Tech 4<sup>th</sup> Year</td>
                                                            </tr>

                                                            <tr>
                                                                <td><b>Student Representative</b>Two for each of the Technical, Cultural, Athletics, Film and Music Societies</td>
                                                                <td>The student population such as female students or students from specific geographical locations, departments and hostels who may not be adequately represented through the selection process.</td>
                                                                <td>Nominated by Dean, SW</td>
                                                            </tr>

                                                            <tr>
                                                                <td><b>Class Representatives</b>One from each class whether it is small or large</td>
                                                                <td>A student in particular class or section</td>
                                                                <td>All the students in that class /section</td>
                                                            </tr>



                                                            {/* ...add more rows as needed... */}
                                                        </tbody>
                                                    </table>


                                                    <br></br>

                                                    <b>Eligibility for Candidature</b>
                                                    <br></br>
                                                    - A CGPA of 7.50 till the previous semester without any backlogs in the previous semesters and R grade in the previous semesters.
                                                    <br></br>
                                                    - There shouldn't be i) a year-back, ii) any cases before the Institute Students Disciplinary Committee and iii) malpractice cases in the exam.
                                                    <br></br>
                                                    If there is only one nomination, under any category, he/she shall be declared selected.
                                                    <br></br>
                                                    - If there are no nominations, the Dean (SW) in consultation with the President (SAC) and concerned Vice President shall nominate students to fill up the vacant posts.
                                                    <br></br>
                                                    - The complaints from students and teachers as well as suo motu findings of misconduct by a candidate shall be considered before a decision on potential disqualification is made.

                                                    <br></br>

                                                    <br></br>
                                                    <b>Code of conduct of candidates</b><br></br>

                                                    - No candidate shall create mutual hatred or cause tension among different castes or communities or states or religious or linguistic or any alliance thereof or among any group(s) of students.
                                                    <br></br>
                                                    - No campaigning should be done along the lines of advocating a cause that is harmful to the well-being of students or the excellent academic standards, or the growth of the Institute.
                                                    <br></br>
                                                    - No candidate shall be permitted to display posters and banners on walls, paint on roads, fixing nails on walls, pasting tapes on painted surfaces or disfiguring Institute facilities for the purposes of canvassing.
                                                    <br></br>
                                                    - No candidate shall be permitted to conduct/organize a procession or public meeting.
                                                    <br></br>
                                                    - No candidate shall, nor shall his/her supporters, deface or cause any destruction to any property of the Institute.
                                                    <br></br>
                                                    - No candidate shall be permitted to indulge in or abet corrupt practices or offences.
                                                    <br></br>
                                                    - Candidates shall refrain from criticism of all aspects of private life not connected with the public activities of other candidates.
                                                    <br></br>
                                                    - All the candidates must obey the code of conduct of candidates mentioned under 6.7 of recommendations of Mr. J.M.Lyngdoh committee report “Recommendations and suggestions relating to Students’ Union Elections”.
                                                    <br></br>


                                                    <br></br>

                                                    <b>Procedure for Selection</b>
                                                    <br></br>
                                                    - Call for nominations for the posts through the Google form
                                                    <br></br>
                                                    - Verification of the eligibility conditions for the candidates and display the list of eligible candidates.
                                                    <br></br>
                                                    - Preparation of a single Google form by the Dean (SW) office including the name of all eligible candidates of all categories (i.e. General and Joint Secretaries and student representatives for Technical, Cultural, Sports, and Film and Music societies etc) and circulating among all the students through email.
                                                    <br></br>
                                                    - Filling out the Google form for choosing a secretary / joint secretary/student representative may be conducted either from designated halls or from any computer over the Institute network.

                                                    <br></br>

                                                    <br></br>
                                                    <b>Responsibilities of the Council</b><br></br>

                                                    - To support the administration in the smooth conduct of student activities on campus.
                                                    <br></br>
                                                    - To organize activities to improve the knowledge and skills of the campus students.
                                                    <br></br>
                                                    - To maintain peace and harmony among the campus community in general and the student community in particular.
                                                    <br></br>
                                                    - To prevent ragging on the campus through counselling the senior students, and helping the administration whenever necessary.
                                                    <br></br>
                                                    - To bring to the notice of the administration the specific requirements for improving student amenities on campus.
                                                    <br></br>
                                                    - To guide junior and needy students to improve their technical, organizational and managerial skills by organizing seminars / symposia / workshops etc.
                                                    <br></br>
                                                    - To encourage innovative and creative skills of the students
                                                    <br></br>
                                                    - To organize the SWACCHATA Campaign on the campus to improve cleanliness and greenery.
                                                    <br></br>
                                                    - To participate in and conduct the Institute functions every year
                                                    <br></br>
                                                    - To arrange expert lectures from eminent persons for career and personality development.
                                                    <br></br>


                                                    <br></br>
                                                    <b>Responsibilities of Secretary and Joint Secretary</b><br></br>

                                                    - To act as representatives of all the students of NIT in all external forums.
                                                    <br></br>
                                                    - To serve as a bridge between the Institute administration and the larger student community.
                                                    <br></br>
                                                    - To guide and advise all the societies for conducting programmes.
                                                    <br></br>
                                                    - To encourage innovative and creative ideas.
                                                    <br></br>
                                                    - To initiate proposals for improvement in consultation with Vice Presidents of all the societies.
                                                    <br></br>
                                                    - To take the lead role in planning, coordinating and executing major programmes/events of the concerned society.
                                                    <br></br>
                                                    - To protect the interest of the concerned society (i.e. Technical, Cultural, Sports, Film and Music).
                                                    <br></br>
                                                    - To assist in achieving the overall goals and objectives of the society

                                                    <br></br>

                                                    <br></br>
                                                    <b>Responsibilities of Student Representatives</b>
                                                    <br></br>
                                                    - To serve as bridge between the SAC administration and the larger student community.
                                                    <br></br>
                                                    - To carry out events under the supervision of Vice Presidents and higher authorities.
                                                    <br></br>
                                                    - To ensure team spirit and a multicultural environment in all activities.
                                                    <br></br>
                                                    - To assist Vice Presidents in spending funds allocated to the society

                                                    <br></br>
                                                    <h3 align='center'>Class Representatives (CR)</h3>


                                                    - Class representatives for each class shall be chosen by all the students of that class (whether it is small or big).
                                                    <br></br>
                                                    - If there is more than one section in a given year of a particular branch, each section shall elect its class representative
                                                    <br></br>
                                                    - The faculty advisor in consultation with the HOD will conduct the selection process.

                                                    <br></br>
                                                    <br></br>
                                                    <b>Eligibility for the Nomination of Class Representatives</b>    <br></br>

                                                    - A CGPA of 8.00 till the previous semester without any backlogs in the previous semesters and R grade in the previous semesters.
                                                    <br></br>
                                                    - There shouldn't be i) a year-back, ii) any case before the Institute Students Disciplinary Committee, iii) malpractice cases in the exam.
                                                    <br></br>
                                                    - If there is only one nomination, he/she shall be declared selected.
                                                    <br></br>
                                                    - If there are no nominations, the concerned faculty advisor in consultation with the HOD will nominate a student as CR.
                                                    <br></br>


                                                    <br></br>
                                                    <b>Procedure for Selection</b><br></br>

                                                    - Call for nominations for the class representatives by the faculty advisor in consultation with the Head of the Department through the Google form
                                                    <br></br>
                                                    - Verify the eligibility conditions for the candidates and display the list of eligible candidates.
                                                    <br></br>
                                                    - Prepare a Google form including the name of all eligible candidates of a class and circulate it among all the students of that class through institute email only.
                                                    <br></br>
                                                    - Filling out the Google form for choosing a class representative may be conducted either from designated halls in the department or from any computer over the Institute network.

                                                    <br></br>


                                                    <br></br>

                                                    <b>Responsibilities of Class Representatives</b><br></br>

                                                    - To act as an interface between students, faculty, and the Head of the Department for the smooth conduct of classes and labs.
                                                    <br></br>
                                                    - To maintain discipline within the classrooms and labs.
                                                    <br></br>
                                                    - To ensure the maintenance of classrooms, corridors, and academic area
                                                    <br></br>
                                                    - To inform the faculty about an emergency situation such as a student's health or a calamity in the student’s family etc.
                                                    <br></br>
                                                    - To resolve the working condition of LCD projectors, the microphone in the classroom
                                                    <br></br>
                                                    - To ensure the availability of chalks or writing pens for the whiteboards
                                                    <br></br>
                                                    - To represent students' problems at Class Review committee meetings.
                                                    <br></br>


                                                </p>
                                                <br></br>

                                                {/* <img
                                                    src="https://nitw.ac.in/api/static/files/Ambulance_2023-5-23-15-21-35.jpg"
                                                    alt="Image 1"
                                                    style={{ width: '100%', height: '450px' }} // Customize width and height
                                                /> */}



                                            </div>
                                        </div>
                                    </div>
                                </div>










                                <div class={`tab-panel ${activeTab === 'v-pills-FIS' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-FIS" role="tabpanel"
                                    aria-labelledby="v-pills-FIS-tab">
                                    <div class="row FIS">

                                        {/* <h4 align='center'>Faculty Information System</h4><br></br><br></br> */}
                                        <p class="justified-text">
                                            The Central Library has implemented a new “Faculty Information System” under the Indian Research Information Network System (IRINS) to showcase the academic and research activities of the institute faculty members and provide an opportunity to create a scholarly research network.</p>
                                        <br></br>
                                        <p class="justified-text">
                                            The NITW- Indian Research Information Network System (IRINS) will support the integration of the existing research management system such as HR system, course management, grant management system, institutional repository, Open and commercial citation databases, scholarly publishers, etc. This will be integrated with the academic identities such as ORCID ID, Scopus ID, Research ID, Microsoft Academic ID, and Google Scholar ID for ingesting the scholarly publication from various sources. This Faculty Information System can become the source for the NIRF, NAC and other international raking systems. The Faculty Information System can be accessed by everyone through the following link:
                                        </p>

                                        <p class="justified-text"><b>Website: </b><a href="https://nitw.irins.org/">https://nitw.irins.org/</a></p>



                                    </div>
                                </div>



{/* 
                                <div class={`tab-panel ${activeTab === 'v-pills-E-Books' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-E-Books" role="tabpanel"
                                    aria-labelledby="v-pills-E-Books-tab">
                                    <div class="row E-Books">
                                        <h3 align='center'>Technical Society</h3><br></br>
                                        <p style={{ textAlign: 'justify' }} fontSize='12px'>


                                        </p>


                                    </div>
                                </div> */}

















                                <div class={`tab-panel ${activeTab === 'v-pills-AYL' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-AYL" role="tabpanel"
                                    aria-labelledby="v-pills-AYL-tab">
                                    <div class="row AYL">
                                        <p class="justified-text">
                                            <img src="https://nitw.ac.in/api/static/files/Ask_a_Libraria_2022-7-22-10-26-13.png" alt="profile-image" class="profile" /><br></br>
                                            Due to the Covid-19 pandemic, the Central library of NIT Warangal was close down for the public. However, we do not want the students and faculty suffer from getting any information or help from the library. Hence, the central library has created an online, real time ‘virtual reference service’ facility in the library webpage. ‘Ask a Librarian’ is a free online web-based service through email.

                                        </p>
                                    </div>
                                </div>



















                                <div class={`tab-pane ${activeTab === 'v-pills-AcademicResearchGroup' ? 'show active' : ''} fade `} id="v-pills-AcademicResearchGroup" role="tabpanel"
                                    aria-labelledby="v-pills-AcademicResearchGroup-tab">
                                    <div class="row academic_group">
                                        <div class="col-md-12">

                                            <header>
                                                {/* <h4 align='center'>Timings

                                                </h4> */}
                                            </header>
                                        </div>
                                        {/* <h3 align='center'>STUDENT ACTIVITY CENTRE (SAC)</h3><br></br> */}
                                        <p style={{ textAlign: 'justify' }} fontSize='12px'>

                                            {/* <b>About SAC </b><br></br>

                                            The National Institute of Technology, Warangal (NITW) is one among the most renowned technical institutes of India. The institute is known not only for academic excellence but also for offering superior opportunities to its students to participate in recreational and creative activities. These activities are administered through the Student Activity Centre (SAC), which serves as the nerve center of all co-curricular activities in the campus.
                                            <br></br><br></br> */}
                                            <b>The objectives of SAC are: </b><br></br>

                                            - To foster a sense of brotherhood among all Institute students by bridging gaps in gender, cultural preferences, and disparities in state of origin, academic programme, and branch of study.
                                            <br></br>
                                            - To promote extracurricular activities among all students of the Institute.
                                            <br></br>
                                            - To help students in developing their leadership, entrepreneurship, and innovative skills.
                                            <br></br>
                                            - To conduct INTER-NIT competitions in Technical, Cultural, Athletics, Film and Music events and to organize other inter-institutional activities
                                            <br></br>
                                            - To contribute to the development of a multicultural environment where students from various cultural backgrounds can cordially exchange experiences.


                                            <b>SOCIETIES UNDER SAC </b><br></br>

                                            The activities of SAC are organized under four societies, each society focusing on one major aspect of campus life. The societies are:
                                            <br></br>
                                            1. Technical Society (TS)
                                            <br></br>
                                            2. Athletics Society (AS)
                                            <br></br>
                                            3. Cultural Society (CS)
                                            <br></br>
                                            4. Film and Music Society (FM)
                                            <br></br>
                                            The major activities of each society are broadly elaborated in the following sections. However, a society is free to expand its activities from time to time depending on the interest of the students. The broad functions of each society are listed below.

                                            <br></br>
                                            <br></br>
                                            <b>Technical Society (TS)</b>
                                            <br></br>

                                            1. To promote and encourage technical innovation and entrepreneurship among students by organizing seminars and Symposia, Training and workshops in emerging areas of Science and Technology.

                                            <br></br>
                                            2. To promote and encourage the interest of the students in designing and developing working devices and physical models.

                                            <br></br>
                                            3. To promote and encourage computer-based activities including creative software, graphics-based models, games and other challenging applications, and to organize competitions in this area.
                                            <br></br>
                                            4. To conduct the technical festival “TECHNOZION” for students annually at the institute level in collaboration with academic departments. The technical festival is intended to focus on physical and computer models, posters on scientific and technical topics, and presentations based on creative work.
                                            <br></br>
                                            5. To arrange lectures by eminent speakers on emerging areas of science and technology.
                                            <br></br>
                                            6. To encourage and manage participation of NITW students in reputed national level competitions / seminars / conferences.

                                            <br></br><br></br>

                                            <b>Athletics Society (AS) </b><br></br>

                                            1. To encourage all students of the Institute to participate in games and sports activities of their choice.
                                            <br></br>
                                            2. To administer games and sports activities on a day to day basis, and to organize the Annual Sports Day.
                                            <br></br>
                                            3. To administer participation of NITW students in reputed national level sports / athletics competitions.
                                            <br></br>
                                            4. To organize coaching of students in various disciplines of games and sports.
                                            <br></br>
                                            5. To organize sports activities among incoming students with an objective of inducting them into the student mainstream.
                                            <br></br>
                                            6. To organize Seminars, Workshop, training camps etc. on sports, games and related areas.
                                            <br></br><br></br>
                                            <b>Cultural Society (LS)</b><br></br>

                                            1. To promote and encourage extracurricular activities of students in the areas of creative writing, poetry, drama, art, craft, sculpture, painting, photography, quizzes etc.
                                            <br></br>
                                            2. To conduct workshops on creative writing and organize National or Institute level competitions.
                                            <br></br>
                                            3. To organize different Institute level function i.e. Independence day, Republic Day, Institute day etc.
                                            <br></br>
                                            4. To organize the cultural events like Inter-hall competitions.
                                            <br></br>
                                            5. To organize the major cultural event of the year – the two-day long festival “SPRING SPREE”.
                                            <br></br>
                                            6. To make a conscious effort to create a multicultural environment by promoting cultural events from different states of India.

                                            <br></br><br></br>

                                            <b>Film and Music Society (FMS)</b><br></br>

                                            1. To organize an annual music, photography contest and dance festival covering events from all parts of the country.
                                            <br></br>
                                            2. To manage the SPICMACAY chapter in the Institute and invite maestros to perform in the institute.
                                            <br></br>
                                            3. To facilitate practice sessions in music, photography and dance by the students.
                                            <br></br>
                                            4. To organize the major youth event of the year – the two-day long festival “YOUTH FEST”.
                                            <br></br>
                                            5. To screen movies on regular basis in the Institute Auditorium for students and staff members covering films from all parts of the country.
                                            <br></br>
                                            6. To conduct a week long film festival once in a year covering films from all parts of the country.
                                            <br></br><br></br>


                                            <b>Responsibilities of the President </b><br></br>

                                            - Overall coordination of SAC activities and events.<br></br>

                                            - To prepare and publish the annual calendar of SAC after obtaining approval of Dean (SW).<br></br>

                                            - To provide expert advice in conducting major events.<br></br>

                                            - To promote good hobbies and habits for the students and encourage students to actively take part in various competitions organized at NIT and by outside agencies.
                                            <br></br>
                                            - To represent SAC in various forums of the institute.
                                            <br></br>
                                            - To organize Institute Day and award of certificates.
                                            <br></br>
                                            - To divide responsibilities among vice presidents.

                                            <br></br><br></br>


                                            <b>Responsibilities of the Vice Presidents</b><br></br>

                                            - To prepare, obtain approval of President and publish the annual calendar of activities and the budget of the particular Society
                                            <br></br>
                                            - Encourage the students for participation in the activities of the respective societies and promote the interest of the students.
                                            <br></br>
                                            - Guide and advise the students from time to time.
                                            <br></br>
                                            - Conduct the meeting of the society at least once a month to review the progress of various activities.
                                            <br></br>
                                            - Take lead role if the society in conducting major events.
                                            <br></br>
                                            - Select teams for participation in competitions organized by both NITW as well as other institutes.
                                            <br></br><br></br>


                                            <b>The student Council</b><br></br>

                                            The activities of the Student Activity Centre will be managed by the student council through its selected representatives. The collective body of all functionaries, consisting of the Dean (SW), the President, the vice presidents, all the student representatives (including nominated members) is called the Student Council. The Student Council will meet at least twice in each semester, at the beginning and ending of each semester. It will review and recommend the annual budget and approve the activities/ expenditure of all the societies.
                                            <br></br>
                                            Every BTech 1 st , 2 nd , 3 rd and 4 th year will send TWO representatives and MTech and PG (MSc/MBA/MCA) students will send ONE representative each to all societies, thus creating 40 representatives in four societies of SAC. In addition, Dean (SW) will nominate TWO representatives to each society to bring the total number of representatives to 48.
                                            <br></br><br></br>

                                            <b>The Executive Council</b><br></br>

                                            The Executive Council will be headed by the Dean (Student Welfare). The day to day affairs of the society will be managed by the President, 4Four Vice-Presidents (1 for each society) and 8 student members (general and joint secretary elected by each society). Every society will be managed by one Vice-President and a student body consisting of 12 Representatives (10 from each society and 2 nominated by the Dean).

                                            <br></br>
                                            The Director shall appoint a faculty member of the Institute as the President of SAC who will function under the guidance of the Dean (Student Welfare).

                                            <br></br><br></br>

                                            The Executive Council of the SAC will be as follows:
                                            <br></br>


                                            <table className="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <th style={{ fontSize: '18px' }}>Dean (SW)</th>
                                                        <th style={{ fontSize: '18px' }}>	Chairman</th>

                                                    </tr>
                                                    <tr>
                                                        <td>President of SAC</td>
                                                        <td>Member</td>

                                                    </tr>

                                                    <tr>
                                                        <td>One Vice Presidents for each of the four societies</td>
                                                        <td>Members</td>

                                                    </tr>


                                                    <tr>
                                                        <td>Chief Warden of Halls</td>
                                                        <td>Member</td>

                                                    </tr>

                                                    <tr>
                                                        <td>General and Joint Secretaries of four societies (2 for each society)	</td>
                                                        <td>Members</td>

                                                    </tr>

                                                    <tr>
                                                        <td>Faculty of Physical Education or SAS Officers</td>
                                                        <td>Members</td>

                                                    </tr>


                                                </tbody>
                                            </table>


                                            <br></br>

                                            Faculty of Physical Education or SAS Officers will be permanent members.
                                            <br></br>

                                            A standing committee will be constituted as follows to discuss important emergency issues and dispose of them quickly, when necessary. Depending on the issue, the Vice Presidents of concerned society will be co-opted as members.
                                            <br></br><br></br>



                                            <table className="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <th style={{ fontSize: '18px' }}>Dean (SW)</th>
                                                        <th style={{ fontSize: '18px' }}>	Chairman</th>

                                                    </tr>
                                                    <tr>
                                                        <td>President of SAC</td>
                                                        <td>Member</td>

                                                    </tr>

                                                    <tr>
                                                        <td>General Secretary</td>
                                                        <td>Member</td>

                                                    </tr>

                                                    <tr>
                                                        <td>Joint Secretary</td>
                                                        <td>Member</td>

                                                    </tr>

                                                </tbody>
                                            </table>







                                            The functions organized by the SAC depend heavily on the support of the Hall administration. The Chief Warden and all Wardens of Halls are expected to participate strongly in the events, particularly the Inter-NIT sports, cultural, musical and technical meets like the Tech Fest, and the Inter-Hall sports meet along with, cultural, music and technical competitions.
                                            <br></br><br></br>
                                            <b>Selection of Student Members</b><br></br>

                                            The affairs of SAC shall be managed primarily by the student council under the guidance of faculty members. While the faculty mentors are nominated by the Director, student secretaries, joint secretaries and representatives are selected by the students of NITW. Selections will be held in the beginning of the academic year
                                            <br></br><br></br>

                                            <b>Nominated Posts</b><br></br>

                                            Two representatives will be nominated by the Dean (SW) to each of the four societies. The nominated members will have the same responsibilities and privileges as elected representatives. While making nominations, the Dean (SW) will take into consideration the sections of the student population such as female students, postgraduate and research students or students from specific geographical locations, departments and hostels who may not be adequately represented through the election process. Vacancies in position of representatives, selected or nominated, will be filled up by nomination by Dean (SW) in consultation with President SAC
                                            <br></br><br></br>

                                            <b>STUDENT CLUBS</b><br></br>

                                            In addition to the four constituents of the SAC, students will be free to create and operate smaller forums which will be focused on specific activities. A club may be established with a specific objective that is useful to students and does not conflict with that of an existing club. It should have a well-defined constitution, source of income and plan of expenditure. It must not discriminate, directly or indirectly, against students on grounds of religion, caste, state origin, gender, academic programme or any other criterion.

                                            <br></br><br></br>

                                            Some of the clubs existing are<br></br>

                                            <b>Technical Society (TS)</b><br></br>

                                            1. SAE Local Chapter<br></br>
                                            2. Software Development Club<br></br>
                                            3. Science and Hobbies Club<br></br>
                                            4. Satellite Applications Club<br></br>
                                            5. Innovation and Entrepreneurship club and Honey Bee NITW Student Chapter<br></br>
                                            6. Robotics Club<br></br>
                                            7. Astronomy Club<br></br>
                                            8. Ted X<br></br>
                                            9. Business club
                                            <br></br><br></br>
                                            <b>Athletics Society (AS)</b><br></br>
                                            1. Fitness Club<br></br>
                                            2. NSS<br></br>
                                            3. Ek Bharat Shrest Bharat<br></br>
                                            4. Food and Health Club<br></br>
                                            5. Patriot Club<br></br>
                                            <br></br>
                                            <b>Cultural Society (CS)</b><br></br>
                                            1. Quiz Club<br></br>
                                            2. Literary and Debating Club<br></br>
                                            3. Value Education<br></br>
                                            4. Youth Red Cross<br></br>
                                            5. Nature Club<br></br>
                                            6. Painting Club<br></br>
                                            7. International Students Affairs<br></br>
                                            <br></br>

                                            <b>Film and Music Society (FMS)</b><br></br>
                                            1. Music Club<br></br>
                                            2. Electronic Amateurs Club (HAM Club)<br></br>
                                            3. Film Club<br></br>
                                            4. Anime and Comics Club<br></br>
                                            5. Spic-Macay Heritage club<br></br>
                                            6. Dance and Dramatics Club<br></br>
                                            7. Photography Club<br></br>
                                            <br></br>

                                            The clubs are entitled to hold competitions and recommend award of certificates. The Vice President of the parent Society may, at his discretion, send an observer to maintain fairness and high standard of competition in the satellite societies.
                                            <br></br><br></br>
                                            Clubs can be associated with well-known national and international professional bodies (e.g. IEEE, SAE etc) and promote their activities in NIT campus. But formal association with commercial organizations leading to promotion of their products / services, strongly centralized non-profit organizations, or organizations espousing controversial activities or thoughts is forbidden.

                                            <br></br><br></br>






                                        </p>



                                    </div>


                                </div>



                                <div class={`tab-pane ${peopleTab === 'AdjunctFaculty' ? 'show active' : ''} fade`} id="nav-AdjunctFaculty" role="tabpanel"
                                    aria-labelledby="nav-AdjunctFaculty-tab">

                                    <div class="col-md-12 profile-info-individual">
                                        <div class="row">

                                            <div class="col-md-9">
                                                <div class="card">
                                                    <div class="d-flex">
                                                        <div class=""> <img src="https://nitw.ac.in/api/static/files/Prof._Tata_Narasinga_Rao_2023-10-20-18-32-20.png" alt=""
                                                            class="" width="150" height="180" /> </div>
                                                        <div class="ml-3 w-100">
                                                            <h6 class="mb-0 mt-0"> Prof. Tata Narasinga Rao </h6>

                                                            <span>Director, International Advanced Research Centre for Powder Metallurgy & New Materials (ARCI), Hyderabad</span>
                                                            <div class=" mt-2">
                                                                <p class="contact-info">  <i
                                                                    class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:tata@arci.res.in">director@arci.res.in, tata@arci.res.in</a> </p>
                                                                <p class="justified-text">Nanotechnology, Materials science, Energy materials, Li ion Batteries</p>

                                                                <p class="justified-text"><b> Website: </b> <a href="https://www.arci.res.in/people-pages/tn-rao-cv.pdf">https://www.arci.res.in/people-pages/tn-rao-cv.pdf</a></p>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="col-md-9">
                                                <div class="card">
                                                    <div class="d-flex">
                                                        <div class=""> <img src="https://nitw.ac.in/api/static/files/Prof._Pisupati_V_Sharma_2023-10-20-18-32-39.png" alt=""
                                                            class="img-fluid" width="150" height="180" /> </div>
                                                        <div class="ml-3 w-100">
                                                            <h6 class="mb-0 mt-0"> Prof. Pisupati V Sharma </h6>

                                                            <span>Professor of Energy and Mineral Engineering, and Chemical Engineering, Director, Center for Critical Minerals,
                                                                The Pennsylvania State University, USA</span>
                                                            <div class=" mt-2">
                                                                <p class="contact-info">  <i
                                                                    class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sxp17@psu.edu">sxp17@psu.edu</a> </p>
                                                                <p class="justified-text">Chemical kinetics, mass, and heat transfer in combustion processes; CFD modeling of combustion and air pollution systems; oxy-fuel combustion; coal processing and mineral matter transformations in gasification; coal, biomass, and petroleum coke gasification and recovery of rare earth and critical elements from secondary sources and industrial wastes</p>

                                                                <p class="justified-text"><b>Website: </b><a href="https://www.eme.psu.edu/directory/sarma-v-pisupati">https://www.eme.psu.edu/directory/sarma-v-pisupati</a></p>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>






                                            <div class="col-md-9">
                                                <div class="card">
                                                    <div class="d-flex">
                                                        <div class=""> <img src="https://nitw.ac.in/api/static/files/Prof._Ajay_K_Dalai_2023-10-20-18-32-55.png" alt=""
                                                            class="img-fluid" width="150" height="180" /> </div>
                                                        <div class="ml-3 w-100">
                                                            <h6 class="mb-0 mt-0"> Prof. Ajay K Dalai </h6>

                                                            <span>Distinguished Professor and Canada Research Chair in Bioenergy & Environmentally Friendly Chemical Processing, Department of Chemical and Biological Engineering
                                                                College of Engineering, University of Saskatchewan, SK Canada</span>
                                                            <div class=" mt-2">
                                                                <p class="contact-info">  <i
                                                                    class="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ajay.dalai@usask.ca">ajay.dalai@usask.ca</a> </p>
                                                                <p class="justified-text">Nanocatalysis, chemical reaction engineering, bio and petro-fuels, renewable energy, hydroprocessing, gas-to-liquid and environmental technologies</p>

                                                                <p class="justified-text"><b>Website:</b><a href="https://sens.usask.ca/people/faculty/associate-faculty/ajay-dalai.php">https://sens.usask.ca/people/faculty/associate-faculty/ajay-dalai.php</a></p>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>








                                        </div>
                                    </div>


                                </div>



                                <div class={`tab-panel ${activeTab === 'v-pills-NotableAlumni' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-NotableAlumni" role="tabpanel"
                                    aria-labelledby="v-pills-NotableAlumni-tab">

                                    {/* <h3>Web OPAC </h3> */}
                                    <div class="row notable-alumni">

                                        <h3 align='center'>Activities</h3><br></br>
                                        {/* <img
                                            src="https://nitw.ac.in/api/static/files/camp_v1_2023-5-26-13-34-2.jpg"
                                            alt="Image 1"
                                            style={{ width: '100%', height: '350px' }} // // Customize width and height
                                        /> */}
                                        <br></br>
                                        <p style={{ textAlign: 'justify' }}>
                                        </p>


                                    </div>
                                </div>



                                <div class={`tab-pane ${activeTab === 'v-pills-Awards' ? 'show active' : ''} fade  `} id="v-pills-Awards" role="tabpanel"
                                    aria-labelledby="v-pills-Awards-tab">
                                    <p style={{ textAlign: 'justify' }}>

                                    The National Institute of Technology, Warangal (NITW) is one among the most renowned technical institutes of India. The institute is known not only for academic excellence but also for offering superior opportunities to its students to participate in recreational and creative activities. These activities are administered through the Student Activity Centre, which serves as the nerve center of all co-curricular activities in the campus.

                                    </p>
                                    {/* <div className="text-center">
                                        <h4 className="mt-2 mb-2 notice-board1">
                                            <a href="https://www.nitw.ac.in/page/?url=/NoticeBoardBioIHC/IHC" target="_blank"> Notices/Announcements</a>
                                        </h4>
                                    </div> */}

                                </div>

                                <div class={"tab-pane ${activeTab === 'v-pills-ResearchScholars' ? 'active' : ''} fade  "} id="v-pills-ResearchScholars" role="tabpanel"
                                    aria-labelledby="v-pills-ResearchScholars-tab">

                                </div>

                                <div class={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                                    aria-labelledby="v-pills-PhotoGallery-tab">

                                    <div class="details">




                                        {/* <h5>General Rules</h5> */}
                                        <p class="justified-text">
                                            -Library is a place for study and research. Strict silence is to be observed.
                                            <br></br>
                                            -A user has to show identity card whenever he / she enters the Library.
                                            <br></br>
                                            -All the personal belongings should be deposited at the counter.
                                            <br></br>
                                            -The user has to pay Rs. 10/- towards loss of token of personal belongings counter.
                                            <br></br>
                                            -Users are requested to allow frisking of person at the Library exit gate while leaving the Library.
                                            <br></br>
                                            -Personal books, printed material, photocopy material are not allowed inside the Library.
                                            <br></br>
                                            -Outsiders are not allowed into the Library.
                                            <br></br>
                                            -Smoking is strictly prohibited.

                                        </p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        </>
    );
}

export default SWDeptPage;