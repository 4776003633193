import React from 'react';
// import PageHeader from '../components/PageHeader';


const MoU = () => {
  return (


    <div className="container">

<div className="row">
   
  <div className="col-md-12 pb-2">
    <br></br>
    <h2 style={{color:'blue'}}><center>Recent MoUs</center></h2>
    <h3 style={{color:'orange', float:'left'}}><center>2024</center></h3><br></br><br></br>
    <h4 style={{color:'black'}}>September</h4>  
    <img src="https://nitw.ac.in/api/static/files/MoU_signing_with_the_IT_&_EC_Department_New_2024-9-9-17-26-45.jpg" alt="" className="img-fluid mb-5"/>  
    

    
    <h4 style={{color:'black'}}>August</h4>  
    <img src="https://nitw.ac.in/api/static/files/MoU_NITW-HCCL_2024-9-2-9-34-6.jpg" alt="" className="img-fluid mb-5"/>  
    <img src="https://nitw.ac.in/api/static/files/MoU_between_NITW_and_Deeploop1_2024-8-27-11-20-56.jpg" alt="" className="img-fluid mb-5"/>
    
    <h4 style={{color:'black'}}>June</h4>
    <img src="https://nitw.ac.in/api/static/files/MoU_University_of_New_Brunswick_Canada_2024-6-6-13-17-38.jpg" alt="" className="img-fluid mb-5"/>
    <img src="https://nitw.ac.in/api/static/files/MoU_NIT_W_and_KIGG_2024-6-18-18-6-29.jpg" alt="" className="img-fluid mb-5"/>
    <img src="https://nitw.ac.in/api/static/files/MoU_NIT_W_and_AAI_2024-7-1-18-19-45.jpg" alt="" className="img-fluid mb-5"/>

    <h4 style={{color:'black'}}>May</h4>
    <img src="https://nitw.ac.in/api/static/files/MoU_TSWREIS_2024-5-21-19-36-43.jpg" alt="" className="img-fluid mb-5"/>

    <h4 style={{color:'black'}}>April</h4>
    <img src="https://nitw.ac.in/api/static/files/MoU_L&TIPM_2024-5-21-17-12-3.jpg" alt="" className="img-fluid mb-5"/>

    <h4 style={{color:'black'}}>February</h4>

    <img src="https://nitw.ac.in/api/static/files/CSIR_-_CRRI_2024-3-1-11-33-33.jpg" alt="" className="img-fluid mb-5"/>
    <img src="https://nitw.ac.in/api/static/files/MoU_Exchanged_Between_NIT_Warangal_and_Ni-MSME_2024-2-21-17-58-20.jpg" alt="" className="img-fluid mb-5"/>
    <img src="https://nitw.ac.in/api/static/files/University_of_Saskatchewan,_Canada_2024-2-15-12-16-28.jpg" alt="" className="img-fluid mb-5"/>
    <h4 style={{color:'black'}}>January</h4>

    <img src="https://nitw.ac.in/api/static/files/AIIMS_Bibinagar_2024-2-15-12-54-40.jpg" alt="" className="img-fluid mb-5"/> 


    <h3 style={{color:'orange', float:'left'}}><center>2023</center></h3>
    <img src="https://nitw.ac.in/api/static/files/MoU_with_CSIR-IMMT,_Bhubaneswar_2024-1-1-19-40-16.jpg" alt="" className="img-fluid mb-5"/> 
    <img src="https://nitw.ac.in/api/static/files/NITW_MoU_with_IIT_Goa_2024-3-18-9-57-25.jpg" alt="" className="img-fluid mb-5"/> 
    <img src="https://nitw.ac.in/api/static/files/MoU_with_VJIT_2024-2-29-17-20-30.jpg" alt="" className="img-fluid mb-5"/> 
    <img src="https://nitw.ac.in/api/static/files/MoU_Signed_Between_NIT_Warangal_and_NIT_Jamshedpur_2024-2-26-12-57-24.jpg" alt="" className="img-fluid mb-5"/> 
    <img src="https://nitw.ac.in/api/static/files/mou6_2023-11-9-13-26-6.jpg" alt="" className="img-fluid mb-5"/> 
    <img src="https://nitw.ac.in/api/static/files/mou5_2023-11-9-13-25-34.jpg" alt="" className="img-fluid mb-5"/> 
    <img src="https://nitw.ac.in/api/static/files/mou4_2023-11-9-13-25-14.jpg" alt="" className="img-fluid mb-5"/>
    <img src="https://nitw.ac.in/api/static/files/MOU_NITW_AND_MNIT_JAIPUR_2024-2-15-18-57-58.jpg" alt="" className="img-fluid mb-5"/>  
    <img src="https://nitw.ac.in/api/static/files/mou1and2_v3_2023-11-9-13-39-14.jpg" alt="" className="img-fluid mb-5"/> 
  </div>
      <div className="col-md-12 pb-2">
      <h3 style={{color:'blue'}}><center>Other MoUs</center> </h3>
      </div>
      <div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="#" target="_blank">
      <b>Central Power Research Institute </b><br />
      <small>Bangalore </small>
    </a>
  </div>
</div>
<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Indian Institute of Chemical Technology</b><br />
      <small>Hyderabad </small>
    </a>
  </div>
</div>

<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Sky e IT Solutions Private Limited </b><br />
      <small>Hyderabad </small>
    </a>
  </div>
</div>
      

<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Tata Consultancy Services Limited </b><br />
      <small>Hyderabad </small>
    </a>
  </div>
</div>
      



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Warangal Municipal Corporation </b><br />
      <small>Warangal  </small>
    </a>
  </div>
</div>
      


<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Infosys Limited </b><br />
      <small>Bangalore  </small>
    </a>
  </div>
</div>
      



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>The LakshyaFoundaiton </b><br />
      <small>Warangal  </small>
    </a>
  </div>
</div>
      


<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Electronics Corporation of India Limited </b><br />
      <small>Hyderabad   </small>
    </a>
  </div>
</div>
      



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Software Technology Park of India </b><br />
      <small>Hyderabad   </small>
    </a>
  </div>
</div>
      


<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Center for Development of Advanced Computing (C-DAC)</b><br />
      <small>Bangalore    </small>
    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>CNR</b><br />
      <small>Chennai  </small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Effexort Solutions Pvt. Ltd.</b><br />
      <small>Hyderabad  </small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>University of Malardalan</b><br />
      <small>Sweden  </small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>FutureNowInnosoft Pvt. Ltd.</b><br />
      <small> Hyderabad   </small>
    </a>
  </div>
</div>

<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Helsinki University of Technology, University of Oulu, Tampere University of Technology and The University of Tampere</b><br />
      <small> Finland   </small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>IBM India Limited</b><br />
      <small> Bangalore   </small>
    </a>
  </div>
</div>

<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>International Advanced Research Centre for Power Metallurgy and New Materials (ARCI)</b><br />
      <small> Hyderabad    </small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>International Foundation for Entrepreneurship, Science and Technology (IFEST)</b><br />
      <small> USA   </small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Jayaprakash Narayan College of Engineering</b><br />
      <small>Dharampur, Mahabubnagar   </small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>National Academy of Construction (NAC)</b><br />
      <small> Hyderabad    </small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Nonferrous Materials Technology Development Centre</b><br />
      <small> Hyderabad    </small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Research Centre Imarat (RCI)</b><br />
      <small> Hyderabad    </small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Tampere University of Technology</b><br />
      <small>  Finland     </small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Texas A&M Agrilife Research University</b><br />
      <small>  USA   </small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Appolo Computing Laboratory Pvt. Ltd.</b><br />
      <small>  Hyderabad    </small>
    </a>
  </div>
</div>

<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Central Manufacturing Technology Institute (CMTI) </b><br />
     
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Defence Metallurgical Research Laboratory (DMRL)  </b><br />
     
    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>National Institute of Technology Tiruchirapalli (NITT)  </b><br />
     
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Visvesvaraya National Institute of Technology, Nagpur (VNIT)  </b><br />
     
    </a>
  </div>
</div>

<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>National Institute of Technology, Agartala (NITA)   </b><br />
     
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Telangana Academy of skill and knowledge (TASK)_Institute for Electronic Governance</b><br />
      <small> Telangana State     </small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Electronic Corporation of India Limited</b><br />
      <small> Hyderabad     </small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Indian Institute of Technology</b><br />
      <small> Madras, Chennai, India (IITM)     </small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Water and Land Management Training and Research Institute (WALMTARI) </b><br />

    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Elico Ltd.</b><br />
      <small> Hyderabad     </small>

    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>PEPSICO </b><br />

    </a>
  </div>
</div>





<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>ELICO Ltd. </b><br />
      <small> Hyderabad (different from that of S.No.35)    </small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>MNIT </b><br />
      <small>  Hyderabad  </small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>University Corporation for Atmospheric Research  </b><br />
    
    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Ecole Centrale de Nantes  </b><br />
      <small>  Nantes, France   </small>

    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Lave Consulting Services  </b><br />
      <small>  Bangalore   </small>

    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Sapience Consulting a Texas Instruments University Programe   </b><br />
      <small>  Bangalore   </small>

    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Institute for Development and Research in Banking Technology (IDRBT)  </b><br />
    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Telangana State Road Transport Corporation   </b><br />
      <small>  Hyderabad  </small>

    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Telangana State Road Transport Corporation   </b><br />
      <small>  Hyderabad  </small>

    </a>
  </div>
</div>





<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Incube Ventures Pvt. Ltd.</b><br />
      <small> Ahmedabad   </small>

    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Geo Vista Technologies Pvt. Ltd.</b><br />
      <small> Hyderabad   </small>

    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Sky e IT Solutions(P) Ltd.</b><br />
      <small> Hyderabad (License agreement)   </small>

    </a>
  </div>
</div>





<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Sri MangaduKamatchiammam Business Corporation (INFOMAPS)</b><br />
      <small> Chennai    </small>

    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Info Trans Engineers Private Limited (ITE)</b><br />
      <small> Hyderabad   </small>

    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>BHEL for Research & Development</b><br />
      <small> New Delhi   </small>

    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Electro Optical Instruments Research Academy DRDO, Ministry of Defence (MoD) (ELoira)</b><br />
      <small>  Hyderabad   </small>

    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Nucleonix Systems Pvt. Ltd.</b><br />
      <small>  Hyderabad   </small>

    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>South Ural State University Chelyafinsk</b><br />
      <small>  Russia    </small>

    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Affine Tech Systems Pvt. Ltd.</b><br />
      <small> Hyderabad   </small>

    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>iBuild innovations India Ltd.</b><br />
      <small> Hyderabad   </small>

    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Survey of India</b><br />
      <small> New Delhi    </small>

    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Institute for Solid Waste Research and Ecological Balance(INSWAREB)</b><br />
      <small>  Visakhapatnam    </small>

    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>CUSMAT Technologies</b><br />
      <small> Hyderabad  </small>

    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>FLOWHREX Technologies</b><br />
      <small> Pune  </small>

    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>AMD India Pvt Ltd.</b><br />
      <small> Hyderabad  </small>

    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Neuland Laboratories Limited</b><br />
      <small> Hyderabad  </small>

    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Research on Advanced Biomedical Solutions Pvt Ltd.</b><br />
      <small> Hyderabad  </small>

    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Blusapphire Cyber Systems Pvt Ltd.</b><br />
      <small> Hyderabad  </small>

    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Engineering Staff College of India (ESCI)</b><br />
      <small> Hyderabad  </small>

    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Exseed Space Innovations Pvt Ltd.</b><br />
      <small> Mumbai   </small>

    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Eternal Green Innovations Pvt Ltd</b><br />
      <small> Hyderabad    </small>

    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Engineering Council of India</b><br />
      <small> New Delhi  </small>

    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Hexagon Capability Center India Pvt Ltd.</b><br />
      <small> Hyderabad   </small>

    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Telangana State Council of Science & Technology (TSCOST)</b><br />
      <small> Govt Telangana, Hyderabad  </small>

    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>National Council for Cement and Building Materials</b><br />
      <small> Hyderabad  </small>

    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="#" target="_blank">
      <b>Indian Institute of Packaging</b><br />
      <small>Mumbai  </small>

    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="https://nitw.ac.in/api/static/files/IITD_2023-3-26-12-59-0.pdf" target="_blank">
      <b>Indian Institute of Delhi (IITD)</b><br />
    

    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
  <a href="https://nitw.ac.in/api/static/files/Indian_Institute_of_Technology_Madras_(IITM)_2023-11-9-13-19-6.pdf" target="_blank">
      <b>Indian Institute of Technology Madras (IITM)</b><br />
    

    </a>
  </div>
</div>

    </div>
    </div>
  );
};

export default MoU;
