import { useState } from "react";


const BIODeptPage = () => {

    const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
    const [peopleTab, setPeopleTab] = useState("Faculty");

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        console.log(tabId)
    }

    const handlePeopleTabClick = (tabId) => {
        setPeopleTab(tabId);
        console.log(tabId)
    }
    return (
        <>
        <div className="dept-wrapper">
            <div className="container">
                <section className="py-4 header">
                    <header>
                        {/* */}
                        {/*<img src="CSEDeptAssets/images/CS-banner_NITW-new.png" className="img-fluid"/>*/}
                        <img src="BIODeptAssets/images/BIObanner.jpg" className="img-fluid" />
                    </header>
                    <div className="row">
                        <div className="col-md-3">
                            {/* Tabs nav */}
                            <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                                aria-orientation="vertical">
                                {/*<a className="nav-link    active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><span>Home</span></a>*/}
                                <a className={`nav-link ${activeTab === 'v-pills-visionandmission' ? 'active' : ''}`} id="v-pills-visionandmission-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-visionandmission')}
                                    role="tab" aria-controls="v-pills-visionandmission"
                                    aria-selected="false">
                                    <span>About</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-academics' ? 'active' : ''}`}
                                    id="v-pills-academics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-academics')} role="tab"
                                    aria-controls="v-pills-academics" aria-selected="false">
                                    <span>Academic Programmes</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-Faculty' ? 'active' : ''}`} id="v-pills-Faculty-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Faculty')}
                                    role="tab" aria-controls="v-pills-Faculty" aria-selected="false">
                                    <span>People </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-NotableAlumni' ? 'active' : ''}`} id="v-pills-NotableAlumni-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-NotableAlumni')}
                                    role="tab" aria-controls="v-pills-NotableAlumni"
                                    aria-selected="false">
                                    <span>Notable Alumni </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-Students' ? 'active' : ''}`}
                                    id="v-pills-Students-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Students')} role="tab"
                                    aria-controls="v-pills-Students" aria-selected="false">
                                    <span>Students Society </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-AcademicResearchGroup' ? 'active' : ''}`} id="v-pills-AcademicResearchGroup-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-AcademicResearchGroup')}
                                    role="tab"
                                    aria-controls="v-pills-AcademicResearchGroup" aria-selected="false"> <span>Academic Research
                                        Group</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-Awards' ? 'active' : ''}`} id="v-pills-Awards-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Awards')}
                                    role="tab" aria-controls="v-pills-Awards"
                                    aria-selected="false"> <span>Awards and Honours</span> </a>
                                {/*<a className="nav-link ${activeTab === ? 'active' : ''}   " id="v-pills-ResearchScholars-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-ResearchScholars')} href="#v-pills-ResearchScholars" role="tab" aria-controls="v-pills-ResearchScholars" aria-selected="false"><span>Research Scholars</span></a>*/}
                                <a className={`nav-link ${activeTab === 'v-pills-labs' ? 'active' : ''}`} id="v-pills-labs-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-labs')} role="tab"
                                    aria-controls="v-pills-labs" aria-selected="false">
                                    <span>Laboratory & Facilities</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-PhotoGallery' ? 'active' : ''}`} id="v-pills-PhotoGallery-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-PhotoGallery')}
                                    role="tab" aria-controls="v-pills-PhotoGallery"
                                    aria-selected="false"> <span>Photo Gallery</span> </a>

                            </div>
                            <div className="head_of_dept"> <a href="https://erp.nitw.ac.in/ext/profile/bt-rrb"
                                target="_blank"> <img src="BIODeptAssets/images/7.jpg" alt="" className="img-fluid" />
                                <h6>Prof. B Rama Raju</h6>
                            </a>
                                <p> Head of the Department <br /> <a href="mailto:biotech_hod@nitw.ac.in"
                                    style={{ color: "#808080" }}>biotech_hod@nitw.ac.in</a> <br /> 9490165348 </p>
                            </div>
                        </div>
                        <div className="col-md-9">
                            {/* Tabs content */}
                            <div className="tab-content" id="v-pills-tabContent">
                                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade    show active p-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"></div>*/}

                                <div className={`tab-pane fade ${activeTab === 'v-pills-visionandmission' ? 'show active' : 'alumniTabHide'} `} id="v-pills-visionandmission"
                                    role="tabpanel" aria-labelledby="v-pills-visionandmission-tab">
                                    <div className="intro-section">
                                        {/* <h4 className=" mb-4">Welcome to Computer Science and Engineering Department</h4>*/}
                                        <p>Biotechnology engineering is a branch of engineering where technology is combined with biology for research & development. There are various applications of biotechnology in fields such as animal husbandry, growth of vaccines and medicines, agriculture, pollution control, energy production and conservation, healing of prolonged disease and ecological conservation. It also helps in development of insecticides, fertilizers and quality of seeds. A candidate who specializes in this field is known as a biotechnologist or Bioprocess Engineer. There are lots of career opportunities in this field for young aspirants both in India as well as in aboard.</p>


                                        <p className="more"> <a className="more-info-link" href="https://www.inventiva.co.in/trends/biotechnology-companies/" target="_blank"> Best 10 Biotechnology Companies In India In 2021 <i className="fa fa-angle-right"></i> </a> </p>

                                    </div>
                                    <div className="row vision-mission-section">
                                        <div className="col-md-6 vision-section">
                                            <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/vision.png" className="img-fluid mr-3"
                                                alt="" /> Vision</h4>

                                            <p>Through its faculty, Department of Bio-Technology is a driving force.</p>
                                            <p className="mb-4">To apply engineering knowledge and create novel technologies that improve the human condition by optimizing opportunities for further training and a successful transition to a career in the biotechnology and life sciences.</p>
                                            <p className="mb-4">To initiate a level of attitude in research that will provide solutions to environmental, industrial, agricultural and health based problems.</p>
                                            <p className="mb-4">To graduate students who can compete, with advantage, within the relevant academic and commercial markets in the world. </p>


                                        </div>
                                        <div className="col-md-6 mission-section">
                                            <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/mission.png" className="img-fluid mr-3"
                                                alt="" /> Mission</h4>
                                            <p className="  mb-2">To offer a comprehensive exploration of basic science, applied science, and lab science with an industry focus and to act collectively like a enzyme in enhancing, improving and supporting interdisciplinary research and training.</p>
                                            <p className="  mb-2">To provide a quality education in pursuit of knowledge, that establishes a strong foundation for understanding developments in the rapidly advancing field of biotechnology with the development of new biochemical technologies and therapies through research and education.</p>
                                            <p className="  mb-2">To provide the nation with highly trained professional who are able to implement the scientific principles to the continuous improvement of the safe, quality and value biotechnological services and products.</p>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <h4 className="mt-2 mb-2 notice-board1">
                                            <a href="https://www.nitw.ac.in/page/?url=/NoticeBoardBio/Biotechnology" > Notices/Announcements</a>
                                        </h4>
                                    </div>
                                </div>

                                <div className={`tab-pane ${activeTab === 'v-pills-academics' ? 'show active' : 'alumniTabHide'} fade   " id="v-pills-academics`} role="tabpanel"
                                    aria-labelledby="v-pills-academics-tab">
                                    {/*<h4 className="mb-4">Academic Programmes</h4>*/}
                                    {/*<div className="row">
  <div className="col-md-12">
    <h3 className="mb-3">PhD</h3>
  </div>
</div>*/}
                                    <div className="row">


                                        <div className="col-md-12 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>B.Tech</h3>
                                                        <p>The study of Biotechnology is a blend of subjects related Biology and subjects like Mathematics, Physics, Chemistry and Engineering. A simple definition of Biotechnology is that it uses the properties of living organisms to produce desired products. The Department was started in the year 2006 with an under-graduate programme in B.Tech with an intake of 60 students. Presently the intake is 92 students. Curriculum of B.Tech. degree programme in Biotechnology is at par with that of the best Institutions in the country. Industrial visits are included. Departmental core subjects, departmental electives, basic science core subjects and electives, basic engineering core subjects and electives humanities and social sciences core subjects and electives are included in the curriculum. It is a flexible curriculum and choice based credit system. Every student has to carry out an independent project in the IV year.</p>

                                                        <p>Curriculum of B.Tech. degree programme in Biotechnology is at par with that of the best Institutions in the country.Industrial visits are included. Departmental core subjects, departmental electives, basic science core subjects and electives, basic engineering core subjects and electives humanities and social sciences core subjects and electives are included in the curriculum. It is a flexible curriculum and choice based credit system. Every student has to carryout an independent project in the IV year.</p>


                                                        {/*<img src="CSEDeptAssets/images/btech.jpg" alt="" className="img-fluid"/>*/}
                                                    </div>

                                                    {/* <p className="more"> <a className="more-info-link"
                                                        href="BIODeptAssets/docs/biotech-btech-tt.pdf" target="_blank"> Even Semister Academic Year (2022 -2023) <i className="fa fa-angle-right"></i> </a> </p> */}

                                                    <p className="more"> <a className="more-info-link"
                                                        href="BIODeptAssets/docs/syllabus-btech-bio.pdf" target="_blank"> B.Tech
                                                        Shceme and Syllabus <i className="fa fa-angle-right"></i> </a> </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>M.Tech</h3>
                                                        {/* <p>M. Tech (Computer Science and Engineering) program was started in
                                                            1987 with an intake of 18 and subsequently increased to 20 in 2008.
                                                            Currently the strength is 30.</p> */}
                                                        {/*<img src="CSEDeptAssets/images/mtech1.jpg" alt="" className="img-fluid"/>*/}
                                                    </div>
                                                    <p className="more"> <a className=" more-info-link"
                                                        href="BIOeptAssets/docs/biotech-mtech-tt.pdf"
                                                        target="_blank"> M.Tech Sheme and Syllabus
                                                        {/* 2022-2023   */} <i
                                                            className="fa fa-angle-right"></i>
                                                    </a> </p>

                                                    {/* <p className="more"> <a className=" more-info-link"
                                                        href="BIODeptAssets/docs/mtech-biotech-syllabus-1892020_8SdWS3m.pdf"
                                                        target="_blank"> M.Tech syllabus 2020-2021  <i
                                                            className="fa fa-angle-right"></i> </a> </p> */}
                                                </div>
                                            </div>
                                        </div>






                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>Ph.D.</h3>
                                                        {/*<img src="CSEDeptAssets/images/phd.jpg" alt="" className="img-fluid"/>*/}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>




                                        {/* <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>Academic Calendar</h3>
                                                      
                                                    </div>
                                                    <p className="more"> <a className=" more-info-link"
                                                        href="BIOeptAssets/docs/i-btech-int-msc-ii-sem.pdf"
                                                        target="_blank"> Even Semester  <i
                                                            className="fa fa-angle-right"></i> </a> </p>

                                                        <p className="more"> <a className=" more-info-link"
                                                        href="BIODeptAssets/docs/i-btech-int-msc-i-sem.pdf"
                                                        target="_blank"> Odd Semester  <i
                                                            className="fa fa-angle-right"></i> </a> </p>


                                                        <p className="more"> <a className=" more-info-link"
                                                        href="BIODeptAssets/docs/acad_calendar-summer-quarter.pdf"
                                                        target="_blank"> Summer Quarter <i
                                                            className="fa fa-angle-right"></i> </a> </p>


                                                </div>
                                            </div>
                                        </div> */}





                                        {/* <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>Time-Table</h3>
                                                       
                                                    </div>
                                                    <p className="more"> <a className=" more-info-link"
                                                        href="BIOeptAssets/docs/biotech-btech-tt.pdf"
                                                        target="_blank"> B.Tech (Even Semester)  <i
                                                            className="fa fa-angle-right"></i> </a> </p>

                                                        <p className="more"> <a className=" more-info-link"
                                                        href="BIODeptAssets/docs/biotech-mtech-tt.pdf"
                                                        target="_blank"> M.Tech (Even Semester)  <i
                                                            className="fa fa-angle-right"></i> </a> </p>


                                                </div>
                                            </div>
                                        </div> */}









                                    </div>

                                </div>
                                <div className={`tab-pane ${activeTab === 'v-pills-labs' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-labs" role="tabpanel"
                                    aria-labelledby="v-pills-labs-tab">
                                    <div className="row laboratory-facilities">
                                        <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            {/* <header>
                                                <h3 className="">Micro Biology Lab</h3>
                                            </header> */}
                                            {/* <div className="row">
                                                <div className="col-md-6">
                                                     <div className="card item-card card-block">
                                                        <h4 className="card-title  mb-2">Hardware</h4>
                                                        <ul className="ps-0 list-unstyled">
                                                            <li className="text-black">HP EliteDesk 800 g1 Small Form Factor
                                                                Systemsi7 @3.40GHz, RAM 4GB, HDD 500GB</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 40</p>
                                                            <li className="text-black">HP Proliant 8300 Small Form Factor systems
                                                                i7@ 3.40GHz, RAM 8GB, HDD 500GB</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 5</p>
                                                        </ul>
                                                        <h4 className="card-title  mb-2"> Software</h4>
                                                        <p>Dual boot with Windows 7 and Ubuntu 14.04, NetBeans 7, MSDN, Dev C++
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6"> <img src="BIODeptAssets/images/microbiology_lab.jpg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div>
                                            </div> */}
                                        </div>
                                        {/* <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                <h3 className="">Bio Chemistry Lab</h3>
                                            </header>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="card item-card card-block">
                                                        <h4 className="card-title  mb-2">Hardware</h4>
                                                        <ul className="ps-0 list-unstyled">
                                                            <li className="text-black">HP EliteDesk 800 g1 SFF Systems i7 @3.40GHz,
                                                                4GB RAM, 500GB HDD</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 50</p>
                                                        </ul>
                                                        <h4 className="card-title  mb-2">Software</h4>
                                                        <p>Cognos BIClementine Version 11.0, Oracle 11g (Unlimited user
                                                            Licenses), MSDN Academic Alliance Software, Informatica (25 user
                                                            licenses )</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6"> <img src="CSEDeptAssets/images/data-eng.jpg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div>
                                            </div>
                                        </div> */}
                                        <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            {/* <header>
                                                <h3 className="">Instrumental Analysis Lab</h3>
                                            </header> */}
                                            {/* <div className="row">
                                                <div className="col-md-6">
                                                     <div className="card item-card card-block">
                                                       <h4 className="card-title  mb-2">Hardware</h4>
                                                        <ul className="ps-0 list-unstyled">
                                                            <li className="text-black">HP Proliant 8300 Small Form Factor systems
                                                                i7@ 3.40GHz, RAM 8GB, HDD 500GB</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 45</p>
                                                        </ul>
                                                        <h4 className="card-title  mb-2">Software</h4>
                                                        <p>Dual boot with Windows XP and Ubuntu 12.0, NetBeans 7, Oracle Client
                                                            10</p>
                                                    </div> 
                                                </div>
                                                <div className="col-md-6"> <img src="BIODeptAssets/images/Instrumental_Analysis_Lab.jpg"
                                                    alt="" className="img-fluid" /> </div>
                                            </div>  */}
                                        </div>

                                        <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            {/* <header>
                                                <h3 className="">Down Stream Processing Lab</h3>
                                            </header> */}
                                            {/* <div className="row">
                                                <div className="col-md-6">
                                                     <div className="card item-card card-block">
                                                       <h4 className="card-title  mb-2">Hardware</h4>
                                                        <ul className="ps-0 list-unstyled">
                                                            <li className="text-black">Dell Optiplex 760Intel Core2 duo CPU E8400
                                                                @3.00GHz, 300GB HDD, 4GB RAM</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 50</p>
                                                            <li className="text-black">Acer Veriton Intel Core i7 with 1TB HDD, 8 GB
                                                                RAM</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 40</p>
                                                        </ul>
                                                        <h4 className="card-title  mb-2">Software</h4>
                                                        <p>Rational Testing Suite, MSDN, NetBeans 7, Microsoft Assembler, Dev
                                                            C++ </p>
                                                    </div> 
                                                </div>
                                                <div className="col-md-6"> <img src="BIODeptAssets/images/DownStreamProcessingLab.jpg" alt=""
                                                    className="img-fluid" /> </div>
                                            </div>  */}
                                        </div>

                                        <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            {/* <header>
                                                <h3 className="">Bio Process Control & Instrumentation</h3>
                                            </header> */}
                                            {/* <div className="row">
                                                <div className="col-md-6">
                                                     <div className="card item-card card-block">
                                                        <h4 className="card-title  mb-2">Hardware</h4>
                                                        <ul className="ps-0 list-unstyled">
                                                            <li className="text-black">i7 processor, 16GB RAM</li>

                                                            <li className="text-black">Windows OS, Ubuntu OS</li>

                                                        </ul>

                                                    </div> 
                                                </div>
                                                <div className="col-md-6"> <img src="BIODeptAssets/images/BioProcessControlInstrumentation.jpg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div>
                                            </div> */}
                                        </div>

                                        <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            {/* <header>
                                                <h3 className="">Molecular Biology & Genetics Lab</h3>
                                            </header> */}
                                            {/* <div className="row">
                                                <div className="col-md-6">
                                                    <div className="card item-card card-block">
                                                        <h4 className="card-title  mb-2">Hardware</h4>
                                                        <ul className="ps-0 list-unstyled">
                                                            <li className="text-black">HP Proliant 8300 Micro Tower systemsi5
                                                                @3.40GHz, RAM 2GB, 500GB HDD</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 30</p>
                                                            <li className="text-black">Dell Optiplex 760Intel Core2 duo CPU E8400
                                                                @3.00GHz, 300GB HDD, 4GB RAM</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 10</p>
                                                            <li className="text-black">HCL Dual core2Intel® Core™ 2 Duo CPU
                                                                E4500@2.20 GHz, 1GB RAM, 160GB HDD</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 4</p>
                                                        </ul>
                                                        <h4 className="card-title  mb-2">Software</h4>
                                                        <p>ONE Simulator, NS3, Rational Rose Tools, MSDN, Dev C++</p>
                                                    </div> 
                                                </div>
                                                <div className="col-md-6"> <img src="BIODeptAssets/images/MolecularBiologyGeneticsLab.jpg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div>
                                            </div>  */}
                                        </div>

                                        {/* <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                <h3 className="">Servers</h3>
                                            </header>
                                            <div className="row">
                                               
                                                <div className="col-md-12"> <img src="CSEDeptAssets/images/server.jpg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div>
                                            </div> <br />
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">Windows File Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>HP Proliant DL360e Gen8 intel®
                                                            Xeon® CPU E5-2420 @1.90GHz with 32GB RAM, 2*2TB HDD </p>
                                                    </div>
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">Oracle Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>HP Proliant DL160 Gen8 Intel®
                                                            Xeon® CPU E5 -2630 @2.30GHz with 16GB RAM, 2*1TB </p>
                                                    </div>
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">McAFEE Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>HCL Intel® Xeon® CPU 3.40GHz
                                                            with 4GB RAM, 3*120GB HDD </p>
                                                    </div>
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">Proxy Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>Hp Compaq Elite 8300 SFF (i5
                                                            -3470) CPU @3.40GHz, RAM 2GB, HDD 500GB </p>
                                                    </div>
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">COGNOS Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>HP Proliant DL360 Intel® Xeon®
                                                            CPU 3.20GHz, 3GB RAM, 160GB HDD </p>
                                                    </div>
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">Rational Rose Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>IBM e-server Intel® Xeon™ CPU
                                                            2.40 GHz, 1GB RAM, 40GB HDD </p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">Linux Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>HP Proliant DL360e Gen8 intel®
                                                            Xeon® CPU E5-2420 @1.90GHz with 32GB RAM, 2*2TB HDD) </p>
                                                    </div>
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">High Performance Computing Server (8
                                                            node Cluster)</h4>
                                                        <p className="text-black"> <b>Hardware: </b>HP Proliant DL380P Gen82 x
                                                            Intel® xeon® CPU E5-2640 (2.5 GHz / 6-core/15MB / 95w) Processor64
                                                            GB DDR RAM, HP SA 410i RAID controller with 1 GB FBWCHP SN1000E 16GB
                                                            Dual Port, FC HBA/1200WIntel® xeon® CPU E5-2640 (2.5 GHz /
                                                            6-core/15MB / 95w) ProcessorIntel 7500 chip set with node controller
                                                        </p>
                                                    </div>
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">Big Data Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>HP Proliant DL980 Gen78 x Intel®
                                                            xeon® CPU E7-2830 (2.13 GHz / 8-core/24MB / 105w) ProcessorIntel
                                                            7500 chip set with node controller, 512 GB DDR RAMHP SA 410i RAID
                                                            controller with 1 GB FBWC6x600GB 10K HDD, DVD + RW 12 Nos. of PCI
                                                            slots4x dual port Giga Bit Ethernet NIC and 2x 10GBPS Ethernet
                                                            CardHP SN1000E 16GB Dual Port, FC HBA/1200WRack Mountable Hot
                                                            pluggable Redundant Power Supplies and Redundant fans/ Server
                                                            Manageability SoftwareOS: CentOS 6.5 Server with Hadoop2.2 </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}



                                    </div>
                                </div>

                                <div className={`tab-pane ${activeTab === 'v-pills-Phd' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Phd" role="tabpanel"
                                    aria-labelledby="v-pills-Phd-tab">
                                    <h4 className=" mb-4">Phd</h4> <a href="" target="_blank"> <span className="highlight">P</span>h.D.
                                        Students List ( Full / Part Time) <i className="fa fa-link " aria-hidden="true"></i> </a>
                                </div>
                                <div className={`tab-pane ${activeTab === 'v-pills-Faculty' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Faculty" role="tabpanel"
                                    aria-labelledby="v-pills-Faculty-tab">
                                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'Faculty' ? 'active' : ''} `} id="nav-faculty-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-faculty"
                                            aria-selected="true" onClick={() => { handlePeopleTabClick("Faculty") }}>Faculty</a>

                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'NonTeachingStaff' ? 'active' : ''} `}
                                            id="nav-NonTeachingStaff-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-NonTeachingStaff" aria-selected="false" onClick={() => { handlePeopleTabClick("NonTeachingStaff") }}>Non-Teaching
                                            Staff</a>


                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'ResearchScholars' ? 'active' : ''} `} id="nav-ResearchScholars-tab"
                                            data-toggle="tab" role="tab"
                                            aria-controls="nav-ResearchScholars" aria-selected="false" onClick={() => { handlePeopleTabClick("ResearchScholars") }}>Research Scholars</a>


                                    </div>
                                    <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"
                                        style={{ marginTop: "5%", marginLeft: "1%" }}>
                                        <div className={`tab-pane ${peopleTab === 'Faculty' ? 'show active' : ''} fade`} id="nav-faculty" role="tabpanel"
                                            aria-labelledby="nav-faculty-tab">
                                            {/*<h4 className=" mb-4">Faculty</h4>*/}
                                            {/* <a href="" target="_blank"><span className="highlight">F</span>aculty Profiles <i className="fa fa-external-link" aria-hidden="true"></i></a>*/}
                                            {/* <section id="profile-info">



                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16440"
                                                            target="_blank"> <img src="BIODeptAssets/images/6.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16440"
                                                                        target="_blank">Prof. Amitava Bandhu</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969446 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:bandhuam@nitw.ac.in">bandhuam@nitw.ac.in</a> </p>
                                                                    <p>Studies on gene regulation in Mycobacteria</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-anbu"
                                                            target="_blank"> <img src="BIODeptAssets/images/14.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-anbu"
                                                                        target="_blank">Prof. Anbumathi P</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 08702462703 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:anbu@nitw.ac.in">anbu[@]nitw[dot]ac[dot]in</a>
                                                                    </p>
                                                                    <p>Systems Biology, Cancer, Cell Cycle Regualtion, Mathematical Modeling and Analysis</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17075"
                                                            target="_blank"> <img src="BIODeptAssets/images/21.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17075"
                                                                        target="_blank">Prof. Ashish A Prabhu</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9582700174 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:ashishp@nitw.ac.in">ashishp@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Metabolic Engineering; Bioprocess Engineering; Biomass and Biorefinery; Therapeutic proteins/enzymes</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-asimbikas"
                                                            target="_blank"> <img src="BIODeptAssets/images/10.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-asimbikas"
                                                                        target="_blank">Prof. Asim Bikas Das</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969440 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:asimbikas@nitw.ac.in">asimbikas@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Network system biology ; Cancer genomics</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17026"
                                                            target="_blank"> <img src="BIODeptAssets/images/20.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17026"
                                                                        target="_blank">Prof. Chockalingam S</a>
                                                                    </h5> <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9502205174 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:chocks@nitw.ac.in">chocks@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Cancer biology; Cancer cell signaling; Cancer stem cell biology</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16351"
                                                            target="_blank" className="img-fluid"> <img
                                                                src="BIODeptAssets/images/4.jpg" alt="" className="img-fluid" /> </a>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16351"
                                                                        target="_blank">Prof. Korrapati Narasimhulu</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9985470286 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:simha_bt@nitw.ac.in">simha_bt@nitw.ac.in</a>
                                                                        
                                                                    </p>
                                                                    <p>Environmental Biotechnology,Modelling & Simulation of Bioprocesses,Biofuels & Bioenergy,Systems Biology.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16895"
                                                            target="_blank"> <img src="BIODeptAssets/images/13.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16895"
                                                                        target="_blank">Prof. M. Jerold</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9486252374 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:jerold@nitw.ac.in">jerold@nitw.ac.in
                                                                        </a> </p>
                                                                    <p>Biofuels & Bioenergy; Bioprocessing; Biosorption; Wastewater Treatment; Bioseparation; Algal Biotechnology ; Biopolymer; Biorefineries; Biomass Valorization; Biogenic Nanoparticles</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-popomal"
                                                            target="_blank"> <img src="BIODeptAssets/images/5.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-popomal"
                                                                        target="_blank">Prof. Onkara Perumal. P</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i
                                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                            href="mailto:popomal@nitw.ac.in">popomal@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Bioinformatics; Computational Biology; Functional Genomics; Metabolic Disorders.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16894"
                                                            target="_blank"> <img src="BIODeptAssets/images/12.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16894"
                                                                        target="_blank">Prof. Perugu Shyam</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"><i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9948561761 <br /> <i className="fa fa-envelope"
                                                                            aria-hidden="true"></i> <a
                                                                                href="mailto:shyamperugu@nitw.ac.in">shyamperugu@nitw.ac.in</a> </p>
                                                                    <p>Bioinformatics, Systems biology, Cancer biology, Molecular Dynamics & Simulations, Drug Design and Discovery, Machine Learning, Biological Bigdata analysis.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16425"
                                                            target="_blank"> <img src="BIODeptAssets/images/1.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16425"
                                                                        target="_blank">Prof. Prakash Saudagar</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2452898 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:ps@nitw.ac.in">ps@nitw.ac.in</a> </p>
                                                                    <p>Protein Biochemistry, Molecular Approach to Infectious Diseases, Molecular & Biochemical Parasitology</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17024"
                                                            target="_blank"> <img src="BIODeptAssets/images/18.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17024"
                                                                        target="_blank">Prof. Priya</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                        <a href="mailto:priyap@nitw.ac.in"> priyap@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Cell and molecular biology</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-satishbabu"
                                                            target="_blank"> <img src="BIODeptAssets/images/3.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-satishbabu"
                                                                        target="_blank">Prof. R. Satish Babu</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969439 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:satishbabu@nitw.ac.in">satishbabu@nitw.ac.in</a>
                                                                        
                                                                    </p>
                                                                    <p>Bioprocess EngineeringEnvironmental BiotechnologyModeling and Simulation of BioprocessesMetabolic EngineeringBioinformatics</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-rrb"
                                                            target="_blank"> <img src="BIODeptAssets/images/7.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-rrb"
                                                                        target="_blank">Prof. Rama Raju. B</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969462 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:rrb@nitw.ac.in">rrb@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Metabolic Engineering, Biofuels and Biomaterials</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-rathnaprabhad"
                                                            target="_blank"> <img src="BIODeptAssets/images/15.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-rathnaprabhad"
                                                                        target="_blank">Prof. Rathnaprabha D</a>
                                                                    </h5> <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9494668822 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:rathnaprabhad@nitw.ac.in"> rathnaprabhad@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Plant Biotechnology, Plant tissue culture Plant Molecular biology, Phytochemicals analysis from medicinal plantsts</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17023"
                                                            target="_blank"> <img src="BIODeptAssets/images/16.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17023"
                                                                        target="_blank">Prof. Soumya Lipsa Rath</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-24524205 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:slrath@nitw.ac.in">
                                                                            slrath[@]nitw[dot]ac[dot]in </a> </p>
                                                                    <p>Molecular Modelling ; Structure Based Drug Designing;Study of Protein structure and function using Molecular Dynamics Simulations</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-parcha"
                                                            target="_blank"> <img src="BIODeptAssets/images/2.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-parcha"
                                                                        target="_blank">Prof. Sreenivasa Rao Parcha</a>
                                                                    </h5> <span>Professor</span>
                                                                    <p className="contact-info"><i
                                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                            href="mailto:parcha@nitw.ac.in">parcha@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Stem Cell Engineering, Downstream Processing and Enzyme Engineering</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-sevdasuraj"
                                                            target="_blank"> <img src="BIODeptAssets/images/17.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-sevdasuraj"
                                                                        target="_blank">Prof. Surajbhan Sevda</a>
                                                                    </h5> <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9929565697
                                                                        <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                        <a href="mailto:sevdasuraj@nitw.ac.in"> sevdasuraj@nitw.ac.in</a> </p>
                                                                    <p>My current area of Research: (a) Applications of protein nanowires in electronic sensing and power-generating devices.(b) Biosensing for environmental monitoring and healthcare diagnostics;(c) Direct interspecies electron transfer; (d) Synthetic electromicrobiology; (e) Novel bioenergy strategies;</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-thyagesh"
                                                            target="_blank"> <img src="BIODeptAssets/images/19.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-thyagesh"
                                                                        target="_blank">Prof. Thyageshwar Chandran</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 7348975012
                                                                        <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                        <a href="mailto:thyagesh@nitw.ac.in"> thyagesh@nitw.ac.in</a>
                                                                       
                                                                    </p>
                                                                    <p>Structural Proteomics (X-ray Crystallography and Cryo-EM); Molecular Dynamics of Macromolecules; Molecular Biophysics; Lectins; Ribosome Inactivating Proteins; Ribosomes</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-urmila"
                                                            target="_blank"> <img src="BIODeptAssets/images/11.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-urmila"
                                                                        target="_blank">Prof. Urmila Saxena</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969442 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:urmila@nitw.ac.in">urmila@nitw.ac.in</a>
                                                                    </p>
                                                                    <p> Statistical Analysis of Biological Data for Biomarker Identification; Medical Biosensors; Nanobiotechnology</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-v.kohila"
                                                            target="_blank"> <img src="BIODeptAssets/images/8.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-v.kohila"
                                                                        target="_blank">Prof. V. Kohila</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9849432598 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:v.kohila@nitw.ac.in">v.kohila@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Protein Engineering, Immunology</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>







                                            </section> */}
                                            <section id="profile-info">



                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-bandhuam"
                                                            target="_blank"> <img src="BIODeptAssets/images/6.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-bandhuam"
                                                                        target="_blank">Prof. Amitava Bandhu</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969446 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:bandhuam@nitw.ac.in">bandhuam@nitw.ac.in</a> </p>
                                                                    <p>Studies on gene regulation in Mycobacteria</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-anbu"
                                                            target="_blank"> <img src="BIODeptAssets/images/14.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-anbu"
                                                                        target="_blank">Prof. Anbumathi P</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 08702462703 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:anbu@nitw.ac.in">anbu[@]nitw[dot]ac[dot]in</a>
                                                                    </p>
                                                                    <p>Systems Biology, Cancer, Cell Cycle Regualtion, Mathematical Modeling and Analysis</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-ashishp"
                                                            target="_blank"> <img src="BIODeptAssets/images/21.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-ashishp"
                                                                        target="_blank">Prof. Ashish A Prabhu</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9582700174 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:ashishp@nitw.ac.in">ashishp@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Metabolic Engineering; Bioprocess Engineering; Biomass and Biorefinery; Therapeutic proteins/enzymes</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-asimbikas"
                                                            target="_blank"> <img src="BIODeptAssets/images/10.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-asimbikas"
                                                                        target="_blank">Prof. Asim Bikas Das</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969440 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:asimbikas@nitw.ac.in">asimbikas@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Network system biology ; Cancer genomics</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-chocks"
                                                            target="_blank"> <img src="BIODeptAssets/images/20.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-chocks"
                                                                        target="_blank">Prof. Chockalingam S</a>
                                                                    </h5> <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9502205174 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:chocks@nitw.ac.in">chocks@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Cancer biology; Cancer cell signaling; Cancer stem cell biology</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-simha_bt"
                                                            target="_blank" className="img-fluid"> <img
                                                                src="BIODeptAssets/images/4.jpg" alt="" className="img-fluid" /> </a>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-simha_bt"
                                                                        target="_blank">Prof. Korrapati Narasimhulu</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9985470286 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:simha_bt@nitw.ac.in">simha_bt@nitw.ac.in</a>
                                                                        {/* , <a
                                href="mailto:simha7762006@gmail.com">simha7762006@gmail.com</a> */}
                                                                    </p>
                                                                    <p>Environmental Biotechnology,Modelling & Simulation of Bioprocesses,Biofuels & Bioenergy,Systems Biology.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-jerold"
                                                            target="_blank"> <img src="BIODeptAssets/images/13.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-jerold"
                                                                        target="_blank">Prof. M. Jerold</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9486252374 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:jerold@nitw.ac.in">jerold@nitw.ac.in
                                                                        </a> </p>
                                                                    <p>Biofuels & Bioenergy; Bioprocessing; Biosorption; Wastewater Treatment; Bioseparation; Algal Biotechnology ; Biopolymer; Biorefineries; Biomass Valorization; Biogenic Nanoparticles</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-popomal"
                                                            target="_blank"> <img src="BIODeptAssets/images/5.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-popomal"
                                                                        target="_blank">Prof. Onkara Perumal. P</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i
                                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                            href="mailto:popomal@nitw.ac.in">popomal@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Bioinformatics; Computational Biology; Functional Genomics; Metabolic Disorders.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-shyamperugu"
                                                            target="_blank"> <img src="BIODeptAssets/images/12.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-shyamperugu"
                                                                        target="_blank">Prof. Perugu Shyam</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"><i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9948561761 <br /> <i className="fa fa-envelope"
                                                                            aria-hidden="true"></i> <a
                                                                                href="mailto:shyamperugu@nitw.ac.in">shyamperugu@nitw.ac.in</a> </p>
                                                                    <p>Bioinformatics, Systems biology, Cancer biology, Molecular Dynamics & Simulations, Drug Design and Discovery, Machine Learning, Biological Bigdata analysis.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-ps"
                                                            target="_blank"> <img src="BIODeptAssets/images/1.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-ps"
                                                                        target="_blank">Prof. Prakash Saudagar</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2452898 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:ps@nitw.ac.in">ps@nitw.ac.in</a> </p>
                                                                    <p>Protein Biochemistry, Molecular Approach to Infectious Diseases, Molecular & Biochemical Parasitology</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-priyap"
                                                            target="_blank"> <img src="BIODeptAssets/images/18.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-priyap"
                                                                        target="_blank">Prof. Priya</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                        <a href="mailto:priyap@nitw.ac.in"> priyap@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Cell and molecular biology</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-satishbabu"
                                                            target="_blank"> <img src="BIODeptAssets/images/3.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-satishbabu"
                                                                        target="_blank">Prof. R. Satish Babu</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969439 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:satishbabu@nitw.ac.in">satishbabu@nitw.ac.in</a>
                                                                        {/* , <a
                                href="mailto:rsatishbabur@gmail.com">rsatishbabur@gmail.com</a> */}
                                                                    </p>
                                                                    <p>Bioprocess EngineeringEnvironmental BiotechnologyModeling and Simulation of BioprocessesMetabolic EngineeringBioinformatics</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-rrb"
                                                            target="_blank"> <img src="BIODeptAssets/images/7.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-rrb"
                                                                        target="_blank">Prof. Rama Raju. B</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969462 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:rrb@nitw.ac.in">rrb@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Metabolic Engineering, Biofuels and Biomaterials</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-rathnaprabhad"
                                                            target="_blank"> <img src="BIODeptAssets/images/15.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-rathnaprabhad"
                                                                        target="_blank">Prof. Rathnaprabha D</a>
                                                                    </h5> <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9494668822 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:rathnaprabhad@nitw.ac.in"> rathnaprabhad@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Plant Biotechnology, Plant tissue culture Plant Molecular biology, Phytochemicals analysis from medicinal plantsts</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-slrath"
                                                            target="_blank"> <img src="BIODeptAssets/images/16.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-slrath"
                                                                        target="_blank">Prof. Soumya Lipsa Rath</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-24524205 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:slrath@nitw.ac.in">
                                                                            slrath[@]nitw[dot]ac[dot]in </a> </p>
                                                                    <p>Molecular Modelling ; Structure Based Drug Designing;Study of Protein structure and function using Molecular Dynamics Simulations</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-parcha"
                                                            target="_blank"> <img src="BIODeptAssets/images/2.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-parcha"
                                                                        target="_blank">Prof. Sreenivasa Rao Parcha</a>
                                                                    </h5> <span>Professor</span>
                                                                    <p className="contact-info"><i
                                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                            href="mailto:parcha@nitw.ac.in">parcha@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Stem Cell Engineering, Downstream Processing and Enzyme Engineering</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-sevdasuraj"
                                                            target="_blank"> <img src="BIODeptAssets/images/17.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-sevdasuraj"
                                                                        target="_blank">Prof. Surajbhan Sevda</a>
                                                                    </h5> <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9929565697
                                                                        <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                        <a href="mailto:sevdasuraj@nitw.ac.in"> sevdasuraj@nitw.ac.in</a> </p>
                                                                    <p>My current area of Research: (a) Applications of protein nanowires in electronic sensing and power-generating devices.(b) Biosensing for environmental monitoring and healthcare diagnostics;(c) Direct interspecies electron transfer; (d) Synthetic electromicrobiology; (e) Novel bioenergy strategies;</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-thyagesh"
                                                            target="_blank"> <img src="BIODeptAssets/images/19.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-thyagesh"
                                                                        target="_blank">Prof. Thyageshwar Chandran</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 7348975012
                                                                        <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                        <a href="mailto:thyagesh@nitw.ac.in"> thyagesh@nitw.ac.in</a>
                                                                        {/* , <a
                                href="mailto:cthyageshwar@alum.iisc.ac.in">cthyageshwar@alum.iisc.ac.in</a> */}
                                                                    </p>
                                                                    <p>Structural Proteomics (X-ray Crystallography and Cryo-EM); Molecular Dynamics of Macromolecules; Molecular Biophysics; Lectins; Ribosome Inactivating Proteins; Ribosomes</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-urmila"
                                                            target="_blank"> <img src="BIODeptAssets/images/11.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-urmila"
                                                                        target="_blank">Prof. Urmila Saxena</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969442 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:urmila@nitw.ac.in">urmila@nitw.ac.in</a>
                                                                    </p>
                                                                    <p> Statistical Analysis of Biological Data for Biomarker Identification; Medical Biosensors; Nanobiotechnology</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-v.kohila"
                                                            target="_blank"> <img src="BIODeptAssets/images/8.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-v.kohila"
                                                                        target="_blank">Prof. V. Kohila</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9849432598 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:v.kohila@nitw.ac.in">v.kohila@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Protein Engineering, Immunology</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>







                                            </section>
                                        </div>
                                        <div className={`tab-pane ${peopleTab === 'NonTeachingStaff' ? 'show active' : ''} fade`} id="nav-NonTeachingStaff" role="tabpanel"
                                            aria-labelledby="nav-NonTeachingStaff-tab">
                                            <div className="row academic_group">

                                                {/* <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="BIODeptAssets/images/st1.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">V Suresh</h6> <span>Assistant(SG-II)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969539 <br />
                                                                        
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}


                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="BIODeptAssets/images/st2.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Chaitanya Subhash Gajbhiye</h6> <span>Technical Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8087832150 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:gajbhiyec@nitw.ac.in">gajbhiyec@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="BIODeptAssets/images/st3.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">G Shankaraiah</h6>
                                                                <span>Technician(SG-II)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969441 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:shankaraiah647@nitw.ac.in">shankaraiah647@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="BIODeptAssets/images/st4.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Dr A Ramesh</h6>
                                                                <span>Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9494464295 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:ramesh1989@nitw.ac.in">ramesh1989@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="BIODeptAssets/images/st5.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">P Kumar</h6> <span>Senior Caretaker</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969645 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:pkumar406@nitw.ac.in">pkumar406@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="BIODeptAssets/images/st6.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">K Kumaraswamy</h6> <span>Senior Caretaker</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969644
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="BIODeptAssets/images/st7.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">K Ravikumar</h6> <span>Sr.Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8374663160 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:kakatir@nitw.ac.in">kakatir@nitw.ac.in</a></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="BIODeptAssets/images/st8.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">A Karthik</h6>
                                                                <span>Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9160648038 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:althik@nitw.ac.in">althik@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="BIODeptAssets/images/st9.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">T Sammaiah</h6> <span>Technician (Dailywage)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9949698590 <br /> <i
                                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                            href="mailto:thottesammaiah@gmail.com">thottesammaiah@gmail.com</a></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="BIODeptAssets/images/st10.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">B Naresh</h6> <span>Outsourcing (Skilled)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9666298023 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:nareshnannu2011@gmail.com">nareshnannu2011@gmail.com</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="BIODeptAssets/images/st11.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">V Praveen</h6> <span>Outsourcing (Skilled)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9908384578<br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:praveenvem@1979@gmail.com">praveenvem@1979@gmail.com</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}



                                            </div>

                                        </div>
                                        <div className={`tab-pane ${peopleTab === 'ResearchScholars' ? 'show active' : ''} fade`} id="nav-ResearchScholars" role="tabpanel"
                                            aria-labelledby="nav-ResearchScholars-tab">
                                            {/*<h4 className="">Ph.D. Awardee</h4>
                    <p>
                      <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf" target="_blank">
                        <span className="highlight">L</span>ist of Ph.D. Awardees <i className="fa fa-link " aria-hidden="true"></i>
                      </a>
                    </p>*/}



                                            <div className="row phd-std-row">



                                                {/* <p className="more"> <a className="buttton more-info-link" href="BIODeptAssets/docs/phd-scholars-list.pdf"
                                                        target="_blank"> Ph.D Scholars List <i className="fa fa-angle-right"></i> </a> </p>
		

        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img ">

              <img src="CSEDeptAssets/images/phd1.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>Research Area :  <b>Environmental Biotechnology</b></p>
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Dr.K.Narasimhulu</h6>
            <p><span>Name of the Supervisor : <b>Prof. Y.Pydi Setty </b></span><br></br>
            <span className="contact-info">  Admission On : <b>December 2008 </b> <br/>  Completion On : <b>September 2013 </b>  </span></p>
          </div>
        </div>
        
    
 
 

        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img ">

              <img src="CSEDeptAssets/images/phd1.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>Research Area : <b>Enzyme Engineering  </b></p>
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Dr. V. Swapna</h6>
            <p><span>Name of the Supervisor : <b>Dr.P.Sreenivasa Rao  </b></span><br></br>
            <span className="contact-info">  Admission On : <b>December 2008  </b> <br/>  Completion On : <b>November 2013  </b>  </span></p>
          </div>
        </div>
        



        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img ">

              <img src="CSEDeptAssets/images/phd1.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>Research Area : <b>Stem Cell Technology   </b></p>
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Dr. M.Naveen Kumar </h6>
            <p><span>Name of the Supervisor : <b>Dr.P.Sreenivasa Rao   </b></span><br></br>
            <span className="contact-info">  Admission On : <b>December 2009  </b> <br/>  Completion On : <b>May 2014   </b>  </span></p>
          </div>
        </div>



        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img ">

              <img src="CSEDeptAssets/images/phd1.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>Research Area : <b>Metabolic Engineering    </b></p>
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Dr. B.Rama Raju </h6>
            <p><span>Name of the Supervisor : <b>Dr.P.Sreenivasa Rao   </b></span><br></br>
            <span className="contact-info">  Admission On : <b>July 2009  </b> <br/>  Completion On : <b>2014   </b>  </span></p>
          </div>
        </div>



        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img ">

              <img src="CSEDeptAssets/images/phd1.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>Research Area :  <b>Cancer Biology   </b></p>
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Mr. V.S.P.K. Sankara Aditya. J  </h6>
            <p><span>Name of the Supervisor : <b>Dr Urmila Saxena   </b></span><br></br>
            <span className="contact-info">  Admission On : <b>December 2013   </b> <br/>  Completion On : <b>   </b>  </span></p>
          </div>
        </div>



        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img ">

              <img src="CSEDeptAssets/images/phd1.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>Research Area :<b> Cancer Biology    </b></p>
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Ms. Sumithra. B  </h6>
            <p><span>Name of the Supervisor : <b>Dr Asim Bikas Das    </b></span><br></br>
            <span className="contact-info">  Admission On : <b>December 2013   </b> <br/>  Completion On : <b>   </b>  </span></p>
          </div>
        </div>



        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img ">

              <img src="CSEDeptAssets/images/phd1.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>Research Area : <b>Bio-informatics    </b></p>
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Mr. Erva Rajeswara Reddy   </h6>
            <p><span>Name of the Supervisor : <b>Dr R. Satish Babu   </b></span><br></br>
            <span className="contact-info">  Admission On : <b>December 2013   </b> <br/>  Completion On : <b>   </b>  </span></p>
          </div>
        </div>



        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img ">

              <img src="CSEDeptAssets/images/phd1.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>Research Area :  <b>Stem Cell Engineering    </b></p>
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Mr. Birru Bhaskar   </h6>
            <p><span>Name of the Supervisor : <b>Dr. P.Sreenivasa Rao   </b></span><br></br>
            <span className="contact-info">  Admission On : <b>December 2013   </b> <br/>  Completion On : <b>   </b>  </span></p>
          </div>
        </div>



        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img ">

              <img src="CSEDeptAssets/images/phd1.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>Research Area : <b>Bio Fuels      </b></p>
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Ms. Manasa. P.   </h6>
            <p><span>Name of the Supervisor : <b>Dr. K.Narasimhulu  </b></span><br></br>
            <span className="contact-info">  Admission On : <b>December 2013   </b> <br/>  Completion On : <b>   </b>  </span></p>
          </div>
        </div>



        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img ">

              <img src="CSEDeptAssets/images/phd1.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>Research Area :<b> Gene therapy</b></p>
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">R. Kowshik Aravilli </h6>
            <p><span>Name of the Supervisor : <b>Dr.V.Kohila</b></span><br></br>
            <span className="contact-info">  Admission On : <b>December 2013   </b> <br/>  Completion On : <b>   </b>  </span></p>
          </div>
        </div>



        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img ">

              <img src="CSEDeptAssets/images/phd1.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>Research Area :<b> Environmental Biotechnology </b></p>
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Hari Krishna Yadav Nanganuru </h6>
            <p><span>Name of the Supervisor : <b>Dr.K.Narasimhulu</b></span><br></br>
            <span className="contact-info">  Admission On : <b>July 2014    </b> <br/>  Completion On : <b>   </b>  </span></p>
          </div>
        </div>



        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img ">

              <img src="CSEDeptAssets/images/phd1.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>Research Area :<b> Gene regulation </b></p>
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">M. Nirupama  </h6>
            <p><span>Name of the Supervisor : <b>Dr.Amitava Bandhu </b></span><br></br>
            <span className="contact-info">  Admission On : <b>July 2014    </b> <br/>  Completion On : <b>   </b>  </span></p>
          </div>
        </div>




        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img ">

              <img src="CSEDeptAssets/images/phd1.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>Research Area :<b> Nanobiotechnology </b></p>
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Mayuri Bhatia   </h6>
            <p><span>Name of the Supervisor : <b>Dr.R.Satish babu </b></span><br></br>
            <span className="contact-info">  Admission On : <b>July 2014    </b> <br/>  Completion On : <b>   </b>  </span></p>
          </div>
        </div>



        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img ">

              <img src="CSEDeptAssets/images/phd1.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>Research Area :<b> Stem Cell Engineering  </b></p>
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Santhosh Kacham   </h6>
            <p><span>Name of the Supervisor : <b>Dr.P.Sreenivasa Rao</b></span><br></br>
            <span className="contact-info">  Admission On : <b>July 2014    </b> <br/>  Completion On : <b>   </b>  </span></p>
          </div>
        </div> */}




















                                            </div>



                                        </div>
                                    </div>
                                </div>
                                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade   " id="v-pills-NonTeachingStaff" role="tabpanel" aria-labelledby="v-pills-NonTeachingStaff-tab"><h4 className=" mb-4">Non-Teaching Staff</h4><table className="table table-sm "><thead><tr><th>Sl. No</th><th>Name of the Staff Member</th><th>Designation</th><th>Division</th></tr></thead><tbody><tr><td>1</td><td>Smt. K.S.S.S. Padmasri</td><td>Senior Technical Assistant</td></tr><tr><td>2</td><td>Smt. Shalini Avala</td><td>Technical Assistant</td></tr><tr><td>3</td><td>Sri . R Shivamallikarjun</td><td>Technical Assistant</td><td>CSE</td></tr><tr><td>4</td><td> Sri R. Jeevan kumar</td><td>Senior Technician</td></tr><tr><td>5</td><td>Sri. D. Ramesh</td><td>Senior Technician</td></tr><tr><td>6</td><td>Sri. B. Ramesh</td><td>Junior Assistant</td></tr><tr><td>7</td><td>K. Venkatamma</td><td> Technician (upgd)</td></tr><tr><td>8</td><td> K. Jayaraj</td><td>Caretaker SG-II</td></tr><tr><td>9</td><td>B. Sunil kumar</td><td>Electrician (Daily wage)</td></tr><tr><td>10</td><td>T. Varsha Sree</td><td>Skilled</td></tr><tr><td>11</td><td> M. Padma Rao</td><td>Skilled</td></tr><tr><td>12</td><td>P. Suma </td><td>Skilled</td></tr><tr><td>13</td><td>M. Mahendar</td><td>Un-Skilled</td></tr><tr><td>14</td><td>K. Srinivasulu</td><td>Un-Skilled</td><td>CSE</td></tr><tr><td>15</td><td>T. Sampath </td><td> Un-Skilled</td></tr></tbody></table></div>*/}
                                <div className={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                                    aria-labelledby="v-pills-Students-tab">
                                    <div className="row std-society">


                                        {/* <div className="col-md-3 col-6">
                                            <div className="card "> <a
                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16345"
                                                target="_blank"> <img src="CSEDeptAssets/images/9.jpg" alt="" className="img-fluid" />
                                            </a>
                                                <div className="data"> <a
                                                    href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16345"
                                                    target="_blank">
                                                    <h6>Prof. R. Padmavathy</h6> <span> Faculty Advisor </span>
                                                    <hr />
                                                </a>
                                                    <p> <i className="fa fa-phone" aria-hidden="true"></i> 9490165347 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:cse_hod@nitw.ac.in"
                                                            style={{ color: "#808080" }}>cse_hod@nitw.ac.in</a> </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="card "> <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a>
                                                <div className="data"> <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Prof. U. S. N. Raju</h6> <span> Treasurer </span>
                                                    <hr />
                                                </a>
                                                    <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p>
                                                </div>
                                            </div>
                                        </div> */}




                                    </div>
                                </div>
                                <div className={`tab-panel ${activeTab === 'v-pills-NotableAlumni' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-NotableAlumni" role="tabpanel"
                                    aria-labelledby="v-pills-NotableAlumni-tab">


                                    <div className="row notable-alumni">





                                        {/* <h3>Alumni List 2006-2010</h3>
                                        
                                        

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Akula Bhulaxmi</h6>
                                                    <p>
                                                        Roll No :  <b>6801</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ankit Bachwat </h6>
                                                    <p>
                                                        Roll No :  <b>6802 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ankit Reddy Vantikommu</h6>
                                                    <p>
                                                        Roll No :  <b>6803</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Anoop Kumar</h6>
                                                    <p>
                                                        Roll No : 6804  <b></b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ashok Verma Samantapudi </h6>
                                                    <p>
                                                        Roll No :  <b>6805</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>B. Srikanth Vijaya Kumar</h6>
                                                    <p>
                                                        Roll No :  <b>6806</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Byagari Krishna</h6>
                                                    <p>
                                                        Roll No :  <b>6807</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Govind Lohia </h6>
                                                    <p>
                                                        Roll No :  <b>6808 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Harish Kumar Boda</h6>
                                                    <p>
                                                        Roll No :  <b>6809 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>J. Raghava Reddy </h6>
                                                    <p>
                                                        Roll No :  <b>6810 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Jasti Srikanth</h6>
                                                    <p>
                                                        Roll No :  <b>6811</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Kumar Amar </h6>
                                                    <p>
                                                        Roll No :  <b>6813 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>L. Lokesh </h6>
                                                    <p>
                                                        Roll No :  <b>6814 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>M. Siddartha </h6>
                                                    <p>
                                                        Roll No :  <b>6815 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Md. Imran Rawami </h6>
                                                    <p>
                                                        Roll No :  <b>6816 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Md. Khader </h6>
                                                    <p>
                                                        Roll No :  <b>6817 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Bejjanki Sushma </h6>
                                                    <p>
                                                        Roll No :  <b>6818</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Divya Janaki Kallem</h6>
                                                    <p>
                                                        Roll No :  <b>6819</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Krishna Das</h6>
                                                    <p>
                                                        Roll No :  <b>6820</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Madhavi Kaivalya, K</h6>
                                                    <p>
                                                        Roll No :  <b>6821 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. P. Sahiti</h6>
                                                    <p>
                                                        Roll No :  <b>6822</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Rekha S Varrier</h6>
                                                    <p>
                                                        Roll No :  <b>6823 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Riya Biswas </h6>
                                                    <p>
                                                        Roll No :  <b>6824</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Sathvika Chinthalapani </h6>
                                                    <p>
                                                        Roll No :  <b>6825</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Sonali Seth</h6>
                                                    <p>
                                                        Roll No :  <b>6826 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Sumona Chakraborty </h6>
                                                    <p>
                                                        Roll No :  <b>6827 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Tejaswi</h6>
                                                    <p>
                                                        Roll No :  <b>6828 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Murali Krishna Routhu</h6>
                                                    <p>
                                                        Roll No :  <b>6829</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>N. Kovid Dev Reddy </h6>
                                                    <p>
                                                        Roll No :  <b>6830 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Nagendra Babu Pati</h6>
                                                    <p>
                                                        Roll No :  <b>6831 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Nimmala Anil Kumar </h6>
                                                    <p>
                                                        Roll No :  <b>6832</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Omesh Raypa </h6>
                                                    <p>
                                                        Roll No :  <b>6833</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>P. Karthik</h6>
                                                    <p>
                                                        Roll No :  <b>6834</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Prayakara Rao Rahul</h6>
                                                    <p>
                                                        Roll No :  <b>6836 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Rahul Kumar Naik Ajmeera </h6>
                                                    <p>
                                                        Roll No :  <b>6837</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Rahul Unnikrishnan</h6>
                                                    <p>
                                                        Roll No :  <b>6838</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Rohit Gupta </h6>
                                                    <p>
                                                        Roll No :  <b>6839</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Rohit, K. R</h6>
                                                    <p>
                                                        Roll No :  <b>6840</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Saranga Bordoloi</h6>
                                                    <p>
                                                        Roll No :  <b>6841</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Shyam Sunder, K</h6>
                                                    <p>
                                                        Roll No :  <b>6842</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Srikanth Puthkanuri </h6>
                                                    <p>
                                                        Roll No :  <b>6843</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ulke Parag Ramesh </h6>
                                                    <p>
                                                        Roll No :  <b>6844</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>V. Kumaran </h6>
                                                    <p>
                                                        Roll No :  <b>6845</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>V. Vineedh</h6>
                                                    <p>
                                                        Roll No :  <b>6846 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Varun Sarna </h6>
                                                    <p>
                                                        Roll No :  <b>6847 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Vijay Mishra </h6>
                                                    <p>
                                                        Roll No :  <b>6848</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Vinit Kumar Kulakarni</h6>
                                                    <p>
                                                        Roll No :  <b>6849 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Vishal Kumar</h6>
                                                    <p>
                                                        Roll No :  <b>6950</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Yashwanth Narayana Rao, H </h6>
                                                    <p>
                                                        Roll No :  <b>6851</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>






                                        <h3>Alumni List 2007-2011</h3>




                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Abhishek Kumar</h6>
                                                    <p>
                                                        Roll No :  <b>7801</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Alapati Vikranth</h6>
                                                    <p>
                                                        Roll No :  <b>7802 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Alok Roy </h6>
                                                    <p>
                                                        Roll No :  <b>7803 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Amar Kumar Srivastava</h6>
                                                    <p>
                                                        Roll No :  <b>7804 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Anzal Rahman</h6>
                                                    <p>
                                                        Roll No :  <b>7805</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>B.V.Pramod Gupta</h6>
                                                    <p>
                                                        Roll No :  <b>7807</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Febin Puthiyapurayil Nalpady</h6>
                                                    <p>
                                                        Roll No :  <b>7808</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ganta Srikanth </h6>
                                                    <p>
                                                        Roll No :  <b>7809</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Giraboina Kranthi Kumar </h6>
                                                    <p>
                                                        Roll No :  <b>7810</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Gururaj </h6>
                                                    <p>
                                                        Roll No :  <b>7811</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Harsh Luhadiya </h6>
                                                    <p>
                                                        Roll No :  <b>7812</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Harsha Bajaj </h6>
                                                    <p>
                                                        Roll No :  <b>7813</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Isha Chowdhary</h6>
                                                    <p>
                                                        Roll No :  <b>7814 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Janpriya Ankit </h6>
                                                    <p>
                                                        Roll No :  <b>7815</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Jenice Kujur</h6>
                                                    <p>
                                                        Roll No :  <b>7816</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Jhansi Naramada Reddy Kotamreddy </h6>
                                                    <p>
                                                        Roll No :  <b>7817</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>K.P.Mohamed Nashath</h6>
                                                    <p>
                                                        Roll No :  <b>7818 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Kolli Priyanka</h6>
                                                    <p>
                                                        Roll No :  <b>7819</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Komal Kiran </h6>
                                                    <p>
                                                        Roll No :  <b>7820</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Konakanchi Vasanth Kumar </h6>
                                                    <p>
                                                        Roll No :  <b>7821</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Konda Manindra</h6>
                                                    <p>
                                                        Roll No :  <b>7822</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Lalit Sethia</h6>
                                                    <p>
                                                        Roll No :  <b>7823</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Manav Gupta</h6>
                                                    <p>
                                                        Roll No :  <b>7824</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Mirtunjay Kumar</h6>
                                                    <p>
                                                        Roll No :  <b>7825</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Muddana Sagar </h6>
                                                    <p>
                                                        Roll No :  <b>7826</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Niharika Gudur </h6>
                                                    <p>
                                                        Roll No :  <b>7827</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Nijel Plamthottathil Alex</h6>
                                                    <p>
                                                        Roll No :  <b>7828 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>P Sameep Jain </h6>
                                                    <p>
                                                        Roll No :  <b>7829</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>P Arun Venkatesh</h6>
                                                    <p>
                                                        Roll No :  <b>7830</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Prashanth Bajpai </h6>
                                                    <p>
                                                        Roll No :  <b>7831</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Prasmjit Jhankar</h6>
                                                    <p>
                                                        Roll No :  <b>7832</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Priyabrata Chatterjee </h6>
                                                    <p>
                                                        Roll No :  <b>7833</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Priyanka Dutta</h6>
                                                    <p>
                                                        Roll No :  <b>7834</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. R. Ranjani</h6>
                                                    <p>
                                                        Roll No :  <b>7835</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Rajanala Sai Harisha </h6>
                                                    <p>
                                                        Roll No :  <b>7836</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Rajasekhara Reddy Katreddy</h6>
                                                    <p>
                                                        Roll No :  <b>7837</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Raju Kumar Mishra </h6>
                                                    <p>
                                                        Roll No :  <b>7838</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>S. Varun Kumar </h6>
                                                    <p>
                                                        Roll No :  <b>7839</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Satya Prathyusha B</h6>
                                                    <p>
                                                        Roll No :  <b>7840</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Seshaphani J </h6>
                                                    <p>
                                                        Roll No :  <b>7841</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Sharma Shruti Jagatram </h6>
                                                    <p>
                                                        Roll No :  <b>7842</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Shreya Makala</h6>
                                                    <p>
                                                        Roll No :  <b>7843</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Sidhartha Kr Sharma </h6>
                                                    <p>
                                                        Roll No :  <b>7844 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Sindhuja Nandiraju</h6>
                                                    <p>
                                                        Roll No :  <b>7845 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Sriramoju Prem kumar</h6>
                                                    <p>
                                                        Roll No :  <b>7846 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Srujan Kumar Kokula</h6>
                                                    <p>
                                                        Roll No :  <b>7847 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Tejaswi Naik Ajmera </h6>
                                                    <p>
                                                        Roll No :  <b>7848</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Thamalapakula Vamsee</h6>
                                                    <p>
                                                        Roll No :  <b>7849 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Tushar Ranjan Moharana </h6>
                                                    <p>
                                                        Roll No :  <b>7850 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>V. Naveen Kumar </h6>
                                                    <p>
                                                        Roll No :  <b>7851</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>V. Rakesh</h6>
                                                    <p>
                                                        Roll No :  <b>7852</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>V.T. Bhuvanesh </h6>
                                                    <p>
                                                        Roll No :  <b>7853 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ms. Vasantha Guguloth </h6>
                                                    <p>
                                                        Roll No :  <b>7854 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Venu Madhav Chagarla</h6>
                                                    <p>
                                                        Roll No :  <b>7855</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Yadvendra Yadav </h6>
                                                    <p>
                                                        Roll No :  <b>7856 </b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Sandeep Kumar Behera</h6>
                                                    <p>
                                                        Roll No :  <b>6862</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <div className="data">
                                                    <h6>Ulke Parag Ramesh</h6>
                                                    <p>
                                                        Roll No :  <b>6844</b>

                                                    </p>
                                                </div>
                                            </div>
                                        </div> */}





                                    </div>
                                </div>

                                <div className={`tab-pane ${activeTab === 'v-pills-AcademicResearchGroup' ? 'show active' : ''} fade `} id="v-pills-AcademicResearchGroup" role="tabpanel"
                                    aria-labelledby="v-pills-AcademicResearchGroup-tab">



                                    {/* <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Big Data Analytics, Artificial Intelligence / Machine Learning, Data Science
                                                </h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/5.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. RBV Subramanyam</h6>
                                                        <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9491346969 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rbvs66@nitw.ac.in">rbvs66@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/6.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P Radhakrishna</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9704988816 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:prkrishna@nitw.ac.in">prkrishna@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/11.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. T Ramakrishnudu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9866876842 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:trk@nitw.ac.in">trk@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/14.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K.V.Kadambari</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9705460461<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kvkadambari@nitw.ac.in">kvkadambari@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/22.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. M Srinivas</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8897064421<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:msv@nitw.ac.in">msv@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/27.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Venkateswara Rao Kagita</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 6281746931 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:venkat.kagita@nitw.ac.in">venkat.kagita@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}



                                    {/* <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Security</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/9.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. R Padmavathy</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9381265691 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rpadma@nitw.ac.in">rpadma@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/10.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. R.R.Rout</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969418<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rashrr@nitw.ac.in">rashrr@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/1.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. S Ravichandra</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969414 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ravic@nitw.ac.in">ravic@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/21.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof.M.Sandhya</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9394468554<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:msandhya@nitw.ac.in">msandhya@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/23.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Kaveti Ilaiah</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9848916272 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ilaiahkavati@nitw.ac.in">ilaiahkavati@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/20.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. E Suresh Babu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440959713 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:esbabu@nitw.ac.in">esbabu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}



                                    {/* <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Networks</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/10.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. R.R.Rout</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969418<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rashrr@nitw.ac.in">rashrr@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/7.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K Ramesh</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9849432598 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kramesh@nitw.ac.in">kramesh@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/17.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sriram Kailasam</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8894275490 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sriramk@nitw.ac.in">sriramk@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/26.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K.BalaPrakashRao</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 7002457102 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:bsprao@nitw.ac.in">bsprao@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/20.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. E Suresh Babu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440959713 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:esbabu@nitw.ac.in">esbabu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}



                                    {/* <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Software Engineering</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/1.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. S Ravichandra</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969414 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ravic@nitw.ac.in">ravic@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/7.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K Ramesh</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9849432598 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kramesh@nitw.ac.in">kramesh@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/16.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof.Priyanka Chawla</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 7982750652 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:priyankac@nitw.ac.in">priyankac@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/18.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof.Manjubala Bisi</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9502940360 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:esbabu@nitw.ac.in">manjubalabisi@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/29.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sangharatna Godboley</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 7013306805<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:cse_hod@nitw.ac.in">sanghu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}


                                    {/* 
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Cloud & Distributed Computing, IOT</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/8.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Ch Sudhakar</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440647945 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:chapram@nitw.ac.in">chapram@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/10.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. R.R.Rout</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969418<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rashrr@nitw.ac.in">rashrr@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/17.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sriram Kailasam</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8894275490 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sriramk@nitw.ac.in">sriramk@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/25.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sanjaya Kumar Panda</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9861126947<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sanjay@nitw.ac.in">sanjay@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/20.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. E Suresh Babu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440959713 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:esbabu@nitw.ac.in">esbabu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/26.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K.BalaPrakashRao</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 7002457102 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:bsprao@nitw.ac.in">bsprao@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}




                                    {/* <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Algorithms, BioInformatics, Computer Vision, Image Processing, Model-driven
                                                    Frameworks, Soft Computing</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/7.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K Ramesh</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9849432598 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kramesh@nitw.ac.in">kramesh@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/12.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Raju Bhukya</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9700553922 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:raju@nitw.ac.in">raju@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/15.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P V Subba Reddy</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8790590436<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:pvsr@nitw.ac.in">pvsr@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/13.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. U S N Raju</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440149146 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:usnraju@nitw.ac.in">usnraju@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/19.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Ijjina Earnest Paul</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9494466490<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:iep@nitw.ac.in">iep@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/24.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sujit Das</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8536009758<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sujit.das@nitw.ac.in">sujit.das@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/28.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Ramalingaswamy Cherukku</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9773827143 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rmlswamy@nitw.ac.in">rmlswamy@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}




                                </div>


                                <div className={"tab-pane ${activeTab === 'v-pills-ResearchScholars' ? 'active' : ''} fade  "} id="v-pills-ResearchScholars" role="tabpanel"
                                    aria-labelledby="v-pills-ResearchScholars-tab">
                                    <h4 className="">Ph.D. Awardee</h4> <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf"
                                        target="_blank"> <span className="highlight">L</span>ist of Ph.D. Awardees <i
                                            className="fa fa-link " aria-hidden="true"></i> </a>
                                    <h4 className="">Ph.D. On Roll</h4> <a
                                        href="CSEDeptAssets/docs/Ph.D._Students_List_2022-7-29-11-55-47.pdf" target="_blank"> <span
                                            className="highlight">P</span>h.D. Students List ( Full / Part Time) <i
                                                className="fa fa-link " aria-hidden="true"></i> </a>
                                </div>


                                <div className={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                                    aria-labelledby="v-pills-PhotoGallery-tab">

                                    {/* <div className="details"> 
								                <h4>AICTE Training and Learning Academy (ATAL) FDP on Cloud and Fog Computing Platforms for IoT Applications</h4>
                                <span>19th to 30th September 2022</span>
								                </div> 

                                <img src="CSEDeptAssets/images/atal1.jpeg" alt="" className="img-fluid mb-5"/> 
                                <img src="CSEDeptAssets/images/atal2.jpeg" alt="" className="img-fluid mb-5"/> 
                                <img src="CSEDeptAssets/images/atal3.jpeg" alt="" className="img-fluid mb-5"/> */}


                                </div>
                                {/* <div className={`tab-pane ${activeTab === 'v-pills-Awards' ? 'show active' : ''} fade  bg-white`} id="v-pills-Awards" role="tabpanel"
                                    aria-labelledby="v-pills-Awards-tab">

                                </div> */}


                                <div className={`tab-pane ${activeTab === 'v-pills-Awards' ? 'show active' : ''} fade  bg-white`} id="v-pills-Awards" role="tabpanel"
                                    aria-labelledby="v-pills-Awards-tab">

                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Anam Upadhyay,  M.Tech. 1st-year student in the Department of Biotechnology, has been selected for the Khorana Program for Scholars. During the summer of 2024, she will do her internship at Purdue University, USA, under Dr. Andrea Kasinski</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
            {/* <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright">
                                <p>Copyright © Centre for Digital Infrastructure and Services </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default BIODeptPage;