import React from 'react';
// import PageHeader from '../components/PageHeader';
// import '../css/deansw.css'; // Update the CSS file name

const HygieneCampus = () => {
    return (
        <div class="container my-4">
            <center><h4 style={{ textDecoration: "underline" }}>ADVISORY ON HYGIENE OF CAMPUS</h4></center>
            <p>Maintaining a clean and hygienic campus environment is essential for health, safety, and
                overall well-being of everyone on campus. Students, Faculty, and Staff play a vital role in
                keeping our surroundings clean and ensuring that the campus remains a pleasant place for
                all. The Some of practices every NITWian may follow for our obvious benefits.</p>
            <div class="row">
                <table className="table table-bordered table-responsive">
                    <tbody>
                        <tr>
                            <td>1 </td>
                            <td><p><b>Proper Disposal of Trash</b></p></td>
                            <td> Please dispose of all trash,
                                including food wrappers,
                                paper, and other waste, in
                                the designated bins placed
                                around the campus. Also,
                                follow the waste
                                segregation guidelines by
                                placing recyclable
                                materials, organic waste,
                                and non-recyclable waste
                                in the appropriate bins.</td>
                            <td><img class="img-fluid" src="https://nitw.ac.in/api/static/files/Proper_Disposal_of_Trash_2024-9-10-15-47-43.jpg" /> </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td><b>Classroom and Study Areas</b></td>
                            <td> Ensure that your study and
                                workspaces are free of
                                litter and personal items
                                when you leave. If you
                                accidentally spill
                                something, clean it up
                                immediately or notify the
                                maintenance staff for
                                assistance.</td>
                            <td><img class="img-fluid" src="https://nitw.ac.in/api/static/files/Classroom_and_Study_Areas_2024-9-10-15-48-16.jpg" /> </td>
                        </tr>


                        <tr>
                            <td>3</td>
                            <td><b>Restroom Hygiene</b></td>
                            <td>Always flush the toilet
                                before and after use to
                                maintain cleanliness. Place
                                used paper towels in the
                                trash bins provided, and
                                ensure proper hand
                                hygiene. After using the
                                restroom, wash your hands
                                thoroughly with soap and
                                water. If the hand wash is
                                not unavailable, please
                                contact your Department
                                office for replacement. </td>
                            <td><img class="img-fluid" src="https://nitw.ac.in/api/static/files/Restroom_Hygiene_2024-9-10-15-48-46.jpg" /> </td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td><b>Personal Cleanliness</b></td>
                            <td>Regularly wash your
                                hands, especially before
                                eating and after using the
                                restroom. Carry hand
                                sanitizer for situations
                                where soap and water are
                                not available. Make sure
                                your bags, water bottles,
                                and other personal items
                                are clean and do not
                                contribute to clutter or
                                mess. </td>
                            <td><img class="img-fluid" src="https://nitw.ac.in/api/static/files/Personal_Cleanliness_2024-9-10-15-49-22.jpg" /> </td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td><b> Outdoor Spaces</b></td>
                            <td> Do not litter in gardens,
                                lawns, or other outdoor
                                areas instead choose the
                                trash bins provided.
                                Participate in Swachhatta

                                Programs campus clean-
                                up initiatives to help keep

                                the environment clean and
                                foster a sense of
                                community responsibility.
                                As NITWIAN, you have the
                                additional responsibility in
                                sensitizing the others to
                                adopt cleanliness practices</td>
                            <td><img class="img-fluid" src="https://nitw.ac.in/api/static/files/Outdoor_Spaces_2024-9-10-15-49-51.jpg" /> </td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td><b>Complaint the issue</b></td>
                            <td> If you notice areas on
                                campus that need cleaning
                                or maintenance, report
                                them to the Housekeeping
                                Supervisors/Caretakers of
                                respective Halls of
                                Residence.</td>
                            <td><img class="img-fluid" src="https://nitw.ac.in/api/static/files/Complaint_the_issue_2024-9-10-15-50-19.jpg" /> </td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td><b>Be a Role Mode</b></td>
                            <td>Encourage your peers to
                                follow these cleanliness
                                and hygiene practices.
                                Leading by example is one
                                of the most effective ways
                                to promote a clean and
                                healthy campus. </td>
                            <td><img class="img-fluid" src="https://nitw.ac.in/api/static/files/Be_a_Role_Mode_2024-9-10-15-50-48.jpg" /> </td>
                        </tr>


                    </tbody>
                </table>

                <center><h3>“IF HYGIENE IS THE JOKE, THEN ILL
                HEALTH IS THE PUNCH LINE.”</h3></center>

            </div>
        </div>
    )
}
export default HygieneCampus;