import React from 'react';
// import PageHeader from '../components/PageHeader';
import '../css/deansw.css'; // Update the CSS file name

const StatutoryPolicies = () => {
    return (
        <div class="container">
            <center><h4 style={{textDecoration:"underline"}}>Statutory Policies</h4> </center>
            <div className="row">
                <div className='col-md-5 col-sm-12'>

                </div>
                <div className='col-md-5 col-sm-12'>
                <h5><b>Zero-tolerance</b></h5>
                <p>
                    Women harassment.<br />
                    Damaging Institute property.<br />
                    Unfair practices in examinations.<br />
                    Physical / oral abuse of any person.
                </p>
                <h5><b>Strictly prohibited</b></h5>
                <p>
                    WRagging in any form.<br />
                    Consumption of alcohol, Narcotic drugs.<br />
                    Smoking in public places.
                </p>
                <h5><b>Discriminations treated seriously</b></h5>
                <p>
                    Gender / Social status.<br />
                    Academic performance.<br />
                    Region / Religion / Nationality.
                </p>
                </div>



            </div>
           
        </div>
    )
}
export default StatutoryPolicies;