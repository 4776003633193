// import "../css/NewsScrollCard.css";

// const NewsScrollCard = (props) => {
//   return (
//     <div className="scroll-card-wrapper flex">
//       <div className="row text-center">
//         {props.image ? (
//           <div className="col-12">
//             {" "}
//             <img src={props.image} className="scroll-card-img" alt="" />{" "}
//           </div>
//         ) : (
//           <></>
//         )}

//         <div className="col-12" style={{ paddingTop: "10px" }}>
//           {" "}
//           <h4 className="news-title-news"> {props.title} </h4>{" "}
//         </div>
//         {/* <br /> */}
//         <div className="col-12">
//           <p className="read-mores-news">
//             {props.content}
//           </p>
//         </div>
//         <div className="col-12">
//           <p className="read-more-news">
//             <br />
//             <a href={props.url}>Read More</a>
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default NewsScrollCard;

import React, { useState } from "react";
import "../css/NewsScrollCard.css";

const NewsScrollCard = (props) => {
  const [expanded, setExpanded] = useState(false);
  const title = props.title;
  const MAX_WORDS = 15;

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const renderTitle = () => {
    if (expanded || !title || title.split(" ").length <= MAX_WORDS) {
      return title;
    } else {
      const words = title.split(" ");
      const visibleTitle = words.slice(0, MAX_WORDS).join(" ");
      const remainingTitle = words.slice(MAX_WORDS).join(" ");
      return (
        <>
          {visibleTitle}
          <span className="read-more-news" onClick={handleClick}>
            read more
          </span>
          {expanded && remainingTitle}
        </>
      );
    }
  };

  return (
    <div className="scroll-card-wrapper flex">
      <div className="row text-center">
        {props.image && (
          <div className="col-12">
            <img src={props.image} className="scroll-card-img" alt="" />
          </div>
        )}

        <div className="col-12" style={{ paddingTop: "10px" }}>
          <h4 className="news-title-news">{renderTitle()}</h4>
        </div>

        <div className="col-12">
          <p className="read-mores-news font-change-applicable">
            {props.content && props.content.length > 0 && props.content}
            {props.url && (
              <a href={props.url} className="read-more-news">
                Click here
              </a>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NewsScrollCard;
